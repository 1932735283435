.illustrations {

    @include text-style;
    @include padding-bottom;
    background-color: $copy-background;

    h2,
    h3 {
        @include span-brand;
    }

    p {
        @include span-dark;
    }

    img {
        width: 200px;
    }

    .desktop-view {
        display: none;

        img {
            margin: 20px 5px 30px 5px;
            width: 49%;
        }
    }

    .cta-box {
        color: #fff;
        margin: 30px 0;

        .cta-box-container {
            padding: 40px 40px 28px 40px;
            background-color: $brand-heading-dark;
            border-radius: 5px;
        }

        p {
            span {
                font-weight: bold;
                color: #64C3D5;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .illustrations {
        .desktop-view {
            img {
                width: 24%;
                display: inline-block;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .illustrations {
        .desktop-view {
            img {
                width: 23%;
                margin: 20px 10px 30px 10px;
            }
        }
    }
}