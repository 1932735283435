.page_5 {
    .passage {
        padding-bottom: 0px;
    }

    .accordion {
        margin-bottom: 30px;
        
        .container {
            .card-header button {
                color: $brand-heading-dark;
            }
        }
    }

    .button-block {
        margin-bottom: 60px;
    }
}

@include media-breakpoint-up (md) {
    .page_5 {
        .button-block {
            margin-bottom: 100px;
        }
    }
}