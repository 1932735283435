@charset "UTF-8";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-phone: 480px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1500px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Jost", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: inherit;
}

h1, .h1 {
  font-size: 1.5rem;
}

h2, .h2 {
  font-size: 1.375rem;
}

h3, .h3 {
  font-size: 1.25rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1.075rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container-phone, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-phone, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-phone, .container {
    max-width: 960px;
  }
}
@media (min-width: 1500px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-phone, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-phone,
.col-phone-auto, .col-phone-12, .col-phone-11, .col-phone-10, .col-phone-9, .col-phone-8, .col-phone-7, .col-phone-6, .col-phone-5, .col-phone-4, .col-phone-3, .col-phone-2, .col-phone-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 480px) {
  .col-phone {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-phone-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-phone-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-phone-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-phone-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-phone-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-phone-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-phone-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-phone-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-phone-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-phone-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-phone-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-phone-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-phone-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-phone-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-phone-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-phone-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-phone-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-phone-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-phone-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-phone-first {
    order: -1;
  }
  .order-phone-last {
    order: 13;
  }
  .order-phone-0 {
    order: 0;
  }
  .order-phone-1 {
    order: 1;
  }
  .order-phone-2 {
    order: 2;
  }
  .order-phone-3 {
    order: 3;
  }
  .order-phone-4 {
    order: 4;
  }
  .order-phone-5 {
    order: 5;
  }
  .order-phone-6 {
    order: 6;
  }
  .order-phone-7 {
    order: 7;
  }
  .order-phone-8 {
    order: 8;
  }
  .order-phone-9 {
    order: 9;
  }
  .order-phone-10 {
    order: 10;
  }
  .order-phone-11 {
    order: 11;
  }
  .order-phone-12 {
    order: 12;
  }
  .offset-phone-0 {
    margin-left: 0;
  }
  .offset-phone-1 {
    margin-left: 8.33333333%;
  }
  .offset-phone-2 {
    margin-left: 16.66666667%;
  }
  .offset-phone-3 {
    margin-left: 25%;
  }
  .offset-phone-4 {
    margin-left: 33.33333333%;
  }
  .offset-phone-5 {
    margin-left: 41.66666667%;
  }
  .offset-phone-6 {
    margin-left: 50%;
  }
  .offset-phone-7 {
    margin-left: 58.33333333%;
  }
  .offset-phone-8 {
    margin-left: 66.66666667%;
  }
  .offset-phone-9 {
    margin-left: 75%;
  }
  .offset-phone-10 {
    margin-left: 83.33333333%;
  }
  .offset-phone-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1500px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 480px) {
  .dropdown-menu-phone-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-phone-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1500px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 1.075rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 2rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.5rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.5rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.5rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.5rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 3px 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 479.98px) {
  .navbar-expand-phone > .container,
  .navbar-expand-phone > .container-fluid,
  .navbar-expand-phone > .container-sm,
  .navbar-expand-phone > .container-md,
  .navbar-expand-phone > .container-lg,
  .navbar-expand-phone > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 480px) {
  .navbar-expand-phone {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-phone .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-phone .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-phone .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-phone > .container,
  .navbar-expand-phone > .container-fluid,
  .navbar-expand-phone > .container-sm,
  .navbar-expand-phone > .container-md,
  .navbar-expand-phone > .container-lg,
  .navbar-expand-phone > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-phone .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-phone .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-phone .navbar-toggler {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1499.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1500px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: white;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.8);
  border-color: white;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.8);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1500px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 480px) {
  .d-phone-none {
    display: none !important;
  }
  .d-phone-inline {
    display: inline !important;
  }
  .d-phone-inline-block {
    display: inline-block !important;
  }
  .d-phone-block {
    display: block !important;
  }
  .d-phone-table {
    display: table !important;
  }
  .d-phone-table-row {
    display: table-row !important;
  }
  .d-phone-table-cell {
    display: table-cell !important;
  }
  .d-phone-flex {
    display: flex !important;
  }
  .d-phone-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1500px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 480px) {
  .flex-phone-row {
    flex-direction: row !important;
  }
  .flex-phone-column {
    flex-direction: column !important;
  }
  .flex-phone-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-phone-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-phone-wrap {
    flex-wrap: wrap !important;
  }
  .flex-phone-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-phone-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-phone-fill {
    flex: 1 1 auto !important;
  }
  .flex-phone-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-phone-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-phone-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-phone-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-phone-start {
    justify-content: flex-start !important;
  }
  .justify-content-phone-end {
    justify-content: flex-end !important;
  }
  .justify-content-phone-center {
    justify-content: center !important;
  }
  .justify-content-phone-between {
    justify-content: space-between !important;
  }
  .justify-content-phone-around {
    justify-content: space-around !important;
  }
  .align-items-phone-start {
    align-items: flex-start !important;
  }
  .align-items-phone-end {
    align-items: flex-end !important;
  }
  .align-items-phone-center {
    align-items: center !important;
  }
  .align-items-phone-baseline {
    align-items: baseline !important;
  }
  .align-items-phone-stretch {
    align-items: stretch !important;
  }
  .align-content-phone-start {
    align-content: flex-start !important;
  }
  .align-content-phone-end {
    align-content: flex-end !important;
  }
  .align-content-phone-center {
    align-content: center !important;
  }
  .align-content-phone-between {
    align-content: space-between !important;
  }
  .align-content-phone-around {
    align-content: space-around !important;
  }
  .align-content-phone-stretch {
    align-content: stretch !important;
  }
  .align-self-phone-auto {
    align-self: auto !important;
  }
  .align-self-phone-start {
    align-self: flex-start !important;
  }
  .align-self-phone-end {
    align-self: flex-end !important;
  }
  .align-self-phone-center {
    align-self: center !important;
  }
  .align-self-phone-baseline {
    align-self: baseline !important;
  }
  .align-self-phone-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1500px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 480px) {
  .float-phone-left {
    float: left !important;
  }
  .float-phone-right {
    float: right !important;
  }
  .float-phone-none {
    float: none !important;
  }
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1500px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 480px) {
  .m-phone-0 {
    margin: 0 !important;
  }
  .mt-phone-0,
  .my-phone-0 {
    margin-top: 0 !important;
  }
  .mr-phone-0,
  .mx-phone-0 {
    margin-right: 0 !important;
  }
  .mb-phone-0,
  .my-phone-0 {
    margin-bottom: 0 !important;
  }
  .ml-phone-0,
  .mx-phone-0 {
    margin-left: 0 !important;
  }
  .m-phone-1 {
    margin: 0.25rem !important;
  }
  .mt-phone-1,
  .my-phone-1 {
    margin-top: 0.25rem !important;
  }
  .mr-phone-1,
  .mx-phone-1 {
    margin-right: 0.25rem !important;
  }
  .mb-phone-1,
  .my-phone-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-phone-1,
  .mx-phone-1 {
    margin-left: 0.25rem !important;
  }
  .m-phone-2 {
    margin: 0.5rem !important;
  }
  .mt-phone-2,
  .my-phone-2 {
    margin-top: 0.5rem !important;
  }
  .mr-phone-2,
  .mx-phone-2 {
    margin-right: 0.5rem !important;
  }
  .mb-phone-2,
  .my-phone-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-phone-2,
  .mx-phone-2 {
    margin-left: 0.5rem !important;
  }
  .m-phone-3 {
    margin: 1rem !important;
  }
  .mt-phone-3,
  .my-phone-3 {
    margin-top: 1rem !important;
  }
  .mr-phone-3,
  .mx-phone-3 {
    margin-right: 1rem !important;
  }
  .mb-phone-3,
  .my-phone-3 {
    margin-bottom: 1rem !important;
  }
  .ml-phone-3,
  .mx-phone-3 {
    margin-left: 1rem !important;
  }
  .m-phone-4 {
    margin: 1.5rem !important;
  }
  .mt-phone-4,
  .my-phone-4 {
    margin-top: 1.5rem !important;
  }
  .mr-phone-4,
  .mx-phone-4 {
    margin-right: 1.5rem !important;
  }
  .mb-phone-4,
  .my-phone-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-phone-4,
  .mx-phone-4 {
    margin-left: 1.5rem !important;
  }
  .m-phone-5 {
    margin: 3rem !important;
  }
  .mt-phone-5,
  .my-phone-5 {
    margin-top: 3rem !important;
  }
  .mr-phone-5,
  .mx-phone-5 {
    margin-right: 3rem !important;
  }
  .mb-phone-5,
  .my-phone-5 {
    margin-bottom: 3rem !important;
  }
  .ml-phone-5,
  .mx-phone-5 {
    margin-left: 3rem !important;
  }
  .p-phone-0 {
    padding: 0 !important;
  }
  .pt-phone-0,
  .py-phone-0 {
    padding-top: 0 !important;
  }
  .pr-phone-0,
  .px-phone-0 {
    padding-right: 0 !important;
  }
  .pb-phone-0,
  .py-phone-0 {
    padding-bottom: 0 !important;
  }
  .pl-phone-0,
  .px-phone-0 {
    padding-left: 0 !important;
  }
  .p-phone-1 {
    padding: 0.25rem !important;
  }
  .pt-phone-1,
  .py-phone-1 {
    padding-top: 0.25rem !important;
  }
  .pr-phone-1,
  .px-phone-1 {
    padding-right: 0.25rem !important;
  }
  .pb-phone-1,
  .py-phone-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-phone-1,
  .px-phone-1 {
    padding-left: 0.25rem !important;
  }
  .p-phone-2 {
    padding: 0.5rem !important;
  }
  .pt-phone-2,
  .py-phone-2 {
    padding-top: 0.5rem !important;
  }
  .pr-phone-2,
  .px-phone-2 {
    padding-right: 0.5rem !important;
  }
  .pb-phone-2,
  .py-phone-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-phone-2,
  .px-phone-2 {
    padding-left: 0.5rem !important;
  }
  .p-phone-3 {
    padding: 1rem !important;
  }
  .pt-phone-3,
  .py-phone-3 {
    padding-top: 1rem !important;
  }
  .pr-phone-3,
  .px-phone-3 {
    padding-right: 1rem !important;
  }
  .pb-phone-3,
  .py-phone-3 {
    padding-bottom: 1rem !important;
  }
  .pl-phone-3,
  .px-phone-3 {
    padding-left: 1rem !important;
  }
  .p-phone-4 {
    padding: 1.5rem !important;
  }
  .pt-phone-4,
  .py-phone-4 {
    padding-top: 1.5rem !important;
  }
  .pr-phone-4,
  .px-phone-4 {
    padding-right: 1.5rem !important;
  }
  .pb-phone-4,
  .py-phone-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-phone-4,
  .px-phone-4 {
    padding-left: 1.5rem !important;
  }
  .p-phone-5 {
    padding: 3rem !important;
  }
  .pt-phone-5,
  .py-phone-5 {
    padding-top: 3rem !important;
  }
  .pr-phone-5,
  .px-phone-5 {
    padding-right: 3rem !important;
  }
  .pb-phone-5,
  .py-phone-5 {
    padding-bottom: 3rem !important;
  }
  .pl-phone-5,
  .px-phone-5 {
    padding-left: 3rem !important;
  }
  .m-phone-n1 {
    margin: -0.25rem !important;
  }
  .mt-phone-n1,
  .my-phone-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-phone-n1,
  .mx-phone-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-phone-n1,
  .my-phone-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-phone-n1,
  .mx-phone-n1 {
    margin-left: -0.25rem !important;
  }
  .m-phone-n2 {
    margin: -0.5rem !important;
  }
  .mt-phone-n2,
  .my-phone-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-phone-n2,
  .mx-phone-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-phone-n2,
  .my-phone-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-phone-n2,
  .mx-phone-n2 {
    margin-left: -0.5rem !important;
  }
  .m-phone-n3 {
    margin: -1rem !important;
  }
  .mt-phone-n3,
  .my-phone-n3 {
    margin-top: -1rem !important;
  }
  .mr-phone-n3,
  .mx-phone-n3 {
    margin-right: -1rem !important;
  }
  .mb-phone-n3,
  .my-phone-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-phone-n3,
  .mx-phone-n3 {
    margin-left: -1rem !important;
  }
  .m-phone-n4 {
    margin: -1.5rem !important;
  }
  .mt-phone-n4,
  .my-phone-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-phone-n4,
  .mx-phone-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-phone-n4,
  .my-phone-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-phone-n4,
  .mx-phone-n4 {
    margin-left: -1.5rem !important;
  }
  .m-phone-n5 {
    margin: -3rem !important;
  }
  .mt-phone-n5,
  .my-phone-n5 {
    margin-top: -3rem !important;
  }
  .mr-phone-n5,
  .mx-phone-n5 {
    margin-right: -3rem !important;
  }
  .mb-phone-n5,
  .my-phone-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-phone-n5,
  .mx-phone-n5 {
    margin-left: -3rem !important;
  }
  .m-phone-auto {
    margin: auto !important;
  }
  .mt-phone-auto,
  .my-phone-auto {
    margin-top: auto !important;
  }
  .mr-phone-auto,
  .mx-phone-auto {
    margin-right: auto !important;
  }
  .mb-phone-auto,
  .my-phone-auto {
    margin-bottom: auto !important;
  }
  .ml-phone-auto,
  .mx-phone-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1500px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 480px) {
  .text-phone-left {
    text-align: left !important;
  }
  .text-phone-right {
    text-align: right !important;
  }
  .text-phone-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1500px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

body {
  font-weight: 400;
  font-style: normal;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div#top {
  position: absolute;
  top: 0px;
  visibility: hidden;
  z-index: -1;
}

div#bottom {
  position: relative;
  top: -50px;
}

.container {
  padding: 0;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .container {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 575.98px) {
  .container {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.bob-frame-content {
  display: none;
}

.scroll-page.position-top > div:first-child {
  padding-top: 160px;
}

div, a, a:link, a:hover, a:visited,
input, button, label {
  -webkit-tap-highlight-color: rgba(200, 200, 200, 0); /* alpha=0 disables default highlighting */
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  text-rendering: auto;
  font-family: "Jost", sans-serif;
  margin-bottom: 12px;
}

h1, .h1 {
  font-weight: 300;
}

h2, .h2 {
  font-weight: 300;
}

h3, .h3 {
  font-weight: 400;
}

h4, .h4 {
  font-weight: 600;
}

h1 small, .h1 small {
  font-size: 1.5rem;
  font-weight: 300;
}

h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small {
  font-size: 1.25rem;
  font-weight: 300;
}

h1.legend {
  padding-bottom: 6px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 12px;
}

.font-thin {
  font-weight: 300;
}

.formal-content.terms a {
  hyphens: auto;
}

nobr {
  white-space: nowrap;
  hyphens: none;
}

blockquote {
  margin-bottom: 12px;
  border-left: 0 none;
}
blockquote p {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 300;
  font-style: italic;
  position: relative;
}
blockquote p:last-child {
  margin-bottom: 0;
}
blockquote p:before {
  position: absolute;
  top: -80px;
  left: -40px;
  z-index: -1;
  content: "„";
  font-size: 100px;
  font-weight: 600;
  font-style: italic;
  color: var(--light);
}

input[type=submit] {
  font-style: normal;
  font-weight: 300;
}

input,
textarea,
button {
  -webkit-font-smoothing: inherit;
}

.btn-custom {
  color: #fff;
  background-color: #376a85;
  border-color: #376a85;
}
.btn-custom:hover {
  color: #fff;
  background-color: #2c546a;
  border-color: #284d61;
}
.btn-custom:focus, .btn-custom.focus {
  color: #fff;
  background-color: #2c546a;
  border-color: #284d61;
  box-shadow: 0 0 0 0.2rem rgba(85, 128, 151, 0.5);
}
.btn-custom.disabled, .btn-custom:disabled {
  color: #fff;
  background-color: #376a85;
  border-color: #376a85;
}
.btn-custom:not(:disabled):not(.disabled):active, .btn-custom:not(:disabled):not(.disabled).active, .show > .btn-custom.dropdown-toggle {
  color: #fff;
  background-color: #284d61;
  border-color: #244658;
}
.btn-custom:not(:disabled):not(.disabled):active:focus, .btn-custom:not(:disabled):not(.disabled).active:focus, .show > .btn-custom.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 128, 151, 0.5);
}

.btn-app {
  color: #fff;
  background-color: #376a85;
  border-color: #376a85;
}
.btn-app:hover {
  color: #fff;
  background-color: #2c546a;
  border-color: #284d61;
}
.btn-app:focus, .btn-app.focus {
  color: #fff;
  background-color: #2c546a;
  border-color: #284d61;
  box-shadow: 0 0 0 0.2rem rgba(85, 128, 151, 0.5);
}
.btn-app.disabled, .btn-app:disabled {
  color: #fff;
  background-color: #376a85;
  border-color: #376a85;
}
.btn-app:not(:disabled):not(.disabled):active, .btn-app:not(:disabled):not(.disabled).active, .show > .btn-app.dropdown-toggle {
  color: #fff;
  background-color: #284d61;
  border-color: #244658;
}
.btn-app:not(:disabled):not(.disabled):active:focus, .btn-app:not(:disabled):not(.disabled).active:focus, .show > .btn-app.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 128, 151, 0.5);
}

.btn-sec {
  color: #212529;
  background-color: #b9e2f2;
  border-color: #b9e2f2;
}
.btn-sec:hover {
  color: #212529;
  background-color: #99d5ec;
  border-color: #8ed0ea;
}
.btn-sec:focus, .btn-sec.focus {
  color: #212529;
  background-color: #99d5ec;
  border-color: #8ed0ea;
  box-shadow: 0 0 0 0.2rem rgba(162, 198, 212, 0.5);
}
.btn-sec.disabled, .btn-sec:disabled {
  color: #212529;
  background-color: #b9e2f2;
  border-color: #b9e2f2;
}
.btn-sec:not(:disabled):not(.disabled):active, .btn-sec:not(:disabled):not(.disabled).active, .show > .btn-sec.dropdown-toggle {
  color: #212529;
  background-color: #8ed0ea;
  border-color: #83cce8;
}
.btn-sec:not(:disabled):not(.disabled):active:focus, .btn-sec:not(:disabled):not(.disabled).active:focus, .show > .btn-sec.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 198, 212, 0.5);
}

.btn-link {
  color: #376a85;
}
.btn-link:focus, .btn-link.focus, .btn-link:hover {
  color: #284d61;
}

.btn-link-app {
  color: #376a85;
}
.btn-link-app:focus, .btn-link-app.focus, .btn-link-app:hover {
  color: #284d61;
}

.glyphicons {
  vertical-align: baseline;
}
.glyphicons.glyphicons-gaia {
  transform: rotate(90deg);
}
.glyphicons.glyphicons-gaia:before {
  content: "\e174";
}

.noscript {
  visibility: visible;
}
.noscript div.noscript-msg {
  display: block;
  position: absolute;
  z-index: 9999;
  top: 100px;
  left: 50%;
  margin-left: -150px;
  width: 300px;
}

@media (min-width: 576px) {
  .noscript div.noscript-msg {
    margin-left: -200px;
    width: 400px;
  }
}
body.bob-frame-open .body-content {
  display: none;
}

.body-content {
  position: relative;
}

.bob-frame {
  display: none;
  overflow: hidden;
  position: relative;
  padding-left: calc(100vw - 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  z-index: 1040;
  background: #ffffff;
}
.bob-frame.bob-fading-in {
  position: fixed;
  top: -1;
  left: 0;
}
.bob-frame .bob-window {
  margin: 0 auto;
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  border: 0 none;
}
.bob-frame .bob-window .bob-header img {
  display: block;
  width: 110px;
  height: auto;
  margin: 10px 0 0 0;
}
.bob-frame .bob-window .bob-content {
  margin-top: 25px;
}

.bob-frame-container {
  padding: 0 30px;
}

@media (min-width: 576px) {
  .bob-frame .bob-window .bob-content {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  .bob-frame .bob-window .bob-content {
    margin-top: 40px;
  }
}
.bob-msg {
  display: none;
  position: fixed;
  top: 13px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 1080;
  outline: none;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  cursor: pointer;
}
.bob-msg .msg-content {
  display: none;
  border-radius: 0.3rem;
  padding: 30px 30px 30px 25px;
}
.bob-msg .msg-content::after {
  display: block;
  clear: both;
  content: "";
}
.bob-msg .msg-content i,
.bob-msg .msg-content span.glyphicons {
  float: left;
  width: 40px;
  font-size: 24px;
}
.bob-msg .msg-content span:not(.glyphicons) {
  display: block;
  margin-left: 40px;
  margin-right: 40px;
}
.bob-msg .msg-success i {
  color: #28a745;
}
.bob-msg .msg-success span.glyphicons {
  fill: #28a745;
}
.bob-msg .msg-error i,
.bob-msg .msg-error-checksum i,
.bob-msg .msg-error-request-declined i,
.bob-msg .msg-error-declined i,
.bob-msg .msg-error-expired i,
.bob-msg .msg-error-notfound i,
.bob-msg .msg-error-insurer i {
  color: #dc3545;
}
.bob-msg .msg-error span.glyphicons,
.bob-msg .msg-error-checksum span.glyphicons,
.bob-msg .msg-error-request-declined span.glyphicons,
.bob-msg .msg-error-declined span.glyphicons,
.bob-msg .msg-error-expired span.glyphicons,
.bob-msg .msg-error-notfound span.glyphicons,
.bob-msg .msg-error-insurer span.glyphicons {
  fill: #dc3545;
}
.bob-msg .msg-warning i {
  color: #ffc107;
}
.bob-msg .msg-warning span.glyphicons {
  fill: #ffc107;
}
.bob-msg .msg-warning-access i,
.bob-msg .msg-warning-access span.glyphicons {
  fill: #ffc107;
}

.bob-frame .bob-close-vis,
.bob-msg .bob-close-vis {
  position: absolute;
  top: 13px;
  right: 19px;
  font-size: 40px;
  padding: 4px 0.9rem 8px 0.9rem;
  line-height: 39px;
  border: none;
  border-radius: 50px;
  background-color: #fff;
}
.bob-frame .bob-close-vis:focus, .bob-frame .bob-close-vis:active,
.bob-msg .bob-close-vis:focus,
.bob-msg .bob-close-vis:active {
  outline: 0 none;
  background-color: rgb(230, 230, 230);
  border-radius: 50px;
}
@supports (-moz-appearance: none) {
  .bob-frame .bob-close-vis,
  .bob-msg .bob-close-vis {
    padding: 4px 0.9rem 7px 0.9rem;
    line-height: 42px;
  }
}

body.bob-frame-open .body-content {
  display: none;
}

@media (min-width: 576px) {
  .bob-msg {
    top: 30px;
  }
}
.container-form {
  position: relative;
  padding: 30px;
  min-width: 300px;
  width: 600px;
}

@media (max-width: 991.98px) {
  .container-form {
    width: auto;
  }
}
legend {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.2;
  padding-bottom: 6px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
}

label {
  font-weight: 300;
}

input, select, textarea {
  font-family: "Jost", sans-serif;
  font-style: italic;
}

textarea.form-control {
  resize: vertical;
}

.form-control.error {
  border: 1px solid #ff0000;
}

.form-contact_pro .dateTime,
.form-neuro .dateTime {
  display: none;
}
.form-contact_pro .dateTime.show,
.form-neuro .dateTime.show {
  display: flex;
}

.up-sm,
.up-md,
.up-lg,
.up-xl,
.up-sm-inline,
.up-md-inline,
.up-lg-inline,
.up-xl-inline {
  display: none;
}

.nobr {
  white-space: nowrap;
}

.down-sm {
  display: block;
}

.anchor {
  position: relative;
  top: -80px;
}

@media (min-width: 576px) {
  .down-md {
    display: block;
  }
  .down-md-inline {
    display: inline;
  }
  .up-sm {
    display: block;
  }
  .up-sm-inline {
    display: inline;
  }
  .down-sm,
  .down-sm-inline {
    display: none;
  }
}
@media (min-width: 768px) {
  .down-md,
  .down-md-inline {
    display: none;
  }
  .up-md {
    display: block;
  }
  .up-md-inline {
    display: inline;
  }
  .down-lg {
    display: block;
  }
  .down-lg-inline {
    display: inline;
  }
}
@media (min-width: 992px) {
  .down-lg,
  .down-lg-inline {
    display: none;
  }
  .up-lg {
    display: block;
  }
  .up-lg-inline {
    display: inline;
  }
}
@media (min-width: 1500px) {
  .up-xl {
    display: block;
  }
  .up-xl-inline {
    display: inline;
  }
}
.txt-shadow {
  text-shadow: 0px 0px 1px #333333;
}

.icons {
  display: none;
}

.glyphicons,
.glyphicons-not-copied {
  position: relative;
  top: 1px;
  display: inline-block;
  line-height: 1;
  vertical-align: top;
}
.glyphicons svg,
.glyphicons-not-copied svg {
  margin: 0 auto;
  width: 28px;
  height: 28px;
}

span.glyphicons,
span.glyphicons-not-copied {
  top: 0;
}

.msg-success .glyphicons svg path {
  fill: #28a745;
}

.msg-warning .glyphicons svg path {
  fill: #ffc107;
}

.msg-error .glyphicons svg path {
  fill: #dc3545;
}

#scrolltop {
  position: fixed;
  z-index: 900;
  bottom: 20px;
  right: 20px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  transition: all 0.3s ease;
}
#scrolltop span {
  position: relative;
  transition: all 0.3s ease;
}
#scrolltop i,
#scrolltop span.glyphicons {
  margin: 2px 3px 3px;
  fill: #fff;
}
#scrolltop:focus, #scrolltop:active {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #d1d1d1;
}

#scrolltop:hover {
  text-decoration: none;
}

.spinner {
  display: none;
  float: left;
  position: relative;
  top: 24px;
  width: 16px;
  height: 1px;
  margin-left: 20px;
}
.spinner .blockG {
  position: absolute;
  width: 6px;
  height: 2px;
  border-radius: 1px;
  -webkit-animation-name: fadeG;
  -moz-animation-name: fadeG;
  -ms-animation-name: fadeG;
  -o-animation-name: fadeG;
  animation-name: fadeG;
  -webkit-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: linear;
  -moz-animation-direction: linear;
  -ms-animation-direction: linear;
  -o-animation-direction: linear;
  animation-direction: linear;
  background-color: #FFFFFF;
}
.spinner .rotateG_01 {
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  -ms-animation-delay: -1s;
  -o-animation-delay: -1s;
  animation-delay: -1s;
  -webkit-transform: rotate(-90deg) translate(8px, 0px);
  -moz-transform: rotate(-90deg) translate(8px, 0px);
  -ms-transform: rotate(-90deg) translate(8px, 0px);
  -o-transform: rotate(-90deg) translate(8px, 0px);
  transform: rotate(-90deg) translate(8px, 0px);
}
.spinner .rotateG_02 {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -ms-animation-delay: -0.9s;
  -o-animation-delay: -0.9s;
  animation-delay: -0.9s;
  -webkit-transform: rotate(-60deg) translate(8px, 0px);
  -moz-transform: rotate(-60deg) translate(8px, 0px);
  -ms-transform: rotate(-60deg) translate(8px, 0px);
  -o-transform: rotate(-60deg) translate(8px, 0px);
  transform: rotate(-60deg) translate(8px, 0px);
}
.spinner .rotateG_03 {
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  -ms-animation-delay: -0.8s;
  -o-animation-delay: -0.8s;
  animation-delay: -0.8s;
  -webkit-transform: rotate(-30deg) translate(8px, 0px);
  -moz-transform: rotate(-30deg) translate(8px, 0px);
  -ms-transform: rotate(-30deg) translate(8px, 0px);
  -o-transform: rotate(-30deg) translate(8px, 0px);
  transform: rotate(-30deg) translate(8px, 0px);
}
.spinner .rotateG_04 {
  -webkit-animation-delay: -0.7s;
  -moz-animation-delay: -0.7s;
  -ms-animation-delay: -0.7s;
  -o-animation-delay: -0.7s;
  animation-delay: -0.7s;
  -webkit-transform: rotate(0deg) translate(8px, 0px);
  -moz-transform: rotate(0deg) translate(8px, 0px);
  -ms-transform: rotate(0deg) translate(8px, 0px);
  -o-transform: rotate(0deg) translate(8px, 0px);
  transform: rotate(0deg) translate(8px, 0px);
}
.spinner .rotateG_05 {
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
  -ms-animation-delay: -0.6s;
  -o-animation-delay: -0.6s;
  animation-delay: -0.6s;
  -webkit-transform: rotate(30deg) translate(8px, 0px);
  -moz-transform: rotate(30deg) translate(8px, 0px);
  -ms-transform: rotate(30deg) translate(8px, 0px);
  -o-transform: rotate(30deg) translate(8px, 0px);
  transform: rotate(30deg) translate(8px, 0px);
}
.spinner .rotateG_06 {
  -webkit-animation-delay: -0.5s;
  -moz-animation-delay: -0.5s;
  -ms-animation-delay: -0.5s;
  -o-animation-delay: -0.5s;
  animation-delay: -0.5s;
  -webkit-transform: rotate(60deg) translate(8px, 0px);
  -moz-transform: rotate(60deg) translate(8px, 0px);
  -ms-transform: rotate(60deg) translate(8px, 0px);
  -o-transform: rotate(60deg) translate(8px, 0px);
  transform: rotate(60deg) translate(8px, 0px);
}
.spinner .rotateG_07 {
  -webkit-animation-delay: -0.4s;
  -moz-animation-delay: -0.4s;
  -ms-animation-delay: -0.4s;
  -o-animation-delay: -0.4s;
  animation-delay: -0.4s;
  -webkit-transform: rotate(90deg) translate(8px, 0px);
  -moz-transform: rotate(90deg) translate(8px, 0px);
  -ms-transform: rotate(90deg) translate(8px, 0px);
  -o-transform: rotate(90deg) translate(8px, 0px);
  transform: rotate(90deg) translate(8px, 0px);
}
.spinner .rotateG_08 {
  -webkit-animation-delay: -0.3s;
  -moz-animation-delay: -0.3s;
  -ms-animation-delay: -0.3s;
  -o-animation-delay: -0.3s;
  animation-delay: -0.3s;
  -webkit-transform: rotate(120deg) translate(8px, 0px);
  -moz-transform: rotate(120deg) translate(8px, 0px);
  -ms-transform: rotate(120deg) translate(8px, 0px);
  -o-transform: rotate(120deg) translate(8px, 0px);
  transform: rotate(120deg) translate(8px, 0px);
}
.spinner .rotateG_09 {
  -webkit-animation-delay: -0.2s;
  -moz-animation-delay: -0.2s;
  -ms-animation-delay: -0.2s;
  -o-animation-delay: -0.2s;
  animation-delay: -0.2s;
  -webkit-transform: rotate(150deg) translate(8px, 0px);
  -moz-transform: rotate(150deg) translate(8px, 0px);
  -ms-transform: rotate(150deg) translate(8px, 0px);
  -o-transform: rotate(150deg) translate(8px, 0px);
  transform: rotate(150deg) translate(8px, 0px);
}
.spinner .rotateG_10 {
  -webkit-animation-delay: -0.1s;
  -moz-animation-delay: -0.1s;
  -ms-animation-delay: -0.1s;
  -o-animation-delay: -0.1s;
  animation-delay: -0.1s;
  -webkit-transform: rotate(180deg) translate(8px, 0px);
  -moz-transform: rotate(180deg) translate(8px, 0px);
  -ms-transform: rotate(180deg) translate(8px, 0px);
  -o-transform: rotate(180deg) translate(8px, 0px);
  transform: rotate(180deg) translate(8px, 0px);
}
.spinner .rotateG_11 {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(-150deg) translate(8px, 0px);
  -moz-transform: rotate(-150deg) translate(8px, 0px);
  -ms-transform: rotate(-150deg) translate(8px, 0px);
  -o-transform: rotate(-150deg) translate(8px, 0px);
  transform: rotate(-150deg) translate(8px, 0px);
}
.spinner .rotateG_12 {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -ms-animation-delay: 0.1s;
  -o-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-transform: rotate(-120deg) translate(8px, 0px);
  -moz-transform: rotate(-120deg) translate(8px, 0px);
  -ms-transform: rotate(-120deg) translate(8px, 0px);
  -o-transform: rotate(-120deg) translate(8px, 0px);
  transform: rotate(-120deg) translate(8px, 0px);
}

@-moz-keyframes fadeG {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@-webkit-keyframes fadeG {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@-ms-keyframes fadeG {
  0% {
    filter: alpha(opacity=100);
  }
  100% {
    filter: alpha(opacity=40);
  }
}
@-o-keyframes fadeG {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes fadeG {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
.spinner-modal-help {
  display: block;
  position: relative;
}
.spinner-modal-help .spinner {
  float: none;
  display: block;
  position: absolute;
  top: 40px;
  left: 50%;
  margin: 0;
}
.spinner-modal-help .spinner div {
  background-color: #f0f0f0;
}

.total-cover,
.foggy-cover {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.total-cover {
  z-index: 1101;
}

.foggy-cover {
  background: #fff;
  opacity: 0.6;
  z-index: 1100;
}

.total-cover .spinner {
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 160px;
  margin: -20px 0 0 -20px;
  z-index: 1102;
}
.total-cover .spinner div.blockG {
  width: 30px;
  height: 8px;
  border-radius: 4px;
  background-color: #8a8a8a;
  -webkit-animation-name: fadeG;
  -moz-animation-name: fadeG;
  -ms-animation-name: fadeG;
  -o-animation-name: fadeG;
  animation-name: fadeG;
}
.total-cover .spinner .rotateG_01 {
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  -ms-animation-delay: -1s;
  -o-animation-delay: -1s;
  animation-delay: -1s;
  -webkit-transform: rotate(-90deg) translate(40px, 0px);
  -moz-transform: rotate(-90deg) translate(40px, 0px);
  -ms-transform: rotate(-90deg) translate(40px, 0px);
  -o-transform: rotate(-90deg) translate(40px, 0px);
  transform: rotate(-90deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_02 {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -ms-animation-delay: -0.9s;
  -o-animation-delay: -0.9s;
  animation-delay: -0.9s;
  -webkit-transform: rotate(-60deg) translate(40px, 0px);
  -moz-transform: rotate(-60deg) translate(40px, 0px);
  -ms-transform: rotate(-60deg) translate(40px, 0px);
  -o-transform: rotate(-60deg) translate(40px, 0px);
  transform: rotate(-60deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_03 {
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  -ms-animation-delay: -0.8s;
  -o-animation-delay: -0.8s;
  animation-delay: -0.8s;
  -webkit-transform: rotate(-30deg) translate(40px, 0px);
  -moz-transform: rotate(-30deg) translate(40px, 0px);
  -ms-transform: rotate(-30deg) translate(40px, 0px);
  -o-transform: rotate(-30deg) translate(40px, 0px);
  transform: rotate(-30deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_04 {
  -webkit-animation-delay: -0.7s;
  -moz-animation-delay: -0.7s;
  -ms-animation-delay: -0.7s;
  -o-animation-delay: -0.7s;
  animation-delay: -0.7s;
  -webkit-transform: rotate(0deg) translate(40px, 0px);
  -moz-transform: rotate(0deg) translate(40px, 0px);
  -ms-transform: rotate(0deg) translate(40px, 0px);
  -o-transform: rotate(0deg) translate(40px, 0px);
  transform: rotate(0deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_05 {
  -webkit-animation-delay: -0.6s;
  -moz-animation-delay: -0.6s;
  -ms-animation-delay: -0.6s;
  -o-animation-delay: -0.6s;
  animation-delay: -0.6s;
  -webkit-transform: rotate(30deg) translate(40px, 0px);
  -moz-transform: rotate(30deg) translate(40px, 0px);
  -ms-transform: rotate(30deg) translate(40px, 0px);
  -o-transform: rotate(30deg) translate(40px, 0px);
  transform: rotate(30deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_06 {
  -webkit-animation-delay: -0.5s;
  -moz-animation-delay: -0.5s;
  -ms-animation-delay: -0.5s;
  -o-animation-delay: -0.5s;
  animation-delay: -0.5s;
  -webkit-transform: rotate(60deg) translate(40px, 0px);
  -moz-transform: rotate(60deg) translate(40px, 0px);
  -ms-transform: rotate(60deg) translate(40px, 0px);
  -o-transform: rotate(60deg) translate(40px, 0px);
  transform: rotate(60deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_07 {
  -webkit-animation-delay: -0.4s;
  -moz-animation-delay: -0.4s;
  -ms-animation-delay: -0.4s;
  -o-animation-delay: -0.4s;
  animation-delay: -0.4s;
  -webkit-transform: rotate(90deg) translate(40px, 0px);
  -moz-transform: rotate(90deg) translate(40px, 0px);
  -ms-transform: rotate(90deg) translate(40px, 0px);
  -o-transform: rotate(90deg) translate(40px, 0px);
  transform: rotate(90deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_08 {
  -webkit-animation-delay: -0.3s;
  -moz-animation-delay: -0.3s;
  -ms-animation-delay: -0.3s;
  -o-animation-delay: -0.3s;
  animation-delay: -0.3s;
  -webkit-transform: rotate(120deg) translate(40px, 0px);
  -moz-transform: rotate(120deg) translate(40px, 0px);
  -ms-transform: rotate(120deg) translate(40px, 0px);
  -o-transform: rotate(120deg) translate(40px, 0px);
  transform: rotate(120deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_09 {
  -webkit-animation-delay: -0.2s;
  -moz-animation-delay: -0.2s;
  -ms-animation-delay: -0.2s;
  -o-animation-delay: -0.2s;
  animation-delay: -0.2s;
  -webkit-transform: rotate(150deg) translate(40px, 0px);
  -moz-transform: rotate(150deg) translate(40px, 0px);
  -ms-transform: rotate(150deg) translate(40px, 0px);
  -o-transform: rotate(150deg) translate(40px, 0px);
  transform: rotate(150deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_10 {
  -webkit-animation-delay: -0.1s;
  -moz-animation-delay: -0.1s;
  -ms-animation-delay: -0.1s;
  -o-animation-delay: -0.1s;
  animation-delay: -0.1s;
  -webkit-transform: rotate(180deg) translate(40px, 0px);
  -moz-transform: rotate(180deg) translate(40px, 0px);
  -ms-transform: rotate(180deg) translate(40px, 0px);
  -o-transform: rotate(180deg) translate(40px, 0px);
  transform: rotate(180deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_11 {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(-150deg) translate(40px, 0px);
  -moz-transform: rotate(-150deg) translate(40px, 0px);
  -ms-transform: rotate(-150deg) translate(40px, 0px);
  -o-transform: rotate(-150deg) translate(40px, 0px);
  transform: rotate(-150deg) translate(40px, 0px);
}
.total-cover .spinner .rotateG_12 {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -ms-animation-delay: 0.1s;
  -o-animation-delay: 0.1s;
  animation-delay: 0.1s;
  -webkit-transform: rotate(-120deg) translate(40px, 0px);
  -moz-transform: rotate(-120deg) translate(40px, 0px);
  -ms-transform: rotate(-120deg) translate(40px, 0px);
  -o-transform: rotate(-120deg) translate(40px, 0px);
  transform: rotate(-120deg) translate(40px, 0px);
}

.navbar-dark .navbar-nav .nav-link.activeItem {
  color: #fff;
}

.bob-frame.contains-bob-menu {
  background-color: #376a85;
}
.bob-frame.contains-bob-menu .bob-frame-container {
  padding: 0 20px 0 25%;
}
.bob-frame.contains-bob-menu .bob-frame-container .bob-content {
  margin-top: 10px;
  margin-right: 5px;
}
.bob-frame.contains-bob-menu .navbar-nav {
  margin-bottom: 0px;
}
.bob-frame.contains-bob-menu .nav-item {
  width: 200px;
  line-height: 2;
}
.bob-frame.contains-bob-menu .nav-item a {
  color: #fff;
  font-weight: 500;
}
.bob-frame.contains-bob-menu .nav-item a.activeItem {
  color: #fff;
}
.bob-frame.contains-bob-menu .nav-item:after {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0px;
  display: block;
  z-index: 1;
}
.bob-frame.contains-bob-menu .dropup,
.bob-frame.contains-bob-menu .dropright,
.bob-frame.contains-bob-menu .dropdown,
.bob-frame.contains-bob-menu .dropleft {
  position: static;
}
.bob-frame.contains-bob-menu .dropdown-menu {
  display: none;
}
.bob-frame.contains-bob-menu .dropdown-menu.show {
  border: none;
  margin: 0 auto;
  display: block;
  left: 0;
  width: 160px;
  transform: initial;
}
.bob-frame.contains-bob-menu .dropdown-menu.show .dropdown-item {
  padding: 0.25rem 0.9rem;
  line-height: 3;
}
.bob-frame.contains-bob-menu .bob-navbar {
  padding: 0;
}
.bob-frame.contains-bob-menu .bob-navbar .container {
  padding: 0;
}
.bob-frame.contains-bob-menu .bob-navbar .container .navbar-inner {
  margin-top: 30px;
}
.bob-frame.contains-bob-menu .bob-header,
.bob-frame.contains-bob-menu .bob-close-outer {
  display: none;
}
.bob-frame.contains-bob-menu .navbar-logo {
  line-height: 100px;
  z-index: 2;
}
.bob-frame.contains-bob-menu .navbar-logo .bob-menu-label {
  text-transform: uppercase;
  color: #fff;
  margin-top: 30px;
  font-weight: 600;
  font-size: 24px;
}
.bob-frame.contains-bob-menu .bob-navbar-header {
  width: 100%;
  display: block;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.bob-frame.contains-bob-menu .bob-close {
  position: relative;
  z-index: 10;
  top: 0px;
  right: 0px;
  background-color: #376a85;
  color: #fff;
}
.bob-frame.contains-bob-menu .bob-close:hover {
  background-color: #0a2538;
}
.bob-frame.contains-bob-menu .dropdown-menu.show {
  position: static;
}
.bob-frame.contains-bob-menu .nav-item.dropdown .nav-link:after {
  content: "-";
  position: relative;
  display: block;
  line-height: 0;
  top: -24px;
  right: -200px;
  transition: 0.4s;
  font-size: 27px;
  font-style: initial;
}
.bob-frame.contains-bob-menu .nav-item.dropdown .nav-link:not(.active):after {
  content: "+";
}
.bob-frame.contains-bob-menu .hide-in-nav {
  display: none;
}
@media (min-width: 576px) {
  .bob-frame.contains-bob-menu .navbar {
    top: -2px;
  }
}

a.first-button {
  border: none;
  width: 70px;
}
a.first-button:focus {
  outline: none;
}
a.first-button .menu-label {
  width: 40px;
  margin-top: 8px;
  color: #fff;
  font-size: 17px;
  margin-left: auto;
  margin-right: auto;
}

.animated-icon1 {
  width: 40px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.animated-icon1 span,
.animated-icon2 span,
.animated-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.animated-icon1 span {
  background: #fff;
}

.animated-icon2 span {
  background: #e3f2fd;
}

.animated-icon3 span {
  background: #f3e5f5;
}

.animated-icon1 span:nth-child(1) {
  top: 0px;
}

.animated-icon1 span:nth-child(2) {
  top: 10px;
}

.animated-icon1 span:nth-child(3) {
  top: 20px;
}

.navbar-dark {
  background-color: #376a85;
}

.navbar {
  top: 0px;
  transition: margin-top 0.3s, padding-top 0.3s;
  padding: 0px 1rem;
}
.navbar .container {
  transition: margin-top 0.3s;
  margin-top: 33px;
  padding-bottom: 30px;
  padding-top: 5px;
}

.navbar-logo-outer {
  width: 150px;
}

.nav-logo {
  transition: width 0.3s, margin 0.3s;
  width: 110px;
  height: auto;
}

.hm-logo {
  width: 150px;
}

.project-optimune .nav-logo {
  width: 150px;
}

.project-somnovia .nav-logo {
  padding-bottom: 5px;
}

.project-liebria .navbar-logo-outer img {
  width: 80%;
}

.navbar.navbar-animate {
  padding-top: 0px;
  margin-top: -160px;
}
.navbar.navbar-animate .container {
  margin-top: 0;
}

.navbar-secondary {
  display: table;
  position: relative;
  float: right;
  padding-right: 15px;
}
.navbar-secondary a {
  color: #ffffff;
}

.navbar-right a {
  margin-right: 15px;
}

.nav-link i svg {
  width: 20px;
  height: 20px;
  margin: 1px 5px 0 0;
}

.navbar-nav {
  display: block;
}

.nav-item a {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}
.nav-item img {
  display: block;
  margin-left: auto;
  margin-bottom: -12px;
}

.mobile img {
  margin-left: 90px;
}

.navbar-btn-descr {
  display: block;
  width: auto;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  padding-top: 7px;
  color: #fff;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}

@media (min-width: 768px) {
  .page_1 .navbar li > a > .page_1,
  .page_2 .navbar li > a > .page_2,
  .page_3 .navbar li > a > .page_3,
  .page_4 .navbar li > a > .page_4 {
    border-bottom: 2px solid #ddd;
    color: #fff;
    opacity: 1;
  }
}
/* overide border-color */
.navbar-secondary .btn-nav:focus,
.navbar-nav .btn-nav:focus,
.navbar-secondary .btn-nav.focus,
.navbar-nav .btn-nav.focus {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:hover,
.navbar-nav .btn-nav:hover {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:active,
.navbar-nav .btn-nav:active,
.navbar-secondary .btn-nav.active,
.navbar-nav .btn-nav.active,
.open > .dropdown-toggle.navbar-secondary .btn-nav,
.open > .dropdown-toggle.navbar-nav .btn-nav {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:active:hover,
.navbar-nav .btn-nav:active:hover,
.navbar-secondary .btn-nav.active:hover,
.navbar-nav .btn-nav.active:hover,
.open > .dropdown-toggle.navbar-secondary .btn-nav:hover,
.open > .dropdown-toggle.navbar-nav .btn-nav:hover,
.navbar-secondary .btn-nav:active:focus,
.navbar-nav .btn-nav:active:focus,
.navbar-secondary .btn-nav.active:focus,
.navbar-nav .btn-nav.active:focus,
.open > .dropdown-toggle.navbar-secondary .btn-nav:focus,
.open > .dropdown-toggle.navbar-nav .btn-nav:focus,
.navbar-secondary .btn-nav:active.focus,
.navbar-nav .btn-nav:active.focus,
.navbar-secondary .btn-nav.active.focus,
.navbar-nav .btn-nav.active.focus,
.open > .dropdown-toggle.navbar-secondary .btn-nav.focus,
.open > .dropdown-toggle.navbar-nav .btn-nav.focus {
  border-color: #ffffff;
}

/* overide border-color - end */
@media (min-width: 576px) {
  .navbar {
    transition: 0.4s;
  }
  .navbar .container {
    padding-bottom: 0px;
  }
  .project-optimune .navbar.navbar-animate {
    padding-top: 5px;
  }
  .project-optimune .navbar.navbar-animate .nav-logo {
    width: 120px;
  }
  .navbar-nav {
    display: flex;
    margin-bottom: 0px;
  }
  .navbar-btn-descr {
    font-size: 13px;
    display: inline-block;
  }
  .nav-item a {
    font-size: 17px;
    padding-left: 10px;
  }
  .navbar-secondary {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar.navbar-animate {
    padding-top: 0px;
    margin-top: 0;
  }
  .navbar.navbar-animate .nav-logo {
    width: 95px;
  }
  .navbar.navbar-animate .container {
    margin-top: 0;
  }
  .project-velibra.page_6 .navbar.navbar-animate {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.page_5 .passage {
  margin-top: 90px;
}

.page_4 .passage {
  margin-top: 90px;
  padding-bottom: 0;
}

.project-reviga.page_4 .passage {
  padding-bottom: 140px;
  margin-bottom: 130px;
}

.project-liebria.page_4 .passage {
  margin-bottom: 90px;
}

.passage.passage-faq-button .passage-link {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.passage-anchor {
  position: relative;
  top: -100px;
}

.passage-link {
  margin-top: 20px;
}

.passage--extra-space-heading h4 {
  margin-bottom: 30px;
}

.passage-background {
  background-color: #FDEDFF;
}

.small-font p {
  font-size: 12px;
}

.short-padding {
  padding-bottom: 20px !important;
  padding-top: 0px !important;
}

.passage {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
  color: #494949;
}
.passage h1, .passage h2, .passage h3, .passage h4, .passage h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.passage h1.hyphens, .passage h2.hyphens, .passage h3.hyphens, .passage h4.hyphens, .passage h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.passage h1.white, .passage h2.white, .passage h3.white, .passage h4.white, .passage h5.white, .passage p.white {
  color: #fff;
}
.passage h1.app-color, .passage h2.app-color, .passage h3.app-color, .passage h4.app-color, .passage h5.app-color, .passage p.app-color {
  color: #376a85;
}
.passage h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.passage h2.h1 {
  font-size: 40px;
}
.passage h3 {
  padding-bottom: 20px;
}
.passage h3, .passage h4 {
  padding-top: 20px;
  font-size: 24px;
}
.passage h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.passage p, .passage h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.passage p {
  font-weight: 300;
}
.passage p.mini,
.passage p.mini-sm {
  font-size: 18px;
}
.passage p.tiny {
  font-size: 14px;
}
.passage h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .passage p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .passage h2 {
    margin-bottom: 40px;
  }
  .passage h2.h1 {
    font-size: 50px;
  }
  .passage h2.extra {
    font-size: 38px;
  }
  .passage p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .passage h3.h2-lg {
    font-size: 40px;
  }
  .passage h2 {
    margin-bottom: 40px;
  }
  .passage h2.h1 {
    font-size: 66px;
  }
  .passage h2.extra {
    font-size: 40px;
  }
  .passage p.extra {
    font-size: 30px;
  }
}
.passage h2 span, .passage h3 span, .passage p span {
  color: #376a85;
  font-weight: bold;
}
.passage .passage-svg-icon {
  height: 250px;
}
.passage img, .passage object {
  display: block;
  margin: 80px auto 0px auto;
}
.passage object {
  max-width: 370px;
}
.passage .passage-txt-citation {
  font-size: 14px;
}
.passage .passage-info-subtext p {
  font-size: 12.5px;
  line-height: 17px;
  font-weight: 400;
}
.passage .passage-row-line {
  width: 100%;
  height: 2px;
  background-color: #e6e3d9;
  margin-bottom: 40px;
}
.passage .pro-passage h2 {
  font-size: 20px;
  font-weight: 500;
}
.passage .page3 {
  margin-top: 50px;
}
.passage .padding-normal {
  padding-top: 0px;
  padding-bottom: 80px;
}

.passage-last {
  margin-bottom: 50px;
}
.passage-last h2 {
  font-size: 18px;
}
.passage-last p {
  font-size: 14px;
}

strong {
  font-weight: bold;
}

@media (min-width: 576px) {
  .page_4 .passage,
  .page_5 .passage {
    margin-top: 50px;
  }
  .page_6 .passage {
    padding-top: 0px;
    padding-bottom: 60px;
  }
  .passage {
    padding-bottom: 40px;
    padding-top: 50px;
  }
  .passage-first {
    padding-top: 70px;
  }
  .passage--padding {
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) {
  .passage h2 {
    font-size: 52px;
  }
  .passage h3 {
    font-size: 32px;
  }
}
@media (min-width: 576px) {
  .container {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (min-width: 768px) {
  .container,
  .navbar-expand > .container {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
}
.bob-style {
  color: #212529;
  background-color: #fff;
  border-color: rgb(114, 113, 113);
  margin: 3px;
  border-radius: 40px;
  border: none;
  font-size: 17px;
  line-height: 1.5;
  max-width: 400px;
}
.bob-style:hover {
  color: #212529;
  background-color: #fff;
  border-color: #333;
}
.bob-style:focus, .bob-style.focus {
  color: #212529;
  background-color: #fff;
  border-color: #333;
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}
.bob-style.disabled, .bob-style:disabled {
  color: #212529;
  background-color: #fff;
  border-color: rgb(114, 113, 113);
}
.bob-style:not(:disabled):not(.disabled):active, .bob-style:not(:disabled):not(.disabled).active, .show > .bob-style.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #525151;
}
.bob-style:not(:disabled):not(.disabled):active:focus, .bob-style:not(:disabled):not(.disabled).active:focus, .show > .bob-style.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.5);
}

.form-checksum .bob-style,
.form-checksum-renewal .bob-style,
.passage .bob-style,
.text .bob-style,
.accordion .bob-style,
.button-block .bob-style,
.rezept-form .bob-style {
  color: #fff;
  background-color: #0A2538;
  border-color: rgb(145, 145, 145);
}
.form-checksum .bob-style:hover,
.form-checksum-renewal .bob-style:hover,
.passage .bob-style:hover,
.text .bob-style:hover,
.accordion .bob-style:hover,
.button-block .bob-style:hover,
.rezept-form .bob-style:hover {
  color: #fff;
  background-color: #0A2538;
  border-color: #fff;
}
.form-checksum .bob-style:focus, .form-checksum .bob-style.focus,
.form-checksum-renewal .bob-style:focus,
.form-checksum-renewal .bob-style.focus,
.passage .bob-style:focus,
.passage .bob-style.focus,
.text .bob-style:focus,
.text .bob-style.focus,
.accordion .bob-style:focus,
.accordion .bob-style.focus,
.button-block .bob-style:focus,
.button-block .bob-style.focus,
.rezept-form .bob-style:focus,
.rezept-form .bob-style.focus {
  color: #fff;
  background-color: #0A2538;
  border-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}
.form-checksum .bob-style.disabled, .form-checksum .bob-style:disabled,
.form-checksum-renewal .bob-style.disabled,
.form-checksum-renewal .bob-style:disabled,
.passage .bob-style.disabled,
.passage .bob-style:disabled,
.text .bob-style.disabled,
.text .bob-style:disabled,
.accordion .bob-style.disabled,
.accordion .bob-style:disabled,
.button-block .bob-style.disabled,
.button-block .bob-style:disabled,
.rezept-form .bob-style.disabled,
.rezept-form .bob-style:disabled {
  color: #fff;
  background-color: #0A2538;
  border-color: rgb(145, 145, 145);
}
.form-checksum .bob-style:not(:disabled):not(.disabled):active, .form-checksum .bob-style:not(:disabled):not(.disabled).active, .show > .form-checksum .bob-style.dropdown-toggle,
.form-checksum-renewal .bob-style:not(:disabled):not(.disabled):active,
.form-checksum-renewal .bob-style:not(:disabled):not(.disabled).active,
.show > .form-checksum-renewal .bob-style.dropdown-toggle,
.passage .bob-style:not(:disabled):not(.disabled):active,
.passage .bob-style:not(:disabled):not(.disabled).active,
.show > .passage .bob-style.dropdown-toggle,
.text .bob-style:not(:disabled):not(.disabled):active,
.text .bob-style:not(:disabled):not(.disabled).active,
.show > .text .bob-style.dropdown-toggle,
.accordion .bob-style:not(:disabled):not(.disabled):active,
.accordion .bob-style:not(:disabled):not(.disabled).active,
.show > .accordion .bob-style.dropdown-toggle,
.button-block .bob-style:not(:disabled):not(.disabled):active,
.button-block .bob-style:not(:disabled):not(.disabled).active,
.show > .button-block .bob-style.dropdown-toggle,
.rezept-form .bob-style:not(:disabled):not(.disabled):active,
.rezept-form .bob-style:not(:disabled):not(.disabled).active,
.show > .rezept-form .bob-style.dropdown-toggle {
  color: #fff;
  background-color: #02080d;
  border-color: #717171;
}
.form-checksum .bob-style:not(:disabled):not(.disabled):active:focus, .form-checksum .bob-style:not(:disabled):not(.disabled).active:focus, .show > .form-checksum .bob-style.dropdown-toggle:focus,
.form-checksum-renewal .bob-style:not(:disabled):not(.disabled):active:focus,
.form-checksum-renewal .bob-style:not(:disabled):not(.disabled).active:focus,
.show > .form-checksum-renewal .bob-style.dropdown-toggle:focus,
.passage .bob-style:not(:disabled):not(.disabled):active:focus,
.passage .bob-style:not(:disabled):not(.disabled).active:focus,
.show > .passage .bob-style.dropdown-toggle:focus,
.text .bob-style:not(:disabled):not(.disabled):active:focus,
.text .bob-style:not(:disabled):not(.disabled).active:focus,
.show > .text .bob-style.dropdown-toggle:focus,
.accordion .bob-style:not(:disabled):not(.disabled):active:focus,
.accordion .bob-style:not(:disabled):not(.disabled).active:focus,
.show > .accordion .bob-style.dropdown-toggle:focus,
.button-block .bob-style:not(:disabled):not(.disabled):active:focus,
.button-block .bob-style:not(:disabled):not(.disabled).active:focus,
.show > .button-block .bob-style.dropdown-toggle:focus,
.rezept-form .bob-style:not(:disabled):not(.disabled):active:focus,
.rezept-form .bob-style:not(:disabled):not(.disabled).active:focus,
.show > .rezept-form .bob-style.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}

#bob-btn-6.bob-style {
  color: #fff;
  background-color: #0A2538;
  border-color: #0A2538;
}
#bob-btn-6.bob-style:hover {
  color: #fff;
  background-color: #0A2538;
  border-color: #0A2538;
}
#bob-btn-6.bob-style:focus, #bob-btn-6.bob-style.focus {
  color: #fff;
  background-color: #0A2538;
  border-color: #0A2538;
  box-shadow: 0 0 0 0.2rem rgba(47, 70, 86, 0.5);
}
#bob-btn-6.bob-style.disabled, #bob-btn-6.bob-style:disabled {
  color: #fff;
  background-color: #0A2538;
  border-color: #0A2538;
}
#bob-btn-6.bob-style:not(:disabled):not(.disabled):active, #bob-btn-6.bob-style:not(:disabled):not(.disabled).active, .show > #bob-btn-6.bob-style.dropdown-toggle {
  color: #fff;
  background-color: #02080d;
  border-color: #000102;
}
#bob-btn-6.bob-style:not(:disabled):not(.disabled):active:focus, #bob-btn-6.bob-style:not(:disabled):not(.disabled).active:focus, .show > #bob-btn-6.bob-style.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 70, 86, 0.5);
}

.page_2 .bob-style,
.bob-frame .bob-style {
  color: #212529;
  background-color: #EDF0F2;
  border-color: rgb(145, 145, 145);
  padding: 8px 20px;
  border: solid 1px #333;
}
.page_2 .bob-style:hover,
.bob-frame .bob-style:hover {
  color: #212529;
  background-color: #e3e3e3;
  border-color: #333;
}
.page_2 .bob-style:focus, .page_2 .bob-style.focus,
.bob-frame .bob-style:focus,
.bob-frame .bob-style.focus {
  color: #212529;
  background-color: #e3e3e3;
  border-color: #333;
  box-shadow: 0 0 0 0.2rem rgba(128, 129, 129, 0.5);
}
.page_2 .bob-style.disabled, .page_2 .bob-style:disabled,
.bob-frame .bob-style.disabled,
.bob-frame .bob-style:disabled {
  color: #212529;
  background-color: #EDF0F2;
  border-color: rgb(145, 145, 145);
}
.page_2 .bob-style:not(:disabled):not(.disabled):active, .page_2 .bob-style:not(:disabled):not(.disabled).active, .show > .page_2 .bob-style.dropdown-toggle,
.bob-frame .bob-style:not(:disabled):not(.disabled):active,
.bob-frame .bob-style:not(:disabled):not(.disabled).active,
.show > .bob-frame .bob-style.dropdown-toggle {
  color: #212529;
  background-color: #cfd7dd;
  border-color: #717171;
}
.page_2 .bob-style:not(:disabled):not(.disabled):active:focus, .page_2 .bob-style:not(:disabled):not(.disabled).active:focus, .show > .page_2 .bob-style.dropdown-toggle:focus,
.bob-frame .bob-style:not(:disabled):not(.disabled):active:focus,
.bob-frame .bob-style:not(:disabled):not(.disabled).active:focus,
.show > .bob-frame .bob-style.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 129, 129, 0.5);
}

.text-image .bob-style,
.bob-style.light-variant {
  color: #212529;
  background-color: #fff;
  border-color: #0A2538;
  padding: 8px 20px;
  border: solid 1px #0A2538;
  text-decoration: none;
}
.text-image .bob-style:hover,
.bob-style.light-variant:hover {
  color: #212529;
  background-color: #fff;
  border-color: #0A2538;
}
.text-image .bob-style:focus, .text-image .bob-style.focus,
.bob-style.light-variant:focus,
.bob-style.light-variant.focus {
  color: #212529;
  background-color: #fff;
  border-color: #0A2538;
  box-shadow: 0 0 0 0.2rem rgba(13, 37, 54, 0.5);
}
.text-image .bob-style.disabled, .text-image .bob-style:disabled,
.bob-style.light-variant.disabled,
.bob-style.light-variant:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #0A2538;
}
.text-image .bob-style:not(:disabled):not(.disabled):active, .text-image .bob-style:not(:disabled):not(.disabled).active, .show > .text-image .bob-style.dropdown-toggle,
.bob-style.light-variant:not(:disabled):not(.disabled):active,
.bob-style.light-variant:not(:disabled):not(.disabled).active,
.show > .bob-style.light-variant.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #000102;
}
.text-image .bob-style:not(:disabled):not(.disabled):active:focus, .text-image .bob-style:not(:disabled):not(.disabled).active:focus, .show > .text-image .bob-style.dropdown-toggle:focus,
.bob-style.light-variant:not(:disabled):not(.disabled):active:focus,
.bob-style.light-variant:not(:disabled):not(.disabled).active:focus,
.show > .bob-style.light-variant.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 37, 54, 0.5);
}

.text-image .bob-style {
  margin: 3px 20px 3px auto;
}

.bob-style.light-variant {
  display: block;
  margin: 10px auto 3px auto;
}

.jumbotron .bob-style,
.hero .bob-style {
  padding: 20px 30px;
  line-height: 24px;
  margin-top: 5px;
  margin-bottom: 30px;
  width: 85%;
}

.portal .bob-style {
  padding: 6px 20px;
  width: 100%;
  border: solid 2px #0A2538;
}
.portal .bob-style:hover, .portal .bob-style:active, .portal .bob-style:focus {
  border: solid 2px #0A2538;
}

.rezept-form .bob-style {
  display: block;
  margin: 40px 0px 0px auto;
  padding: 6px 20px;
  width: 100%;
  max-width: 280px;
  border: solid 2px #0A2538;
}
.rezept-form .bob-style:hover, .rezept-form .bob-style:active, .rezept-form .bob-style:focus {
  border: solid 2px #0A2538;
}

.rezept-form .bob-style {
  display: block;
  margin: 40px 0px 0px auto;
  padding: 6px 20px;
  width: 100%;
  max-width: 280px;
  border: solid 2px #0A2538;
}
.rezept-form .bob-style:hover, .rezept-form .bob-style:active, .rezept-form .bob-style:focus {
  border: solid 2px #0A2538;
}

.contact .bob-style {
  padding: 8px 20px;
  margin: 20px auto 0 auto;
  width: 100%;
  max-width: 100%;
  line-height: 1.18;
}

.passage .bob-style,
.text .bob-style,
.button-block .bob-style {
  padding: 6px 40px;
}

.webinar-section .bob-style {
  padding: 5px 35px;
  color: #0A2538;
}

.subscription .bob-style {
  color: #0A2538;
  background-color: #fff;
  max-width: 100%;
  width: 100%;
  padding: 12px;
}

.devices .bob-style {
  padding: 6px 20px;
  width: 100%;
  border: solid 2px #0A2538;
  background-color: #fff;
  color: #0A2538;
  max-width: 400px;
}

.subscription-box .bob-style {
  color: #fff;
  background-color: #0A2538;
  border-color: rgb(145, 145, 145);
  color: #fff;
  font-weight: 500;
  width: 100%;
  max-width: 400px;
  padding: 15px 20px;
}
.subscription-box .bob-style:hover {
  color: #fff;
  background-color: #0A2538;
  border-color: #fff;
}
.subscription-box .bob-style:focus, .subscription-box .bob-style.focus {
  color: #fff;
  background-color: #0A2538;
  border-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}
.subscription-box .bob-style.disabled, .subscription-box .bob-style:disabled {
  color: #fff;
  background-color: #0A2538;
  border-color: rgb(145, 145, 145);
}
.subscription-box .bob-style:not(:disabled):not(.disabled):active, .subscription-box .bob-style:not(:disabled):not(.disabled).active, .show > .subscription-box .bob-style.dropdown-toggle {
  color: #fff;
  background-color: #02080d;
  border-color: #717171;
}
.subscription-box .bob-style:not(:disabled):not(.disabled):active:focus, .subscription-box .bob-style:not(:disabled):not(.disabled).active:focus, .show > .subscription-box .bob-style.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}
.subscription-box .bob-style:hover, .subscription-box .bob-style:active, .subscription-box .bob-style:focus {
  color: #fff;
}
.subscription-box .bob-style span {
  font-weight: 600;
  color: #b9e2f2;
}

.logo-steps .bob-style {
  color: #fff;
  background-color: #0A2538;
  border-color: rgb(145, 145, 145);
  padding: 15px 40px;
}
.logo-steps .bob-style:hover {
  color: #fff;
  background-color: #0A2538;
  border-color: #fff;
}
.logo-steps .bob-style:focus, .logo-steps .bob-style.focus {
  color: #fff;
  background-color: #0A2538;
  border-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}
.logo-steps .bob-style.disabled, .logo-steps .bob-style:disabled {
  color: #fff;
  background-color: #0A2538;
  border-color: rgb(145, 145, 145);
}
.logo-steps .bob-style:not(:disabled):not(.disabled):active, .logo-steps .bob-style:not(:disabled):not(.disabled).active, .show > .logo-steps .bob-style.dropdown-toggle {
  color: #fff;
  background-color: #02080d;
  border-color: #717171;
}
.logo-steps .bob-style:not(:disabled):not(.disabled):active:focus, .logo-steps .bob-style:not(:disabled):not(.disabled).active:focus, .show > .logo-steps .bob-style.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}

.project-optimune .hero .bob-style {
  width: 100%;
  max-width: 400px;
}
.project-optimune .portal .bob-style {
  color: #fff;
  background-color: #0A2538;
  border-color: rgb(145, 145, 145);
}
.project-optimune .portal .bob-style:hover {
  color: #fff;
  background-color: #0A2538;
  border-color: #fff;
}
.project-optimune .portal .bob-style:focus, .project-optimune .portal .bob-style.focus {
  color: #fff;
  background-color: #0A2538;
  border-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}
.project-optimune .portal .bob-style.disabled, .project-optimune .portal .bob-style:disabled {
  color: #fff;
  background-color: #0A2538;
  border-color: rgb(145, 145, 145);
}
.project-optimune .portal .bob-style:not(:disabled):not(.disabled):active, .project-optimune .portal .bob-style:not(:disabled):not(.disabled).active, .show > .project-optimune .portal .bob-style.dropdown-toggle {
  color: #fff;
  background-color: #02080d;
  border-color: #717171;
}
.project-optimune .portal .bob-style:not(:disabled):not(.disabled):active:focus, .project-optimune .portal .bob-style:not(:disabled):not(.disabled).active:focus, .show > .project-optimune .portal .bob-style.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 162, 162, 0.5);
}
.project-optimune .portal .style-2 .bob-style {
  padding: 6px 20px;
  width: 100%;
  border: solid 2px #0A2538;
  border-radius: 40px;
  background-color: #fff;
  color: #0A2538;
}
.project-optimune .portal .style-2 .bob-style:hover, .project-optimune .portal .style-2 .bob-style:active, .project-optimune .portal .style-2 .bob-style:focus {
  border: solid 2px #0A2538;
}
.project-optimune .form-checksum .bob-style {
  padding: 6px 20px;
  width: 100%;
  border: solid 2px #0A2538;
  background-color: #fff;
  color: #0A2538;
}
.project-optimune .form-checksum .bob-style:hover, .project-optimune .form-checksum .bob-style:active, .project-optimune .form-checksum .bob-style:focus {
  border: solid 2px #0A2538;
  background-color: #fff;
  color: #0A2538;
}

@media (min-width: 992px) {
  .bob-style {
    font-size: 20px;
  }
}
/*
 * jQuery FlexSlider v2.7.2
 * https://www.woocommerce.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
@font-face {
  font-family: "flexslider-icon";
  src: url("/fonts/flexslider/flexslider-icon.eot");
  src: url("/fonts/flexslider/flexslider-icon.eot?#iefix") format("embedded-opentype"), url("/fonts/flexslider/flexslider-icon.woff") format("woff"), url("/fonts/flexslider/flexslider-icon.ttf") format("truetype"), url("/fonts/flexslider/flexslider-icon.svg#flexslider-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides img {
  width: 100%;
  display: block;
}

.flexslider .slides:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .flexslider .slides {
  display: block;
}

* html .flexslider .slides {
  height: 1%;
}

.no-js .flexslider .slides > li:first-child {
  display: block;
}

/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0 0 60px;
  background: #fff;
  border: 4px solid #fff;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: "" 0 1px 4px rgba(0, 0, 0, 0.2);
}

.flexslider .slides {
  zoom: 1;
}

.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

@-moz-document url-prefix() {
  .loading .flex-viewport {
    max-height: none;
  }
}
.carousel li {
  margin-right: 5px;
}

.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flex-direction-nav a:before {
  font-family: "flexslider-icon";
  font-size: 40px;
  display: inline-block;
  content: "\f001";
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}

.flex-direction-nav a.flex-next:before {
  content: "\f002";
}

.flex-direction-nav .flex-prev {
  left: -50px;
}

.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right;
}

.flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 10px;
}

.flex-direction-nav .flex-prev:hover {
  opacity: 1;
}

.flex-direction-nav .flex-next {
  opacity: 0.7;
  right: 10px;
}

.flex-direction-nav .flex-next:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}

.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: "\f004";
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: "\f003";
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}

.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0.7;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}
.slider {
  padding-top: 20px;
  margin: 0 auto;
}
.slider .container h3 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  padding-bottom: 20px;
  font-size: 24px;
}
.slider .container span {
  color: #376a85;
  font-weight: 700;
}
.slider .container .flex-control-nav {
  bottom: -90px;
}
.slider .container .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background: none;
  border: 2px solid #376a85;
}
.slider .container .flex-control-paging li a.flex-active {
  background: #376a85;
  border-color: #376a85;
}
.slider .container .flex-direction-nav a {
  height: 60px;
}
.slider .container .flex-direction-nav a:before {
  color: #376a85;
}
.slider .container .flex-direction-nav .flex-prev,
.slider .container .flex-direction-nav .flex-next {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.705);
}
.slider .container .flex-direction-nav .flex-prev {
  padding-left: 17px;
}
.slider .container .flex-direction-nav .flex-next {
  padding-right: 15px;
}
.slider .container .flexslider .slides img {
  max-width: 300px;
  margin: auto;
  padding: 5px 15px;
}
.slider .container .slider-row-line {
  width: 100%;
  height: 2px;
  background-color: #e6e3d9;
  margin: 100px 0px 0px 0px;
}
.slider .container hr {
  width: 95%;
  height: 2px;
  background-color: #376a85;
  margin: 20px auto;
}
.slider .container p {
  padding-left: 20px;
  font-size: 20px;
  padding-right: 20px;
  line-height: 1.5;
}
.slider .container p span {
  font-weight: 700;
  color: #376a85;
}
.slider .container .mobile-wrapper {
  padding: 3px 3px;
  background: linear-gradient(0deg, white 0%, #376a85) repeat, linear-gradient(90deg, #376a85 1px, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0)) no-repeat, linear-gradient(90deg, rgba(255, 255, 255, 0) 5px, #376a85 5px, #376a85 6px, rgba(255, 255, 255, 0) 6px, rgba(255, 255, 255, 0)) no-repeat;
  background-color: white;
  border-radius: 8px;
  margin: auto;
  max-width: 350px;
}
.slider .container .mobile-wrapper .mobile-inner-wrapper {
  background-color: white;
  padding: 30px 10px 10px 10px;
  border-radius: 5px;
}

@media (min-width: 576px) {
  .slider {
    padding-top: 70px;
  }
}
@media (min-width: 768px) {
  .slider .container h3 {
    font-size: 32px;
  }
  .slider .container .flex-direction-nav .flex-prev {
    left: -10%;
  }
  .slider .container .flex-direction-nav .flex-next {
    right: -10%;
  }
}
@media (min-width: 992px) {
  .slider .container {
    overflow: visible;
  }
}
.contact {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Jost", sans-serif;
}
.contact .contact-row-line {
  width: 100%;
  height: 2px;
  background-color: #e6e3d9;
  margin: 30px 0px 0px 0px;
}
.contact h3,
.contact h4,
.contact p {
  line-height: 1.4;
}
.contact h3 {
  color: #0A2538;
  font-weight: 700;
  padding: 70px 0px 40px 0px;
  font-size: 24px;
}
.contact h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.contact p {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}
.contact .card {
  border-radius: 5px;
  padding: 25px 30px;
  width: 100%;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
}
.contact .card span {
  font-weight: 700;
}
.contact .card-left {
  background-color: #b9e2f2;
  color: #FFFFFF;
}
.contact .card-left span {
  color: #376a85;
}
.contact .card-right {
  background-color: #74b6d1;
  color: #0A2538;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.contact .card-right span {
  color: #fff;
}

@media (min-width: 768px) {
  .contact h3 {
    font-size: 32px;
    padding: 70px 0px;
  }
  .contact .card {
    padding: 50px 30px 50px 40px;
    width: 45%;
  }
}
.page_4 .contact .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page_4 .contact .card-left {
  background-color: #FDEDFF;
  color: #0A2538;
}
.page_4 .contact .card-left a {
  font-weight: 700;
  color: #0A2538;
  white-space: nowrap;
}
.page_4 .contact .card-left span {
  color: #376a85;
}
.page_4 .contact .card-left .bob-style {
  color: #fff;
  background-color: #0A2538;
}
.page_4 .contact .card-top-wrapper {
  display: flex;
  margin-bottom: 30px;
}
.page_4 .contact .card-top-wrapper h4 {
  padding-top: 0px;
  align-self: center;
  margin-bottom: 0px;
}
.page_4 .contact .card-top-wrapper .card-icon {
  width: 80px;
  height: auto;
  margin-right: 20px;
}

.passage a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.jumbotron a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.paragraph a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.stripe a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.spots a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.accordion a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.usps a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.box a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.usps-patient a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.bob-frame .bob-content a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.steps a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.cta-box a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.subscription-box a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.logo-steps a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.graph a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link),
.rezept-form a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link) {
  color: #376a85;
  text-decoration: none;
  border-bottom: 1px solid #376a85;
}
.passage a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.jumbotron a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.paragraph a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.stripe a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.spots a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.accordion a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.usps a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.box a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.usps-patient a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.bob-frame .bob-content a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.steps a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.cta-box a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.subscription-box a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.logo-steps a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.graph a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus,
.rezept-form a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus {
  outline: 0 none;
  color: black;
  border-bottom: 2px solid #284d61;
  background-color: #87b5ce;
}

.contact.light a {
  text-decoration: none;
}
.contact.light a p {
  color: #0A2538;
}
.contact.light a .item-img-wrapper,
.contact.light a p {
  transition: 0.2s;
}
.contact.light a:focus, .contact.light a:hover, .contact.light a:active {
  outline: 0 none;
}
.contact.light a:focus p, .contact.light a:hover p, .contact.light a:active p {
  color: #376a85;
}
.contact.light a:focus .item-img-wrapper, .contact.light a:hover .item-img-wrapper, .contact.light a:active .item-img-wrapper {
  background-color: #0A2538;
}

.contact.dark a {
  text-decoration: none;
}
.contact.dark a p {
  color: #0A2538;
}
.contact.dark a .item-img-wrapper,
.contact.dark a p {
  transition: 0.2s;
}
.contact.dark a:focus, .contact.dark a:hover, .contact.dark a:active {
  outline: 0 none;
}
.contact.dark a:focus p, .contact.dark a:hover p, .contact.dark a:active p {
  color: #fff;
}
.contact.dark a:focus .item-img-wrapper, .contact.dark a:hover .item-img-wrapper, .contact.dark a:active .item-img-wrapper {
  background-color: #b9e2f2;
}

.footer a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link) {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
}
.footer a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link):focus {
  outline: 0 none;
  background-color: #333;
}

.bob-frame.contains-w1-bob-wizard-form1 .content p,
.bob-frame.contains-w1-bob-wizard-form1 .form-group-email p,
.bob-frame.contains-w2-bob-wizard-form2 .content p,
.bob-frame.contains-w2-bob-wizard-form2 .form-group-email p,
.bob-frame.contains-c1-form-contact_pro .content p,
.bob-frame.contains-c1-form-contact_pro .form-group-email p,
.bob-frame.contains-c1-form-contact .content p,
.bob-frame.contains-c1-form-contact .form-group-email p,
.bob-frame.contains-h1-form-help .content p,
.bob-frame.contains-h1-form-help .form-group-email p,
.bob-frame.contains-w3-bob-wizard-form3 .content p,
.bob-frame.contains-w3-bob-wizard-form3 .form-group-email p,
.bob-frame.contains-w4-bob-wizard-form4 .content p,
.bob-frame.contains-w4-bob-wizard-form4 .form-group-email p,
.bob-frame.contains-n1-neuro-form .content p,
.bob-frame.contains-n1-neuro-form .form-group-email p,
.bob-frame.contains-e1-error-frame .content p,
.bob-frame.contains-e1-error-frame .form-group-email p,
.bob-frame.contains-s2-bob-wizard-success2 .content p,
.bob-frame.contains-s2-bob-wizard-success2 .form-group-email p,
.bob-frame.contains-s1-bob-wizard-success1 .content p,
.bob-frame.contains-s1-bob-wizard-success1 .form-group-email p,
.bob-frame.contains-scpf-contact-patient-flyer-success .content p,
.bob-frame.contains-scpf-contact-patient-flyer-success .form-group-email p,
.bob-frame.contains-cpf1-contact-patient-flyer .content p,
.bob-frame.contains-cpf1-contact-patient-flyer .form-group-email p,
.bob-frame.contains-c1-bob-form-contact-hubspot .content p,
.bob-frame.contains-c1-bob-form-contact-hubspot .form-group-email p {
  font-size: 15px;
  font-weight: 300;
}
.bob-frame.contains-w1-bob-wizard-form1 .mini,
.bob-frame.contains-w2-bob-wizard-form2 .mini,
.bob-frame.contains-c1-form-contact_pro .mini,
.bob-frame.contains-c1-form-contact .mini,
.bob-frame.contains-h1-form-help .mini,
.bob-frame.contains-w3-bob-wizard-form3 .mini,
.bob-frame.contains-w4-bob-wizard-form4 .mini,
.bob-frame.contains-n1-neuro-form .mini,
.bob-frame.contains-e1-error-frame .mini,
.bob-frame.contains-s2-bob-wizard-success2 .mini,
.bob-frame.contains-s1-bob-wizard-success1 .mini,
.bob-frame.contains-scpf-contact-patient-flyer-success .mini,
.bob-frame.contains-cpf1-contact-patient-flyer .mini,
.bob-frame.contains-c1-bob-form-contact-hubspot .mini {
  font-size: 16px;
}

.bob-frame.contains-w2-bob-wizard-form2 .custom-control-label {
  font-size: 15px;
  font-weight: 300;
}

.form-group-email label {
  white-space: nowrap;
}

.bob-frame.contains-w1-bob-wizard-form1 legend {
  font-size: 23px;
}

@media (min-width: 576px) {
  .bob-frame.contains-bob-frame-content-passage .passage {
    padding-top: 0;
  }
  .bob-frame.contains-bob-frame-content-passage .passage .container {
    padding: 0;
  }
  .bob-frame.contains-bob-frame-content-passage .bob-window {
    max-width: 750px;
  }
  .bob-frame.contains-w1-bob-wizard-form1 legend {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .bob-frame.contains-w1-bob-wizard-form1 .bob-window,
  .bob-frame.contains-w2-bob-wizard-form2 .bob-window,
  .bob-frame.contains-c1-form-contact_pro .bob-window,
  .bob-frame.contains-c1-form-contact .bob-window,
  .bob-frame.contains-w3-bob-wizard-form3 .bob-window,
  .bob-frame.contains-w4-bob-wizard-form4 .bob-window,
  .bob-frame.contains-h1-form-help .bob-window,
  .bob-frame.contains-n1-neuro-form .bob-window,
  .bob-frame.contains-e1-error-frame .bob-window,
  .bob-frame.contains-s2-bob-wizard-success2 .bob-window,
  .bob-frame.contains-s1-bob-wizard-success1 .bob-window,
  .bob-frame.contains-scpf-contact-patient-flyer-success .bob-window,
  .bob-frame.contains-cpf1-contact-patient-flyer .bob-window,
  .bob-frame.contains-c1-bob-form-contact-hubspot .bob-window {
    width: 600px;
  }
}
.bob-frame.contains-bob-frame-content-passage .bob-window .bob-content p, .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content .passge-frame-header {
  margin: 20px 0;
}

@media (min-width: 768px) {
  .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content p, .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content .passge-frame-header {
    margin: 25px 0;
  }
}
@media (min-width: 992px) {
  .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content p, .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content .passge-frame-header {
    margin: 30px 0;
  }
}
@media (min-width: 1500px) {
  .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content p, .bob-frame.contains-bob-frame-content-passage .bob-window .bob-content .passge-frame-header {
    margin: 35px 0;
  }
}
html,
body {
  height: 100%;
}

.body-content {
  position: relative;
  height: 100%;
}

.btn {
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: normal;
}

.button-block a:hover {
  background-color: #0A2538;
  color: #fff;
}

.bg-gray,
.bg-gray-down-lg {
  background-color: #F7F7F7;
}

@media (min-width: 768px) {
  .navbar-btn-descr {
    font-size: 17px;
    margin-right: 5px;
  }
}
@media (min-width: 992px) {
  .bg-gray {
    border-right: solid thin rgb(213, 213, 213);
    border-left: solid thin rgb(213, 213, 213);
  }
  .passage-last .bg-gray {
    border-top: solid thin rgb(213, 213, 213);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .container.containing-form .bg-gray {
    border-bottom: solid thin rgb(213, 213, 213);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .bg-gray-down-lg {
    background-color: #fff;
  }
}
@media (min-width: 1500px) {
  .container.containing-form .bg-gray {
    margin: 0 auto;
  }
}
.animate-icon object {
  opacity: 0;
}

.animate-icon object.animate-icon-show {
  opacity: 1;
}

img.animate-icon {
  transition: 0.3s;
  opacity: 0;
}

img.animate-icon.animate-icon-show {
  opacity: 1;
}

.container-big {
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
  overflow: hidden;
}

@media (min-width: 576px) {
  .container-big {
    max-width: 540px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .container-big {
    max-width: 780px;
    padding: 0 40px;
  }
}
@media (min-width: 992px) {
  .container-big {
    max-width: 1000px;
    padding: 0;
  }
}
@media (min-width: 1500px) {
  .container-big {
    max-width: 1200px;
  }
}
.section-dark-box {
  color: #494949;
  padding-bottom: 40px;
  background-color: #FDEDFF;
}
.section-dark-box h1, .section-dark-box h2, .section-dark-box h3, .section-dark-box h4, .section-dark-box h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.section-dark-box h1.hyphens, .section-dark-box h2.hyphens, .section-dark-box h3.hyphens, .section-dark-box h4.hyphens, .section-dark-box h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.section-dark-box h1.white, .section-dark-box h2.white, .section-dark-box h3.white, .section-dark-box h4.white, .section-dark-box h5.white, .section-dark-box p.white {
  color: #fff;
}
.section-dark-box h1.app-color, .section-dark-box h2.app-color, .section-dark-box h3.app-color, .section-dark-box h4.app-color, .section-dark-box h5.app-color, .section-dark-box p.app-color {
  color: #376a85;
}
.section-dark-box h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.section-dark-box h2.h1 {
  font-size: 40px;
}
.section-dark-box h3 {
  padding-bottom: 20px;
}
.section-dark-box h3, .section-dark-box h4 {
  padding-top: 20px;
  font-size: 24px;
}
.section-dark-box h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.section-dark-box p, .section-dark-box h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.section-dark-box p {
  font-weight: 300;
}
.section-dark-box p.mini,
.section-dark-box p.mini-sm {
  font-size: 18px;
}
.section-dark-box p.tiny {
  font-size: 14px;
}
.section-dark-box h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .section-dark-box p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .section-dark-box h2 {
    margin-bottom: 40px;
  }
  .section-dark-box h2.h1 {
    font-size: 50px;
  }
  .section-dark-box h2.extra {
    font-size: 38px;
  }
  .section-dark-box p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .section-dark-box h3.h2-lg {
    font-size: 40px;
  }
  .section-dark-box h2 {
    margin-bottom: 40px;
  }
  .section-dark-box h2.h1 {
    font-size: 66px;
  }
  .section-dark-box h2.extra {
    font-size: 40px;
  }
  .section-dark-box p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .section-dark-box {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .section-dark-box {
    padding-bottom: 80px;
  }
}
.section-dark-box hr {
  margin-bottom: 40px;
  width: 100%;
  height: 2px;
  background-color: #FDEDFF;
  border: none;
  margin-top: 0;
}
@media (min-width: 768px) {
  .section-dark-box hr {
    margin-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .section-dark-box hr {
    margin-bottom: 80px;
  }
}

.dark-box {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .dark-box {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .dark-box {
    margin-top: 40px;
  }
}
.dark-box .container-big {
  padding: 10px;
  background-color: #0A2538;
  border-radius: 7px;
}
.dark-box .container-big p {
  color: #fff;
}
.dark-box .container-big h2 span, .dark-box .container-big h3 span, .dark-box .container-big p span {
  color: #b9e2f2;
  font-weight: bold;
}
.dark-box .container-big img {
  display: block;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
}
.dark-box .container-big a {
  color: #376a85;
  text-decoration: underline;
}

@media (min-width: 576px) {
  .dark-box .container-big {
    padding: 30px 55px;
  }
}
@media (min-width: 992px) {
  .dark-box .container-big {
    padding: 30px 50px 30px 10px;
  }
  .dark-box .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    align-items: center;
  }
}
.page_5 .passage {
  padding-bottom: 0px;
}
.page_5 .accordion {
  margin-bottom: 30px;
}
.page_5 .accordion .container .card-header button {
  color: #0A2538;
}
.page_5 .button-block {
  margin-bottom: 60px;
}

@media (min-width: 768px) {
  .page_5 .button-block {
    margin-bottom: 100px;
  }
}
body {
  font-feature-settings: "lnum" 1;
}

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Jost/woff2/jost-v6-latin-300.woff2") format("woff2") url("/fonts/Jost/woff/jost-v6-latin-300.woff") format("woff");
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Jost/woff2/jost-v6-latin-regular.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v6-latin-regular.woff") format("woff");
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/Jost/woff2/jost-v6-latin-500.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v6-latin-500.woff") format("woff");
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/Jost/woff2/jost-v6-latin-600.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v6-latin-600.woff") format("woff");
}
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Jost/woff2/jost-v6-latin-700.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v6-latin-700.woff") format("woff");
}
@font-face {
  font-family: "Jost";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/Jost/woff2/jost-v6-latin-italic.woff2") format("woff2"), url("/fonts/Jost/woff/jost-v6-latin-italic.woff") format("woff");
}
.portal {
  margin-bottom: 80px;
}
.portal .arrow {
  margin: 0 auto 60px auto;
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-top: 60px solid #b9e2f2;
  border-bottom: 0 none;
  width: 0;
  height: 0;
}
.portal .portal-row {
  font-family: "Jost", sans-serif;
  color: #494949;
  font-size: 20px;
  line-height: 1.45;
  font-weight: 500;
}
.portal .portal-row span {
  color: #b9e2f2;
  font-weight: 700;
}
.portal .portal-row .portal-heading {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 32px;
  line-height: 1.13;
  font-weight: 700;
  padding-bottom: 30px;
  color: #0A2538;
}
.portal .portal-row h2 {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #376a85;
  margin-bottom: 30px;
}
.portal .portal-row .portal-box {
  background-color: #376a85;
  color: #fff;
  padding: 20px;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 25px;
  line-height: 1.3;
}
.portal .portal-row .portal-box img {
  width: 20%;
  max-width: 120px;
  height: fit-content;
  margin: auto;
  display: block;
}
.portal .portal-row .portal-box p {
  margin: 15px;
}
.portal .portal-row .portal-box p a {
  color: #2c464d;
}
.portal .portal-row .portal-row-txt {
  margin-bottom: 10px;
}
.portal .portal-row .portal-row-txt h2 {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #376a85;
  margin-bottom: 30px;
}
.portal .portal-row .portal-row-action .cta-item span {
  color: #b9e2f2;
}
.portal .portal-row .portal-row-action .form-control {
  margin-top: 3px;
  border-radius: 30px;
  padding: 6px 20px;
  background-color: #fff;
  border-color: #9A9A9A;
}
.portal .portal-row .portal-row-action .form-control placeholder {
  color: #B6BBBC;
}
.portal .portal-row .portal-row-action ::placeholder {
  color: #B6BBBC;
  font-style: normal;
}
.portal .portal-row .portal-row-line {
  opacity: 0;
  width: 100%;
  height: 2px;
  background-color: #e6e3d9;
  margin: 30px 0px;
}

@media (min-width: 576px) {
  .portal .portal-row .row {
    padding: 10px 0;
  }
  .portal .portal-row .portal-box img {
    margin: 0 20px 0 20px;
  }
  .portal .portal-row .portal-row-line {
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .portal .portal-row .portal-row-txt {
    margin-bottom: 0px;
  }
}
@media (min-width: 992px) {
  .portal .portal-row .portal-box img {
    width: 10%;
  }
}
.page_3 .portal {
  font-size: 16px;
  margin: 50px 20px 20px 20px;
}
.page_3 .portal .portal-row {
  padding-top: 40px;
}
.page_3 .portal .portal-row-txt {
  font-weight: 300;
}
.page_3 .portal h1 {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #376a85;
  margin-bottom: 20px;
}
@media (min-width: 1500px) {
  .page_3 .portal {
    margin: 70px 20px 30px 20px;
    font-size: 19px;
  }
  .page_3 .portal h1 {
    font-size: 26px;
  }
}

.usps {
  background-color: #b9e2f2;
  padding-top: 60px;
}
.usps .title {
  color: #fff;
}
.usps .usp-cards {
  min-height: 160px;
  margin-bottom: 20px;
}
.usps .usp-cards .usp-info {
  min-height: 110px;
  margin-bottom: 20px;
}
.usps .usp-cards p {
  color: #0a2538;
  line-height: 1.1;
  margin-top: 10px !important;
  font-size: 18px;
  font-weight: 400;
}
.usps .usp-cards .usp-heading {
  line-height: 1.1;
}
.usps .usp-cards .usp-heading p {
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 10px;
  margin-bottom: 0;
  padding-left: 20px;
}
.usps .usp-cards .usp-heading p span {
  color: #fff;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.usps .usp-cards .usp-heading .usp-details-mobile p {
  font-size: 18px;
  font-weight: 600;
}
.usps .usp-cards img {
  display: block;
  max-height: 110px;
}
.usps .usp-cards .usp-details-desktop {
  display: none;
}
.usps span {
  color: #0a2538;
  font-weight: 600;
}

.page_5.project-priovi .usps,
.page_5.project-levidex .usps,
.page_5.project-deprexis .usps {
  background-color: #b9e2f2;
}
.page_5.project-priovi .usps h3,
.page_5.project-levidex .usps h3,
.page_5.project-deprexis .usps h3 {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 700;
  color: #0A2538;
}
.page_5.project-priovi .usps .usp-cards p,
.page_5.project-levidex .usps .usp-cards p,
.page_5.project-deprexis .usps .usp-cards p {
  color: #000;
}
.page_5.project-priovi .usps .usp-cards .usp-details-desktop,
.page_5.project-levidex .usps .usp-cards .usp-details-desktop,
.page_5.project-deprexis .usps .usp-cards .usp-details-desktop {
  padding-left: 30px;
  padding-top: 10px;
}

.project-liebria .usps .usp-details-mobile p span {
  color: #0a2538;
}

@media (min-width: 768px) {
  .usps {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .usps .container {
    padding: 0px 40px;
  }
}
@media (min-width: 992px) {
  .usps .usp-cards {
    min-height: 220px;
    margin-bottom: 0px;
  }
  .usps .usp-cards .usp-details-desktop {
    display: block;
    width: 95%;
  }
  .usps .usp-cards .usp-details-mobile {
    display: none;
  }
  .usps .usp-cards .usp-heading p {
    padding-left: 10px;
  }
}
.pro_usps {
  padding-top: 20px;
  padding-bottom: 20px;
  font-style: "Jost", sans-serif;
  background-color: #FDEDFF;
}
@media (min-width: 768px) {
  .pro_usps {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .pro_usps {
    padding-top: 40px;
  }
}
@media (min-width: 768px) {
  .pro_usps {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .pro_usps {
    padding-bottom: 40px;
  }
}
.pro_usps h2 {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 24px;
  color: #0A2538;
  padding-top: 20px;
}
.pro_usps h2 span {
  color: #376a85;
}
.pro_usps .usp-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.pro_usps p {
  padding: 0px 0px 0 28px;
  line-height: 1.2;
  margin-bottom: 0px;
}
.pro_usps p span {
  font-weight: 600;
}

@media (min-width: 768px) {
  h2 {
    padding-top: 0px;
  }
}
.spot {
  position: relative;
}
.spot .h4 {
  font-size: 20px;
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
}
.spot p {
  margin-bottom: 5px;
}

.spots {
  background: radial-gradient(circle at 40% 50%, #f7f7f7, #f3f3f3, #e9eaea, #dfe1e2, #dbddde);
  margin: 30px 0 0 0;
  padding: 30px 0;
}

.page_2 .spots:not(.spots-hero) {
  padding: 50px 0 30px 0;
  width: 100%;
  background: radial-gradient(circle at 40% 50%, #f7f7f7, #f3f3f3, #e9eaea, #dfe1e2, #dbddde);
}
.page_2 .spots:not(.spots-hero) .container {
  overflow: visible;
}
.page_2 .spots:not(.spots-hero) .bob-style {
  margin: 7px 0;
}
.page_2 .spots-hero {
  margin-top: 50px;
}
.page_2 .spots-hero .h1 {
  font-size: 26px;
  font-weight: 500;
}
.page_2 .spots-hero h1 {
  font-size: 22px;
}
.page_2 .spots-hero p {
  font-weight: 300;
  font-size: 16px;
}
.page_2 .spots-hero .bob-style {
  margin-top: 20px;
}

.horizontal-scroll {
  overflow: auto;
  display: flex;
  scroll-snap-type: x mandatory;
}
.horizontal-scroll .screenshots-item,
.horizontal-scroll .illustration {
  scroll-snap-align: start;
}
.horizontal-scroll .screenshots-item {
  padding: 20px clamp(50px, 50vw - 119px, 6000px);
}
.horizontal-scroll .screenshots-item p {
  min-height: 125px;
}
.horizontal-scroll .illustration {
  padding: 20px clamp(20px, 20vw - 119px, 6000px);
}

@media (min-width: 992px) {
  .screenshots .horizontal-scroll,
  .screenshots .mobile-view-button {
    display: none;
  }
}
@media (min-width: 768px) {
  .illustrations .horizontal-scroll {
    display: none;
  }
}
.info-section {
  padding: 80px 0px;
}
.info-section span {
  color: #376a85;
  font-weight: 700;
}
.info-section .container h2 {
  margin-bottom: 50px;
  color: #0A2538;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3;
}
.info-section .container h3 {
  color: #0A2538;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.3;
}
.info-section .container .info-card p {
  font-size: 18px;
}
.info-section .container p {
  line-height: 1.2;
}
.info-section .container hr {
  margin-bottom: 50px;
  margin-top: 40px;
  border-color: #dfc7e8;
}
.info-section .container .bob-style {
  margin: 0px auto;
  color: #ffF;
  background-color: #0A2538;
  display: block;
}

.footer {
  padding-top: 20px;
  padding-bottom: 40px;
  color: #F4F4F4;
  font-size: 15px;
  background: #0a2538;
}
@media (min-width: 768px) {
  .footer {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .footer {
    padding-top: 40px;
  }
}
@media (min-width: 768px) {
  .footer {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .footer {
    padding-bottom: 80px;
  }
}
.footer span {
  color: #376a85;
  font-weight: 600;
}
.footer hr {
  margin: 70px 0;
  width: 100%;
  height: 2px;
  background-color: hsla(0deg, 0%, 100%, 0.3);
}
.footer .footer-column {
  padding-top: 20px;
  color: #fff;
}
@media (min-width: 768px) {
  .footer .footer-column {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .footer .footer-column {
    padding-top: 40px;
  }
}
.footer .footer-column a,
.footer .footer-column a:hover {
  text-decoration: none;
  color: #fff;
}
.footer .footer-column a {
  opacity: 0.7;
}
.footer .footer-column a:hover,
.footer .footer-column a.active {
  opacity: 1;
}
.footer .footer-column ul {
  list-style: none;
  padding-left: 0;
}
.footer .footer-column ul li {
  position: relative;
  padding-left: 13px;
}
.footer .footer-column ul .bullet {
  display: block;
  position: absolute;
  top: 11px;
  left: 0px;
  background: #fff;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  opacity: 0.5;
}
.footer .footer-logo {
  height: 100px;
}
.footer .footer-logo .gaia-logo {
  margin-top: 16px;
}
.footer .footer-legal {
  font-size: 70%;
  line-height: 1.3;
  margin-bottom: 14px;
}
.footer .footer-legal span {
  color: #fff;
  white-space: nowrap;
  font-weight: 400;
}
.footer .footer-formal-links {
  margin-top: 14px;
}

.project-somnovia .footer span {
  color: #b9e2f2;
}

.footer .footer-links .container {
  overflow: visible;
}

.footer-redirect {
  display: none;
}

.footer .footer-col-mobile h3 {
  font-size: 30px;
}
.footer .footer-col-mobile h3,
.footer .footer-col-mobile p {
  width: 60%;
  margin: 0 auto;
}
.footer .footer-col-mobile p {
  padding-top: 40px;
}
.footer .footer-col-mobile p:before {
  content: "";
  margin-bottom: 50px;
  width: 100%;
  height: 1px;
  background-color: rgb(177, 177, 177);
  display: block;
}
.footer .footer-col-mobile p a {
  opacity: 1;
}

@media (min-width: 576px) {
  .footer .footer-col-mobile h3,
  .footer .footer-col-mobile p {
    width: 100%;
    margin: 0;
  }
  .footer .footer-col-mobile p:before {
    display: none;
  }
  .footer .footer-col-mobile p a {
    opacity: 0.7;
  }
}
@media (min-width: 768px) {
  .footer .footer-col-mobile {
    display: table;
    margin: 0 0 0 auto;
  }
}
.accordion {
  font-family: "Jost", sans-serif;
  color: #494949;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .accordion {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .accordion {
    padding-bottom: 80px;
  }
}
.accordion .container h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 40px;
  color: #0A2538;
}
.accordion .container h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 30px;
  color: #0A2538;
}
.accordion .container .margin-bottom {
  margin-bottom: 40px;
}
.accordion .container hr {
  width: 100%;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.accordion .container .card {
  margin: 0;
}
.accordion .container .card-body {
  padding-left: 0;
  margin-bottom: 40px;
}
.accordion .container .card-body img {
  width: 110px;
}
.accordion .container .card-body p {
  font-size: 16px;
  color: #333333;
}
.accordion .container .card-body li {
  font-size: 16px;
}
.accordion .container .card-header {
  background-color: #fff;
  border-bottom: 0;
  padding: 0;
}
.accordion .container .card-header button {
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
  color: #494949;
  padding: 6px 20px 6px 0;
  line-height: 20px;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
}
.accordion .container .card-header button:focus, .accordion .container .card-header button:active {
  box-shadow: none;
}
.accordion .container .card-header button:focus .arrow, .accordion .container .card-header button:active .arrow {
  background-color: #eaeaea;
}
.accordion .container .card-header button .arrow {
  padding: 5px 5px 4px 5px;
  border-radius: 50%;
  margin-right: 20px;
}
.accordion .container .card-header button .arrow .glyphicons-plus {
  display: block;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}
.accordion .container .card-header button .arrow .glyphicons-plus svg path {
  stroke: #0A2538;
}
.accordion .container .card-header button[aria-expanded=true] .arrow .glyphicons-plus {
  transform: rotate(45deg);
}
.accordion .container .passage {
  color: #494949;
  padding: 0px;
  margin: 0 auto;
}
.accordion .container .passage h3 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 18px;
}
.accordion .container .passage p {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5;
}
.accordion .container .passage span {
  color: #376a85;
  font-weight: 700;
}
.accordion .container .passage .passage-info-subtext p {
  font-size: 12.5px;
  line-height: 17px;
  font-weight: 400;
}
.accordion .container .passage .passage-row-line {
  width: 100%;
  height: 2px;
  background-color: #e6e3d9;
  margin-bottom: 40px;
}

@media (min-width: 576px) {
  .accordion .container .passage .container {
    padding: 0px;
  }
}
.box {
  color: #494949;
  margin-top: 20px;
  margin-bottom: 40px;
}
.box h1, .box h2, .box h3, .box h4, .box h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.box h1.hyphens, .box h2.hyphens, .box h3.hyphens, .box h4.hyphens, .box h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.box h1.white, .box h2.white, .box h3.white, .box h4.white, .box h5.white, .box p.white {
  color: #fff;
}
.box h1.app-color, .box h2.app-color, .box h3.app-color, .box h4.app-color, .box h5.app-color, .box p.app-color {
  color: #376a85;
}
.box h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.box h2.h1 {
  font-size: 40px;
}
.box h3 {
  padding-bottom: 20px;
}
.box h3, .box h4 {
  padding-top: 20px;
  font-size: 24px;
}
.box h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.box p, .box h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.box p {
  font-weight: 300;
}
.box p.mini,
.box p.mini-sm {
  font-size: 18px;
}
.box p.tiny {
  font-size: 14px;
}
.box h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .box p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .box h2 {
    margin-bottom: 40px;
  }
  .box h2.h1 {
    font-size: 50px;
  }
  .box h2.extra {
    font-size: 38px;
  }
  .box p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .box h3.h2-lg {
    font-size: 40px;
  }
  .box h2 {
    margin-bottom: 40px;
  }
  .box h2.h1 {
    font-size: 66px;
  }
  .box h2.extra {
    font-size: 40px;
  }
  .box p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .box {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .box {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  .box {
    margin-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .box {
    margin-bottom: 80px;
  }
}
.box .box-inner {
  background-color: #dbf0f8;
  padding: 40px;
  border-radius: 5px;
}
.box .box-inner-content {
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: #494949;
}
.box .box-inner-content a {
  font-weight: 700;
}
.box img {
  width: 80%;
  max-width: 220px;
  display: block;
  margin: 0 auto 30px auto;
}

@media (min-width: 768px) {
  .box .box-inner-content {
    margin: 5%;
  }
  .box img {
    margin: 0 auto;
  }
}
@media (min-width: 1500px) {
  .box .box-inner-content p {
    font-size: 24px;
  }
}
.subscription {
  color: #494949;
  padding-top: 40px;
  padding-bottom: 40px;
}
.subscription h1, .subscription h2, .subscription h3, .subscription h4, .subscription h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.subscription h1.hyphens, .subscription h2.hyphens, .subscription h3.hyphens, .subscription h4.hyphens, .subscription h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.subscription h1.white, .subscription h2.white, .subscription h3.white, .subscription h4.white, .subscription h5.white, .subscription p.white {
  color: #fff;
}
.subscription h1.app-color, .subscription h2.app-color, .subscription h3.app-color, .subscription h4.app-color, .subscription h5.app-color, .subscription p.app-color {
  color: #376a85;
}
.subscription h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.subscription h2.h1 {
  font-size: 40px;
}
.subscription h3 {
  padding-bottom: 20px;
}
.subscription h3, .subscription h4 {
  padding-top: 20px;
  font-size: 24px;
}
.subscription h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.subscription p, .subscription h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.subscription p {
  font-weight: 300;
}
.subscription p.mini,
.subscription p.mini-sm {
  font-size: 18px;
}
.subscription p.tiny {
  font-size: 14px;
}
.subscription h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .subscription p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .subscription h2 {
    margin-bottom: 40px;
  }
  .subscription h2.h1 {
    font-size: 50px;
  }
  .subscription h2.extra {
    font-size: 38px;
  }
  .subscription p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .subscription h3.h2-lg {
    font-size: 40px;
  }
  .subscription h2 {
    margin-bottom: 40px;
  }
  .subscription h2.h1 {
    font-size: 66px;
  }
  .subscription h2.extra {
    font-size: 40px;
  }
  .subscription p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .subscription {
    padding-top: 60px;
  }
}
@media (min-width: 992px) {
  .subscription {
    padding-top: 80px;
  }
}
@media (min-width: 768px) {
  .subscription {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .subscription {
    padding-bottom: 80px;
  }
}
.subscription h2 span,
.subscription h3 span {
  color: #376a85;
  font-weight: bold;
}
.subscription p span {
  color: #376a85;
  font-weight: bold;
}
.subscription .card {
  border-radius: 5px;
  padding: 25px 10px;
  width: 90%;
  margin: 30px auto;
}
.subscription .card-left {
  background-color: #0A2538;
  color: #fff;
}
.subscription .card-left h3 {
  color: #fff;
}
.subscription .card-left a {
  color: #0A2538;
}
.subscription .card-right {
  background-color: #e6bce7;
  color: #0A2538;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.subscription .card-top-wrapper {
  margin-bottom: 30px;
}
.subscription .card-top-wrapper .card-icon {
  width: 80px;
  height: auto;
  margin-right: 10px;
}

@media (min-width: 576px) {
  .subscription .card {
    width: 45%;
    margin: 30px 10px;
  }
  .subscription .card-top-wrapper .card-icon {
    width: 60px;
  }
  .subscription .card-top-wrapper h3 {
    font-size: 17px;
    font-weight: 500;
  }
}
@media (min-width: 768px) {
  .subscription .card-top-wrapper .card-icon {
    width: 80px;
  }
  .subscription .card-top-wrapper h3 {
    font-size: 18px;
    font-weight: 600;
  }
}
@media (min-width: 992px) {
  .subscription .card {
    padding: 50px 30px 50px 40px;
  }
  .subscription .card-top-wrapper .card-icon {
    margin-right: 20px;
  }
  .subscription .card-top-wrapper h3 {
    font-size: 19px;
  }
}
@media (min-width: 1500px) {
  .subscription .card-top-wrapper h3 {
    font-size: 20px;
  }
}
.project-info {
  top: -120px;
  position: relative;
}

.steps {
  color: #494949;
  padding-top: 40px;
  padding-bottom: 40px;
}
.steps h1, .steps h2, .steps h3, .steps h4, .steps h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.steps h1.hyphens, .steps h2.hyphens, .steps h3.hyphens, .steps h4.hyphens, .steps h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.steps h1.white, .steps h2.white, .steps h3.white, .steps h4.white, .steps h5.white, .steps p.white {
  color: #fff;
}
.steps h1.app-color, .steps h2.app-color, .steps h3.app-color, .steps h4.app-color, .steps h5.app-color, .steps p.app-color {
  color: #376a85;
}
.steps h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.steps h2.h1 {
  font-size: 40px;
}
.steps h3 {
  padding-bottom: 20px;
}
.steps h3, .steps h4 {
  padding-top: 20px;
  font-size: 24px;
}
.steps h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.steps p, .steps h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.steps p {
  font-weight: 300;
}
.steps p.mini,
.steps p.mini-sm {
  font-size: 18px;
}
.steps p.tiny {
  font-size: 14px;
}
.steps h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .steps p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .steps h2 {
    margin-bottom: 40px;
  }
  .steps h2.h1 {
    font-size: 50px;
  }
  .steps h2.extra {
    font-size: 38px;
  }
  .steps p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .steps h3.h2-lg {
    font-size: 40px;
  }
  .steps h2 {
    margin-bottom: 40px;
  }
  .steps h2.h1 {
    font-size: 66px;
  }
  .steps h2.extra {
    font-size: 40px;
  }
  .steps p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .steps {
    padding-top: 60px;
  }
}
@media (min-width: 992px) {
  .steps {
    padding-top: 80px;
  }
}
@media (min-width: 768px) {
  .steps {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .steps {
    padding-bottom: 80px;
  }
}
.steps h2 span,
.steps h3 span {
  color: #376a85;
  font-weight: bold;
}
.steps .steps-content {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .steps .steps-content {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .steps .steps-content {
    margin-top: 40px;
  }
}
.steps .steps-content .container {
  background-color: #dbf0f8;
  padding-top: 50px;
  padding-bottom: 50px;
}
.steps .steps-content .container h3 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Jost", sans-serif;
  margin-bottom: 0px;
}
.steps .steps-content .container h3 .heading-highlight {
  color: #376a85;
}
.steps .steps-content .container h3 .heading-subtext {
  color: #959796;
  font-weight: 400;
}
.steps .steps-content .container a {
  font-size: 20px;
}
.steps .steps-content .container p {
  font-size: 20px;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  color: #4A5B5F;
}
.steps .steps-content .container p span {
  color: #376a85;
  font-weight: 700;
}
.steps .steps-content .container p,
.steps .steps-content .container h3 {
  line-height: 1.3;
}
.steps .steps-content .container .info-content {
  min-height: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.steps .steps-content .container .info-content .steps-icons {
  display: none;
  padding-top: 60px;
}
.steps .steps-content .container .steps-icons {
  display: none;
  padding-top: 60px;
}
.steps .steps-content .container object {
  display: block;
}
.steps .steps-content .container .col-4 {
  padding: 0px;
}
.steps .row-line {
  opacity: 0;
  width: 100%;
  height: 2px;
  background-color: #f8f9fa;
  margin: 15px 0;
}
.steps .padding {
  padding: 30px 0;
}

.project-priovi .steps h2 {
  font-size: 44px;
}
.project-priovi .steps h2 span {
  color: #376a85;
}
.project-priovi .steps .steps-content .container img {
  width: 100%;
}
.project-priovi .steps .steps-content .container p {
  color: #376a85;
  font-weight: 600;
}
.project-priovi .steps .steps-content .container p span {
  color: #0A2538;
}
.project-priovi .steps .steps-content .container a span {
  color: #376a85;
}
.project-priovi .steps .steps-content .container a img {
  width: 40px;
  margin: auto;
}
.project-priovi .steps .info-content .btn-dark-theme {
  color: #fff;
  background-color: #0A2538;
}
.project-priovi .steps .info-content .bob-style {
  font-weight: 600;
  padding: 8px 30px;
  border: 2px solid #0A2538;
}
.project-priovi .steps .info-content .bob-style:hover, .project-priovi .steps .info-content .bob-style:active, .project-priovi .steps .info-content .bob-style:focus {
  background-color: #e6bde8;
}

svg.chevron-down path {
  stroke: #376a85;
}

@media only screen and (min-width: 370px) {
  .steps .steps-content .container .info-content {
    min-height: 195px;
  }
}
@media (min-width: 576px) {
  .steps .row-line {
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .steps .steps-content .container h3,
  .steps .steps-content .container p {
    font-size: 24px;
    line-height: 1.2;
  }
  .steps .steps-content .container p a {
    font-size: 24px;
  }
  .steps .steps-content .container .info-content {
    min-height: 240px;
  }
}
@media (min-width: 992px) {
  .steps .steps-content .container .info-content .steps-icons {
    display: block;
  }
  .steps .padding {
    padding: 0;
  }
}
@media (min-width: 1500px) {
  .steps .steps-content .container .info-content {
    height: 290px;
  }
}
.illustrations {
  color: #494949;
  padding-bottom: 40px;
  background-color: #FDEDFF;
}
.illustrations h1, .illustrations h2, .illustrations h3, .illustrations h4, .illustrations h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.illustrations h1.hyphens, .illustrations h2.hyphens, .illustrations h3.hyphens, .illustrations h4.hyphens, .illustrations h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.illustrations h1.white, .illustrations h2.white, .illustrations h3.white, .illustrations h4.white, .illustrations h5.white, .illustrations p.white {
  color: #fff;
}
.illustrations h1.app-color, .illustrations h2.app-color, .illustrations h3.app-color, .illustrations h4.app-color, .illustrations h5.app-color, .illustrations p.app-color {
  color: #376a85;
}
.illustrations h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.illustrations h2.h1 {
  font-size: 40px;
}
.illustrations h3 {
  padding-bottom: 20px;
}
.illustrations h3, .illustrations h4 {
  padding-top: 20px;
  font-size: 24px;
}
.illustrations h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.illustrations p, .illustrations h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.illustrations p {
  font-weight: 300;
}
.illustrations p.mini,
.illustrations p.mini-sm {
  font-size: 18px;
}
.illustrations p.tiny {
  font-size: 14px;
}
.illustrations h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .illustrations p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .illustrations h2 {
    margin-bottom: 40px;
  }
  .illustrations h2.h1 {
    font-size: 50px;
  }
  .illustrations h2.extra {
    font-size: 38px;
  }
  .illustrations p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .illustrations h3.h2-lg {
    font-size: 40px;
  }
  .illustrations h2 {
    margin-bottom: 40px;
  }
  .illustrations h2.h1 {
    font-size: 66px;
  }
  .illustrations h2.extra {
    font-size: 40px;
  }
  .illustrations p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .illustrations {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .illustrations {
    padding-bottom: 80px;
  }
}
.illustrations h2 span,
.illustrations h3 span {
  color: #376a85;
  font-weight: bold;
}
.illustrations p span {
  color: #376a85;
  font-weight: bold;
}
.illustrations img {
  width: 200px;
}
.illustrations .desktop-view {
  display: none;
}
.illustrations .desktop-view img {
  margin: 20px 5px 30px 5px;
  width: 49%;
}
.illustrations .cta-box {
  color: #fff;
  margin: 30px 0;
}
.illustrations .cta-box .cta-box-container {
  padding: 40px 40px 28px 40px;
  background-color: #0A2538;
  border-radius: 5px;
}
.illustrations .cta-box p span {
  font-weight: bold;
  color: #64C3D5;
}

@media (min-width: 768px) {
  .illustrations .desktop-view img {
    width: 24%;
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .illustrations .desktop-view img {
    width: 23%;
    margin: 20px 10px 30px 10px;
  }
}
.graph {
  color: #494949;
  padding-bottom: 40px;
  background-color: #FDEDFF;
}
.graph h1, .graph h2, .graph h3, .graph h4, .graph h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.graph h1.hyphens, .graph h2.hyphens, .graph h3.hyphens, .graph h4.hyphens, .graph h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.graph h1.white, .graph h2.white, .graph h3.white, .graph h4.white, .graph h5.white, .graph p.white {
  color: #fff;
}
.graph h1.app-color, .graph h2.app-color, .graph h3.app-color, .graph h4.app-color, .graph h5.app-color, .graph p.app-color {
  color: #376a85;
}
.graph h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.graph h2.h1 {
  font-size: 40px;
}
.graph h3 {
  padding-bottom: 20px;
}
.graph h3, .graph h4 {
  padding-top: 20px;
  font-size: 24px;
}
.graph h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.graph p, .graph h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.graph p {
  font-weight: 300;
}
.graph p.mini,
.graph p.mini-sm {
  font-size: 18px;
}
.graph p.tiny {
  font-size: 14px;
}
.graph h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .graph p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .graph h2 {
    margin-bottom: 40px;
  }
  .graph h2.h1 {
    font-size: 50px;
  }
  .graph h2.extra {
    font-size: 38px;
  }
  .graph p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .graph h3.h2-lg {
    font-size: 40px;
  }
  .graph h2 {
    margin-bottom: 40px;
  }
  .graph h2.h1 {
    font-size: 66px;
  }
  .graph h2.extra {
    font-size: 40px;
  }
  .graph p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .graph {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .graph {
    padding-bottom: 80px;
  }
}
.graph h2 span, .graph h3 span, .graph p span {
  color: #376a85;
  font-weight: bold;
}
.graph img {
  width: 90%;
}
.graph .solo-img {
  width: 90%;
}
.graph .logo-box img {
  margin-bottom: 20px;
}

.project-somnovia .graph .graph-box {
  padding-top: 40px;
}

@media (min-width: 375px) {
  .graph img {
    width: 70%;
  }
}
@media (min-width: 576px) {
  .graph img {
    width: auto;
  }
}
.devices {
  color: #494949;
  background-color: #FDEDFF;
}
.devices h1, .devices h2, .devices h3, .devices h4, .devices h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.devices h1.hyphens, .devices h2.hyphens, .devices h3.hyphens, .devices h4.hyphens, .devices h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.devices h1.white, .devices h2.white, .devices h3.white, .devices h4.white, .devices h5.white, .devices p.white {
  color: #fff;
}
.devices h1.app-color, .devices h2.app-color, .devices h3.app-color, .devices h4.app-color, .devices h5.app-color, .devices p.app-color {
  color: #376a85;
}
.devices h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.devices h2.h1 {
  font-size: 40px;
}
.devices h3 {
  padding-bottom: 20px;
}
.devices h3, .devices h4 {
  padding-top: 20px;
  font-size: 24px;
}
.devices h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.devices p, .devices h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.devices p {
  font-weight: 300;
}
.devices p.mini,
.devices p.mini-sm {
  font-size: 18px;
}
.devices p.tiny {
  font-size: 14px;
}
.devices h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .devices p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .devices h2 {
    margin-bottom: 40px;
  }
  .devices h2.h1 {
    font-size: 50px;
  }
  .devices h2.extra {
    font-size: 38px;
  }
  .devices p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .devices h3.h2-lg {
    font-size: 40px;
  }
  .devices h2 {
    margin-bottom: 40px;
  }
  .devices h2.h1 {
    font-size: 66px;
  }
  .devices h2.extra {
    font-size: 40px;
  }
  .devices p.extra {
    font-size: 30px;
  }
}
.devices h2 span,
.devices h3 span {
  color: #376a85;
  font-weight: bold;
}
.devices p {
  margin-bottom: 0;
}
.devices p span {
  color: #376a85;
  font-weight: bold;
}
.devices object {
  margin: 0 15px;
}
.devices .animate-icon {
  flex-shrink: 0;
  width: 80%;
  max-width: 390px;
}
.devices .reverse-row {
  flex-direction: row-reverse;
}
.devices .reverse-row h2 {
  margin-left: 0;
  margin-right: 5%;
  word-break: break-word;
}
.devices .device-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
}
.devices .device-button span {
  color: #376a85;
  font-weight: bold;
}
.devices .row-line {
  background-color: #fff;
  height: 2px;
  margin-top: 60px;
  width: 100%;
}

.page_1 .devices {
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .page_1 .devices {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .page_1 .devices {
    padding-bottom: 80px;
  }
}

@media (min-width: 576px) {
  .devices h2 {
    margin-left: 48px;
  }
  .devices .animate-icon {
    width: 30%;
  }
}
@media (min-width: 768px) {
  .devices .reverse-row h2.h1 {
    font-size: 44px;
  }
}
@media (min-width: 992px) {
  .devices .reverse-row h2.h1 {
    font-size: 54px;
  }
}
.text {
  color: #494949;
  padding-bottom: 40px;
}
.text h1, .text h2, .text h3, .text h4, .text h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.text h1.hyphens, .text h2.hyphens, .text h3.hyphens, .text h4.hyphens, .text h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.text h1.white, .text h2.white, .text h3.white, .text h4.white, .text h5.white, .text p.white {
  color: #fff;
}
.text h1.app-color, .text h2.app-color, .text h3.app-color, .text h4.app-color, .text h5.app-color, .text p.app-color {
  color: #376a85;
}
.text h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.text h2.h1 {
  font-size: 40px;
}
.text h3 {
  padding-bottom: 20px;
}
.text h3, .text h4 {
  padding-top: 20px;
  font-size: 24px;
}
.text h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.text p, .text h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.text p {
  font-weight: 300;
}
.text p.mini,
.text p.mini-sm {
  font-size: 18px;
}
.text p.tiny {
  font-size: 14px;
}
.text h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .text p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .text h2 {
    margin-bottom: 40px;
  }
  .text h2.h1 {
    font-size: 50px;
  }
  .text h2.extra {
    font-size: 38px;
  }
  .text p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .text h3.h2-lg {
    font-size: 40px;
  }
  .text h2 {
    margin-bottom: 40px;
  }
  .text h2.h1 {
    font-size: 66px;
  }
  .text h2.extra {
    font-size: 40px;
  }
  .text p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .text {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .text {
    padding-bottom: 80px;
  }
}
.text .line {
  margin-bottom: 20px;
  background-color: #e6e3d9;
  width: 100%;
  height: 2px;
}
@media (min-width: 768px) {
  .text .line {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .text .line {
    margin-bottom: 40px;
  }
}

.text-image {
  color: #494949;
  padding-top: 40px;
}
.text-image h1, .text-image h2, .text-image h3, .text-image h4, .text-image h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.text-image h1.hyphens, .text-image h2.hyphens, .text-image h3.hyphens, .text-image h4.hyphens, .text-image h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.text-image h1.white, .text-image h2.white, .text-image h3.white, .text-image h4.white, .text-image h5.white, .text-image p.white {
  color: #fff;
}
.text-image h1.app-color, .text-image h2.app-color, .text-image h3.app-color, .text-image h4.app-color, .text-image h5.app-color, .text-image p.app-color {
  color: #376a85;
}
.text-image h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.text-image h2.h1 {
  font-size: 40px;
}
.text-image h3 {
  padding-bottom: 20px;
}
.text-image h3, .text-image h4 {
  padding-top: 20px;
  font-size: 24px;
}
.text-image h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.text-image p, .text-image h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.text-image p {
  font-weight: 300;
}
.text-image p.mini,
.text-image p.mini-sm {
  font-size: 18px;
}
.text-image p.tiny {
  font-size: 14px;
}
.text-image h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .text-image p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .text-image h2 {
    margin-bottom: 40px;
  }
  .text-image h2.h1 {
    font-size: 50px;
  }
  .text-image h2.extra {
    font-size: 38px;
  }
  .text-image p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .text-image h3.h2-lg {
    font-size: 40px;
  }
  .text-image h2 {
    margin-bottom: 40px;
  }
  .text-image h2.h1 {
    font-size: 66px;
  }
  .text-image h2.extra {
    font-size: 40px;
  }
  .text-image p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .text-image {
    padding-top: 60px;
  }
}
@media (min-width: 992px) {
  .text-image {
    padding-top: 80px;
  }
}
.text-image h2 span,
.text-image h3 span {
  color: #376a85;
  font-weight: bold;
}
.text-image p span {
  color: #376a85;
  font-weight: bold;
}
.text-image object {
  width: 100%;
  max-width: 250px;
  margin-top: -23px;
}

.screenshots {
  color: #494949;
  padding-top: 40px;
}
.screenshots h1, .screenshots h2, .screenshots h3, .screenshots h4, .screenshots h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.screenshots h1.hyphens, .screenshots h2.hyphens, .screenshots h3.hyphens, .screenshots h4.hyphens, .screenshots h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.screenshots h1.white, .screenshots h2.white, .screenshots h3.white, .screenshots h4.white, .screenshots h5.white, .screenshots p.white {
  color: #fff;
}
.screenshots h1.app-color, .screenshots h2.app-color, .screenshots h3.app-color, .screenshots h4.app-color, .screenshots h5.app-color, .screenshots p.app-color {
  color: #376a85;
}
.screenshots h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.screenshots h2.h1 {
  font-size: 40px;
}
.screenshots h3 {
  padding-bottom: 20px;
}
.screenshots h3, .screenshots h4 {
  padding-top: 20px;
  font-size: 24px;
}
.screenshots h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.screenshots p, .screenshots h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.screenshots p {
  font-weight: 300;
}
.screenshots p.mini,
.screenshots p.mini-sm {
  font-size: 18px;
}
.screenshots p.tiny {
  font-size: 14px;
}
.screenshots h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .screenshots p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .screenshots h2 {
    margin-bottom: 40px;
  }
  .screenshots h2.h1 {
    font-size: 50px;
  }
  .screenshots h2.extra {
    font-size: 38px;
  }
  .screenshots p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .screenshots h3.h2-lg {
    font-size: 40px;
  }
  .screenshots h2 {
    margin-bottom: 40px;
  }
  .screenshots h2.h1 {
    font-size: 66px;
  }
  .screenshots h2.extra {
    font-size: 40px;
  }
  .screenshots p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .screenshots {
    padding-top: 60px;
  }
}
@media (min-width: 992px) {
  .screenshots {
    padding-top: 80px;
  }
}
.screenshots h2 span,
.screenshots h3 span {
  color: #376a85;
  font-weight: bold;
}
.screenshots p span {
  color: #376a85;
  font-weight: bold;
}
.screenshots .container-big {
  background-color: #FDEDFF;
  padding: 0px 30px;
}
.screenshots .screenshots-items {
  background-color: #FDEDFF;
}
.screenshots .screenshots-items:not(.horizontal-scroll) {
  padding-bottom: 40px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .screenshots .screenshots-items:not(.horizontal-scroll) {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .screenshots .screenshots-items:not(.horizontal-scroll) {
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) {
  .screenshots .screenshots-items:not(.horizontal-scroll) {
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
  .screenshots .screenshots-items:not(.horizontal-scroll) {
    margin-top: 40px;
  }
}
.screenshots .screenshots-items:not(.horizontal-scroll) .screenshots-item {
  padding-top: 20px;
}
@media (min-width: 768px) {
  .screenshots .screenshots-items:not(.horizontal-scroll) .screenshots-item {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .screenshots .screenshots-items:not(.horizontal-scroll) .screenshots-item {
    padding-top: 40px;
  }
}
.screenshots .screenshots-items .screenshots-item p {
  margin: 0;
  font-size: 16px;
}
.screenshots .screenshots-item-middle {
  max-width: 450px;
  margin: 30px;
}
.screenshots .screenshots-item-middle img {
  margin: 0 auto;
}
.screenshots .button-block {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #FDEDFF;
}
@media (min-width: 768px) {
  .screenshots .button-block {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .screenshots .button-block {
    padding-top: 40px;
  }
}
@media (min-width: 768px) {
  .screenshots .button-block {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .screenshots .button-block {
    padding-bottom: 40px;
  }
}
.screenshots .button-block a {
  margin-right: 0;
}
.screenshots .mobile-view-button a {
  margin: 0 auto;
}
.screenshots ::-webkit-scrollbar {
  width: 20px;
}
.screenshots ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.screenshots ::-webkit-scrollbar-thumb {
  background: #376a85;
  border-radius: 10px;
}
.screenshots ::-webkit-scrollbar-thumb:hover {
  background: #4ad9f6;
}

@media (min-width: 992px) {
  .screenshots .screenshots-item-middle {
    margin: 50px;
  }
  .screenshots .screenshots-items .screenshots-item .animate-icon,
  .screenshots .screenshots-items .screenshots-item .ani-target {
    opacity: 0;
  }
  .screenshots .screenshots-items .screenshots-item .animate-icon,
  .screenshots .screenshots-items .screenshots-item .ani-target {
    transition: 0.3s;
  }
  .screenshots .screenshots-items .screenshots-item .animate-icon.animate-icon-show,
  .screenshots .screenshots-items .screenshots-item .ani-target.animate-icon-show {
    opacity: 1;
  }
}
.contact-cards {
  padding-bottom: 40px;
  color: #494949;
}
@media (min-width: 768px) {
  .contact-cards {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .contact-cards {
    padding-bottom: 80px;
  }
}
.contact-cards h1, .contact-cards h2, .contact-cards h3, .contact-cards h4, .contact-cards h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.contact-cards h1.hyphens, .contact-cards h2.hyphens, .contact-cards h3.hyphens, .contact-cards h4.hyphens, .contact-cards h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.contact-cards h1.white, .contact-cards h2.white, .contact-cards h3.white, .contact-cards h4.white, .contact-cards h5.white, .contact-cards p.white {
  color: #fff;
}
.contact-cards h1.app-color, .contact-cards h2.app-color, .contact-cards h3.app-color, .contact-cards h4.app-color, .contact-cards h5.app-color, .contact-cards p.app-color {
  color: #376a85;
}
.contact-cards h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.contact-cards h2.h1 {
  font-size: 40px;
}
.contact-cards h3 {
  padding-bottom: 20px;
}
.contact-cards h3, .contact-cards h4 {
  padding-top: 20px;
  font-size: 24px;
}
.contact-cards h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.contact-cards p, .contact-cards h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.contact-cards p {
  font-weight: 300;
}
.contact-cards p.mini,
.contact-cards p.mini-sm {
  font-size: 18px;
}
.contact-cards p.tiny {
  font-size: 14px;
}
.contact-cards h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .contact-cards p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .contact-cards h2 {
    margin-bottom: 40px;
  }
  .contact-cards h2.h1 {
    font-size: 50px;
  }
  .contact-cards h2.extra {
    font-size: 38px;
  }
  .contact-cards p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .contact-cards h3.h2-lg {
    font-size: 40px;
  }
  .contact-cards h2 {
    margin-bottom: 40px;
  }
  .contact-cards h2.h1 {
    font-size: 66px;
  }
  .contact-cards h2.extra {
    font-size: 40px;
  }
  .contact-cards p.extra {
    font-size: 30px;
  }
}
.contact-cards .contact-row-line {
  width: 100%;
  height: 2px;
  background-color: #e6e3d9;
  margin: 30px 0px 0px 0px;
}
.contact-cards h3 {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .contact-cards h3 {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .contact-cards h3 {
    margin-bottom: 40px;
  }
}
.contact-cards .card {
  border-radius: 5px;
  padding: 40px 30px 40px 40px;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}
.contact-cards .card span {
  font-weight: 700;
}
.contact-cards .card a {
  text-decoration: none;
  transition: 0.2s;
}
.contact-cards .card a:hover .btn-img-bg, .contact-cards .card a:active .btn-img-bg, .contact-cards .card a:focus .btn-img-bg {
  background-color: #b9e2f2;
}
.contact-cards .card a:hover h3,
.contact-cards .card a:hover h4, .contact-cards .card a:active h3,
.contact-cards .card a:active h4, .contact-cards .card a:focus h3,
.contact-cards .card a:focus h4 {
  color: #fff;
}
.contact-cards .btn-img-bg {
  border-radius: 50%;
  display: flex;
  align-items: center;
}
.contact-cards .btn-img-bg img {
  margin: auto;
}
.contact-cards .card-left {
  background-color: #b9e2f2;
  color: #FFFFFF;
  margin-top: 30px;
}
.contact-cards .card-left span {
  color: #b9e2f2;
  font-weight: bold;
}
.contact-cards .card-left .btn-img-bg {
  background-color: #376a85;
  width: 140px;
  height: 140px;
}
.contact-cards .card-left h3 {
  margin-top: 20px;
  margin-bottom: 0px;
}
.contact-cards .card-left h4 {
  margin-bottom: 20px;
}
.contact-cards .card-right {
  background-color: #74b6d1;
  color: #0A2538;
  margin-top: 30px;
}
.contact-cards .card-right p {
  margin-top: 10px;
}
.contact-cards .card-right h4 {
  margin-bottom: 0px;
  align-self: center;
}
.contact-cards .card-right .card-top-wrapper {
  display: flex;
  margin-top: 20px;
  color: #000;
}
.contact-cards .card-right .card-top-wrapper h4 {
  padding-left: 20px;
  width: 70%;
}
.contact-cards .card-right .btn-img-bg {
  background-color: #000;
  width: 95px;
  height: 95px;
}
.contact-cards .card-right .btn-img-bg img {
  margin: auto;
  height: 65px;
}

.project-priovi .contact-cards h3.app-color, .project-levidex .contact-cards h3.app-color, .project-somnovia .contact-cards h3.app-color {
  color: #b9e2f2;
}

.page_1.project-somnovia .contact-cards, .page_1.project-liebria .contact-cards, .page_1.project-reviga .contact-cards {
  padding-top: 80px;
}

@media (min-width: 992px) {
  .contact-cards .card {
    width: 45%;
  }
  .contact-cards .card-left {
    margin-top: 0;
  }
  .contact-cards .card-right {
    margin-top: 0;
  }
}
.contact.light {
  background-color: #fff;
}
.contact.light .item-left .item-img-wrapper {
  background-color: #b9e2f2;
}
.contact.light .item-right p {
  color: #0A2538;
}
.contact.light .line {
  background-color: #dfe5e6;
}

.contact.dark {
  background-color: #376a85;
}
.contact.dark h2 {
  color: #fff;
}
.contact.dark p {
  color: #0A2538;
}
.contact.dark a p {
  font-weight: 500;
}
.contact.dark .item-left .item-img-wrapper {
  background-color: #0A2538;
}
.contact.dark .item-right p {
  color: #fff;
  font-weight: 500;
}
.contact.dark .lines-outer {
  background-color: #376a85;
}
.contact.dark .lines-inner {
  background-color: #dfe5e6;
}

.project-priovi .contact.dark p {
  color: #e9b0ef;
}

.project-deprexis .contact.dark p {
  color: #d3e1e6;
}

.project-somnovia .contact {
  margin-bottom: 80px;
}
.project-somnovia .contact.dark a {
  text-decoration: none;
}
.project-somnovia .contact.dark a p {
  color: #A18AC1;
}
.project-somnovia .contact.dark a .item-img-wrapper,
.project-somnovia .contact.dark a p {
  transition: 0.2s;
}
.project-somnovia .contact.dark a:focus, .project-somnovia .contact.dark a:hover, .project-somnovia .contact.dark a:active {
  outline: 0 none;
}
.project-somnovia .contact.dark a:focus p, .project-somnovia .contact.dark a:hover p, .project-somnovia .contact.dark a:active p {
  color: #fff;
}
.project-somnovia .contact.dark a:focus .item-img-wrapper, .project-somnovia .contact.dark a:hover .item-img-wrapper, .project-somnovia .contact.dark a:active .item-img-wrapper {
  background-color: #baafce;
}
.project-somnovia .contact.dark p {
  color: #fff;
}
.project-somnovia .contact.dark .item-left .item-img-wrapper {
  background-color: #A18AC1;
}

.project-reviga .contact {
  margin-bottom: 80px;
}
.project-reviga .contact.dark a {
  text-decoration: none;
}
.project-reviga .contact.dark a p {
  color: #fff;
}
.project-reviga .contact.dark a .item-img-wrapper,
.project-reviga .contact.dark a p {
  transition: 0.2s;
}
.project-reviga .contact.dark a:focus, .project-reviga .contact.dark a:hover, .project-reviga .contact.dark a:active {
  outline: 0 none;
}
.project-reviga .contact.dark a:focus p, .project-reviga .contact.dark a:hover p, .project-reviga .contact.dark a:active p {
  color: #896002;
}
.project-reviga .contact.dark a:focus .item-img-wrapper, .project-reviga .contact.dark a:hover .item-img-wrapper, .project-reviga .contact.dark a:active .item-img-wrapper {
  background-color: #4b3412;
}
.project-reviga .contact.dark p {
  color: #fff;
}
.project-reviga .contact.dark .item-left .item-img-wrapper {
  background-color: #4b3412;
}
.project-reviga .contact.dark .item-left .item-img-wrapper:hover {
  background: #896002;
}

.project-liebria .contact {
  margin-bottom: 80px;
}
.project-liebria .contact.dark p {
  color: #fff;
}

.contact {
  color: #494949;
  padding-bottom: 40px;
}
.contact h1, .contact h2, .contact h3, .contact h4, .contact h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.contact h1.hyphens, .contact h2.hyphens, .contact h3.hyphens, .contact h4.hyphens, .contact h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.contact h1.white, .contact h2.white, .contact h3.white, .contact h4.white, .contact h5.white, .contact p.white {
  color: #fff;
}
.contact h1.app-color, .contact h2.app-color, .contact h3.app-color, .contact h4.app-color, .contact h5.app-color, .contact p.app-color {
  color: #376a85;
}
.contact h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.contact h2.h1 {
  font-size: 40px;
}
.contact h3 {
  padding-bottom: 20px;
}
.contact h3, .contact h4 {
  padding-top: 20px;
  font-size: 24px;
}
.contact h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.contact p, .contact h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.contact p {
  font-weight: 300;
}
.contact p.mini,
.contact p.mini-sm {
  font-size: 18px;
}
.contact p.tiny {
  font-size: 14px;
}
.contact h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .contact p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .contact h2 {
    margin-bottom: 40px;
  }
  .contact h2.h1 {
    font-size: 50px;
  }
  .contact h2.extra {
    font-size: 38px;
  }
  .contact p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .contact h3.h2-lg {
    font-size: 40px;
  }
  .contact h2 {
    margin-bottom: 40px;
  }
  .contact h2.h1 {
    font-size: 66px;
  }
  .contact h2.extra {
    font-size: 40px;
  }
  .contact p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .contact {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .contact {
    padding-bottom: 80px;
  }
}
.contact .contact-cta-item {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}
.contact .contact-cta-item p {
  margin: -25px 25px 0 25px;
}
.contact .item-left .item-img-wrapper {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  min-width: 100px;
  margin: 0 0 25px 0;
}
@media (min-width: 576px) {
  .contact .item-left .item-img-wrapper {
    height: 120px;
    width: 120px;
    min-width: 120px;
  }
}
@media (min-width: 992px) {
  .contact .item-left .item-img-wrapper {
    height: 179px;
    width: 179px;
    min-width: 179px;
  }
}
.contact .item-left .item-img-wrapper img {
  margin: 0;
  position: relative;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.contact .item-left p {
  width: 106px;
}
.contact .item-right img {
  height: 100px;
  width: 100px;
  min-width: 100px;
  margin: 0 0 25px 0;
}
@media (min-width: 576px) {
  .contact .item-right img {
    height: 120px;
    width: 120px;
    min-width: 120px;
  }
}
@media (min-width: 992px) {
  .contact .item-right img {
    height: 179px;
    width: 179px;
    min-width: 179px;
  }
}

.lines-outer {
  opacity: 0;
  margin: 50px 0px;
}

@media (min-width: 576px) {
  .lines-outer {
    margin: 55px 0px;
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .lines-outer {
    margin: 65px 0px;
  }
}
@media only screen and (min-width: 350px) {
  .contact .item-left p {
    width: auto;
  }
}
@media (min-width: 768px) {
  .contact .contact-cta {
    display: grid;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 120px 120px 1fr;
    grid-template-rows: 2px 1fr;
    grid-gap: 25px;
  }
  .contact .line1 {
    grid-area: 1/1/2/2;
  }
  .contact .line2 {
    grid-area: 1/4/2/5;
  }
  .contact .item-left {
    grid-area: 2/1/3/3;
  }
  .contact .item-right {
    grid-area: 2/3/3/5;
  }
  .contact .lines-inner {
    margin-top: 50px;
  }
  .contact .line {
    height: 2px;
    width: 100%;
  }
  .contact .contact-cta-item {
    display: block;
  }
  .contact .item-left,
  .contact .item-right {
    display: flex;
  }
  .contact .item-left {
    flex-direction: row;
  }
  .contact .item-left p {
    margin: -25px 25px 0 0px;
  }
  .contact .item-right p {
    margin: -25px 0px 0 25px;
  }
}
@media (min-width: 992px) {
  .contact .contact-cta {
    grid-template-columns: 1fr 179px 179px 1fr;
  }
}
.page_6 .contact {
  padding-top: 80px;
}

.webinar-section {
  color: #494949;
  padding-top: 40px;
  padding-bottom: 40px;
}
.webinar-section h1, .webinar-section h2, .webinar-section h3, .webinar-section h4, .webinar-section h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.webinar-section h1.hyphens, .webinar-section h2.hyphens, .webinar-section h3.hyphens, .webinar-section h4.hyphens, .webinar-section h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.webinar-section h1.white, .webinar-section h2.white, .webinar-section h3.white, .webinar-section h4.white, .webinar-section h5.white, .webinar-section p.white {
  color: #fff;
}
.webinar-section h1.app-color, .webinar-section h2.app-color, .webinar-section h3.app-color, .webinar-section h4.app-color, .webinar-section h5.app-color, .webinar-section p.app-color {
  color: #376a85;
}
.webinar-section h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.webinar-section h2.h1 {
  font-size: 40px;
}
.webinar-section h3 {
  padding-bottom: 20px;
}
.webinar-section h3, .webinar-section h4 {
  padding-top: 20px;
  font-size: 24px;
}
.webinar-section h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.webinar-section p, .webinar-section h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.webinar-section p {
  font-weight: 300;
}
.webinar-section p.mini,
.webinar-section p.mini-sm {
  font-size: 18px;
}
.webinar-section p.tiny {
  font-size: 14px;
}
.webinar-section h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .webinar-section p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .webinar-section h2 {
    margin-bottom: 40px;
  }
  .webinar-section h2.h1 {
    font-size: 50px;
  }
  .webinar-section h2.extra {
    font-size: 38px;
  }
  .webinar-section p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .webinar-section h3.h2-lg {
    font-size: 40px;
  }
  .webinar-section h2 {
    margin-bottom: 40px;
  }
  .webinar-section h2.h1 {
    font-size: 66px;
  }
  .webinar-section h2.extra {
    font-size: 40px;
  }
  .webinar-section p.extra {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  .webinar-section {
    padding-top: 60px;
  }
}
@media (min-width: 992px) {
  .webinar-section {
    padding-top: 80px;
  }
}
@media (min-width: 768px) {
  .webinar-section {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .webinar-section {
    padding-bottom: 80px;
  }
}
.webinar-section span {
  color: #376a85;
  font-weight: bold;
}
.webinar-section hr {
  width: 100%;
  height: 2px;
  background-color: #FDEDFF;
}
.webinar-section .container-big {
  padding: 10px;
  background-color: #C656C6;
  border-radius: 7px;
}
.webinar-section .webinar-content {
  padding-left: 20px;
}
.webinar-section .webinar-content p {
  margin-bottom: 0px;
}

.project-priovi .webinar-section .webinar-content p {
  color: #EABCE9;
}

.project-optimune .webinar-section .container-big, .project-somnovia .webinar-section .container-big {
  background-color: #C656C6;
  color: #fff;
}
.project-optimune .webinar-section .container-big .webinar-content h3, .project-somnovia .webinar-section .container-big .webinar-content h3 {
  color: #fff;
}

@media (min-width: 576px) {
  .webinar-section .container-big {
    padding: 30px 55px;
  }
}
.subscription-box {
  padding-top: 20px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .subscription-box {
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .subscription-box {
    padding-top: 40px;
  }
}
@media (min-width: 768px) {
  .subscription-box {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .subscription-box {
    padding-bottom: 80px;
  }
}
.subscription-box .container-big {
  background-color: #b9e2f2;
  padding: 50px;
}
.subscription-box .container-big .row-line {
  background-color: #fff;
  height: 2px;
  margin: 40px 0px;
  width: 100%;
}
.subscription-box .container-big .box-cta {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subscription-box .container-big .box-cta img {
  padding-right: 10px;
  width: 45px;
}
.subscription-box .container-big span {
  font-weight: 600;
  color: #376a85;
}
.subscription-box .container-big a.rezept-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
}
.subscription-box .container-big a.rezept-button p {
  margin-left: 15px;
  margin-bottom: 0;
}
.subscription-box .container-big a.rezept-button p span {
  color: #C656C6;
}

@media (min-width: 768px) {
  .subscription-box .container-big .box-cta {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
.jumbotron {
  width: 100%;
  margin-bottom: 0;
  padding-top: 130px;
  padding-bottom: 80px;
  background-color: #376a85;
}
.jumbotron .container {
  padding: 0 10px;
}
.jumbotron .content {
  color: #fff;
  margin-top: 20px;
  z-index: 10;
}
.jumbotron .content h1 {
  font-size: 40px;
  line-height: 1.3;
  font-weight: 700;
  font-family: "Jost", sans-serif;
  margin-bottom: 30px;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.jumbotron .content h1 span {
  background-color: #376a85;
  white-space: pre-wrap;
}
.jumbotron .content h2, .jumbotron .content h1 span span {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.jumbotron .content h2 {
  font-size: 28px;
  line-height: 1.2;
  font-weight: 300;
  font-family: "Jost", sans-serif;
  margin-bottom: 30px;
}
.jumbotron .content p {
  font-size: 17px;
  line-height: 1.3;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  margin-bottom: 30px;
}
.jumbotron .jumbotron-logo {
  max-width: 100%;
  margin-top: 10px;
}
.jumbotron .jumbotron-logo object {
  width: 80%;
  display: block;
  margin: auto;
  padding-top: 20px;
}
.jumbotron .jumbotron-logo img {
  width: 100%;
  display: block;
  margin: auto;
  padding-top: 20px;
}
.jumbotron .jumbotron-logo p {
  font-size: 14px;
  text-align: right;
}

.page_7 .jumbotron .content h2 span {
  background-color: #376a85;
  white-space: pre-wrap;
}

@media only screen and (min-width: 370px) {
  .jumbotron .container {
    padding: 0 10%;
  }
}
@media (min-width: 576px) {
  .jumbotron .container {
    padding: 0;
  }
  .jumbotron .content h2 {
    font-size: 30px;
  }
  .jumbotron .jumbotron-logo {
    margin-top: 10px;
  }
  .jumbotron .jumbotron-logo object {
    width: 60%;
  }
  .jumbotron .jumbotron-logo img {
    width: 100%;
  }
  .page_7 .jumbotron .container .jumbotron-logo object {
    width: 80%;
  }
}
@media (min-width: 768px) {
  .jumbotron {
    min-height: 520px;
  }
  .jumbotron .container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .jumbotron .container .content {
    margin-top: 55px;
  }
  .jumbotron .container .content a {
    margin-left: 0;
  }
  .jumbotron .container .content h2 {
    max-width: 80%;
  }
  .jumbotron .container .jumbotron-logo {
    position: absolute;
    left: 60%;
    width: 300px;
    margin: 0 auto;
  }
  .jumbotron .container .jumbotron-logo object {
    width: 100%;
    margin: auto;
    padding-top: 0px;
    padding-left: 20px;
  }
  .jumbotron .container .jumbotron-logo img {
    width: 100%;
    max-width: 100%;
    margin: auto;
    padding-top: 0px;
    padding-left: 20px;
  }
  .page_6 .jumbotron .container .content {
    margin-top: 30px;
  }
  .page_6 .jumbotron .container .content h1 {
    font-size: 32px;
  }
  .page_6 .jumbotron .container .content h2 {
    font-size: 24px;
  }
  .page_7 .jumbotron .container .jumbotron-logo {
    width: 640px;
    left: 32%;
  }
}
@media (min-width: 992px) {
  .jumbotron {
    min-height: 620px;
  }
  .jumbotron .container .content {
    margin-top: 50px;
  }
  .jumbotron .container .content h1 {
    font-size: 59px;
  }
  .jumbotron .container .content p {
    font-size: 20px;
  }
  .jumbotron .container .jumbotron-logo {
    width: 350px;
  }
  .page_6 .jumbotron .container .content {
    margin-top: 50px;
  }
  .page_6 .jumbotron .container .content h1 {
    font-size: 50px;
  }
  .page_6 .jumbotron .container .content h2 {
    font-size: 28px;
  }
  .page_7 .jumbotron .container .jumbotron-logo {
    width: 720px;
    left: 40%;
  }
}
@media (min-width: 1500px) {
  .jumbotron {
    min-height: 670px;
  }
  .jumbotron .container .content h1 {
    font-size: 66px;
    letter-spacing: 1px;
  }
  .jumbotron .container .jumbotron-logo {
    width: 400px;
  }
  .page_6 .jumbotron .container .content h1 {
    font-size: 66px;
  }
  .page_7 .jumbotron .container .jumbotron-logo {
    width: 750px;
    left: 42%;
  }
  .page_7 .jumbotron .container .content h1 {
    padding-right: 30px;
  }
}
.hero {
  width: 100%;
  margin-bottom: 0;
  padding-top: 90px;
  padding-bottom: 20px;
  background-color: #376a85;
}
@media (min-width: 768px) {
  .hero {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .hero {
    padding-bottom: 40px;
  }
}
.hero .title {
  padding-top: 40px;
  z-index: 1;
}
.hero .title h1 {
  color: #fff;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  font-family: "Jost", sans-serif;
}
.hero .title h1 span span {
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.hero .title h1 span br {
  display: block;
}
.hero .content {
  z-index: 1;
}
.hero .content img {
  width: 30px;
}
.hero .content a img {
  padding-top: 0px;
}
.hero .content a.rezept-button {
  display: flex;
  align-items: center;
  text-align: left;
  background-color: #376a85;
}
.hero .content a.rezept-button p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0;
  margin-left: 15px;
}
.hero object, .hero img {
  width: 80%;
  display: block;
  margin: auto;
  padding-top: 20px;
}
.hero p {
  color: #75b8d3;
  font-size: 23px;
  line-height: 1.1;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  margin-bottom: 30px;
}
.hero .container {
  padding: 0 10%;
}

.page_5 .hero .content img {
  width: auto;
  margin: 0;
  padding: 0 15px 0 0;
}
.page_5 .hero .content a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.page_5 .hero img {
  width: 100%;
  display: block;
  margin: 20px auto 0 auto;
  padding-bottom: 30px;
  padding-left: 0px;
}
.page_5 .hero .title h1 {
  line-height: 1.1;
}
.page_5 .hero p {
  margin-top: 15px;
  font-weight: 300;
}
.page_5 .hero .bob-style {
  background-color: #fff;
  padding: 15px 30px;
  width: 100%;
}
.page_5 .hero .bob-style:hover {
  color: #fff;
  background-color: #000;
}

.page_8 .hero {
  padding-top: 120px;
}
.page_8 .hero .container {
  padding-left: 10px;
  padding-right: 10px;
}
.page_8 .hero .title h1 {
  font-size: 40px;
}
.page_8 .hero p {
  font-size: 20px;
}
.page_8 .hero object, .page_8 .hero img {
  padding-bottom: 30px;
}
.page_8 .hero .bob-style {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.page_8 span {
  font-weight: 700;
  color: #376a85;
}

@media (min-width: 576px) {
  .hero .title h1 {
    font-size: 45px;
  }
  .hero .title h1 span span {
    -moz-hyphens: unset;
    -webkit-hyphens: unset;
    -ms-hyphens: unset;
    hyphens: unset;
  }
  .hero .title h1 span br {
    display: none;
  }
}
@media (min-width: 768px) {
  .page_5 .hero .title {
    grid-area: 1/1/2/2;
  }
  .page_5 .hero img {
    margin-top: 90px;
    padding-left: 20px;
  }
  .hero .container {
    padding: 0;
  }
  .hero .grid {
    display: grid;
    grid-template-columns: 5fr 90px 3fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
  .hero .title {
    padding-top: 70px;
    grid-area: 1/1/2/2;
  }
  .hero .title h1 {
    font-size: 48px;
    line-height: 1.28;
  }
  .hero .title span {
    background-color: #376a85;
    white-space: pre-wrap;
    padding-right: 10px;
  }
  .hero .title span span {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  .hero .content {
    grid-area: 2/1/3/2;
    z-index: 1;
  }
  .hero .content p {
    font-size: 28px;
  }
  .hero object, .hero img {
    grid-area: 1/2/3/4;
  }
  .page_8 .hero p {
    font-size: 20px;
  }
  .page_8 .hero object, .page_8 .hero img {
    padding-bottom: 0px;
  }
  .page_8 .hero .bob-style {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .page_5 .hero img {
    margin-top: 120px;
  }
  .hero .container {
    padding: 0 40px;
  }
  .hero .grid {
    grid-template-columns: 5fr 145px 2fr;
  }
  .hero .title {
    padding-top: 90px;
  }
  .hero .title h1 {
    font-size: 59px;
  }
  .hero .content {
    padding-right: 45px;
  }
  .hero .content p {
    font-size: 30px;
  }
  .page_8 .hero object, .page_8 .hero img {
    width: 100%;
    grid-area: 1/2/4/4;
  }
  .page_8 .hero .title {
    grid-area: 1/1/2/2;
  }
  .page_8 .hero .title h1 {
    font-size: 52px;
    line-height: 1.3;
  }
  .page_8 .hero .content p {
    font-size: 20px;
    line-height: 1.2;
  }
}
@media (min-width: 1500px) {
  .hero .grid {
    grid-template-columns: 2fr 182px 1fr;
  }
  .hero .title {
    padding-top: 100px;
  }
  .hero .title h1 {
    font-size: 67px;
  }
  .hero .content p {
    font-size: 67px;
    color: #75b8d3;
  }
  .page_5 .hero .content p {
    font-size: 32px;
    color: #75b8d3;
  }
  .page_6 .hero .content p {
    font-size: 32px;
  }
  .page_8 .hero .title {
    grid-area: 1/1/2/2;
  }
  .page_8 .hero .title h1 {
    font-size: 56px;
    line-height: 1.3;
  }
  .page_8 .hero .content p {
    font-size: 20px;
    line-height: 1.2;
  }
}
#bofu-landing .hero h1 {
  font-size: 1.8rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}
#bofu-landing .hero h1 .text-brand {
  color: #73b7d1;
}
#bofu-landing .hero p {
  font-size: 1.25rem;
  line-height: 1.3;
  color: #fff;
}
#bofu-landing .hero #ul-features li {
  list-style-type: none;
  color: #fff;
  background: url("/images/check.svg") no-repeat left center;
  margin: 2rem 0 2rem -40px;
  padding-left: 60px;
  line-height: 27px;
}

.hero-bullet {
  padding-top: 130px;
  padding-bottom: 50px;
  background-color: #F9EEFE;
}
.hero-bullet .container .hero-content {
  width: 100%;
  padding-top: 40px;
}
.hero-bullet .container .hero-content img {
  width: 60px;
  padding: 20px 30px 0px 0px;
}
.hero-bullet .container .hero-content h1 {
  font-size: 40px;
  color: #0A2538;
  margin-bottom: 30px;
  line-height: 48px;
  font-weight: 700;
  font-family: "Jost", sans-serif;
}
.hero-bullet .container .hero-content .bullet-section p {
  font-size: 20px;
}
.hero-bullet .container .hero-content p {
  font-size: 24px;
  color: #0A2538;
  line-height: 1.1;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  margin-bottom: 30px;
}
.hero-bullet .container .hero-content p span {
  color: #b9e2f2;
}
.hero-bullet .container .hero-content h4 {
  font-size: 24px;
  font-weight: 700;
  color: #0A2538;
}
.hero-bullet .container .hero-content .btn-mobile {
  display: block;
}
.hero-bullet .container .hero-image {
  width: 100%;
}
.hero-bullet .container .hero-image img {
  width: 100%;
  max-width: 400px;
  display: block;
  margin: auto;
}
.hero-bullet .container .hero-image .btn-desktop {
  display: none;
}
.hero-bullet .bob-style {
  margin: 40px auto;
  color: #ffF;
  background-color: #0A2538;
  padding-top: 20px;
  padding-bottom: 20px;
}
.hero-bullet span {
  font-weight: 700;
  color: #b9e2f2;
}

@media (min-width: 992px) {
  .hero-bullet .container .hero-content .btn-mobile {
    display: none;
  }
  .hero-bullet .container .hero-image .btn-desktop {
    display: block;
  }
}
h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.15;
  color: #0A2538;
  margin-bottom: 40px;
}
h2 span {
  color: #376a85;
  font-weight: bold;
}

.page_16 h1 {
  font-size: 26px;
}
.page_16 .passage {
  padding-top: 0px;
}
.page_16 .passage p {
  font-size: 14px;
}

.project-priovi.page_10 .hero {
  background-color: #fff;
  color: #0A2538;
}
.project-priovi.page_10 .hero .title h1 {
  color: #0A2538;
  font-size: 40px;
}
.project-priovi.page_10 .hero .title span {
  background-color: unset;
}
.project-priovi.page_10 .hero p {
  color: #0A2538;
  font-size: 20px;
}
.project-priovi.page_10 .hero object {
  width: 100%;
}
.project-priovi.page_10 .hero .bob-style {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #0A2538;
  color: #FFF;
}
.project-priovi.page_10 .usps {
  background-color: #F5CFF9;
}
.project-priovi.page_10 .usps .usp-cards p {
  color: #0A2538;
}
.project-priovi.page_10 .arrow {
  margin: 0 auto 60px auto;
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-top: 60px solid #F5CFF9;
  border-bottom: 0 none;
  width: 0;
  height: 0;
}
.project-priovi.page_10 .box-section {
  padding-bottom: 80px;
}
.project-priovi.page_10 .box-section span {
  color: #376a85;
  font-weight: 700;
}
.project-priovi.page_10 .box-section h2 {
  color: #0A2538;
  font-size: 36px;
  font-weight: 700;
}
.project-priovi.page_10 .box-section hr {
  margin-bottom: 60px;
  margin-top: 70px;
  border-color: #dfc7e8;
}
.project-priovi.page_10 .box-section .bob-style {
  margin: 10px auto 0px;
  color: #FFF;
  background-color: #0A2538;
  display: block;
}

@media (min-width: 768px) {
  .project-priovi.page_10 .hero p {
    font-size: 20px;
  }
  .project-priovi.page_10 .hero .title {
    grid-area: 1/1/2/2;
  }
  .project-priovi.page_10 .hero object {
    padding-bottom: 0px;
  }
  .project-priovi.page_10 .hero .bob-style {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .project-priovi.page_10 .hero .title h1 {
    font-size: 52px;
    line-height: 1.3;
  }
  .project-priovi.page_10 .hero .content p {
    font-size: 20px;
    line-height: 1.2;
  }
  .project-priovi.page_10 .box-section h2 {
    font-size: 48px;
  }
}
@media (min-width: 1500px) {
  .project-priovi.page_10 .hero .title h1 {
    font-size: 56px;
    line-height: 1.3;
  }
  .project-priovi.page_10 .hero .content p {
    font-size: 20px;
    line-height: 1.2;
  }
  .project-priovi.page_10 .box-section h2 {
    font-size: 52px;
  }
}
.navbar .container {
  padding-bottom: 5px;
  overflow: unset;
}
.navbar .nav-link-span {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.navbar .navbarDropdownMenuLink {
  margin-bottom: -11px;
}
.navbar .navbarDropdownMenuLink svg {
  position: relative;
  z-index: 1001;
}
.navbar .nav-item.dropdown:hover #arrow-down {
  stroke: #376a85;
}
.navbar .nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
.navbar .dropdown-menu {
  width: 13rem;
  left: 50%;
  transform: translate(-50%);
  background-color: #73b7d1;
  border: 0;
  border-radius: 0.5rem;
  margin-top: -4px;
}
.navbar .dropdown-menu .dropdown-divider {
  width: 22%;
  margin: 0.5rem 41px;
  border-top: 2px solid #9cc8de;
}
.navbar .dropdown-menu a.dropdown-item {
  color: #d5e7f1;
  padding: 0.25rem 1.25rem;
}
.navbar .dropdown-menu a.dropdown-item span {
  font-size: 20px;
  color: #73b7d1;
}
.navbar .dropdown-menu a.dropdown-item:hover {
  color: #fff;
  background-color: #73b7d1;
}
.navbar .dropdown-menu a.dropdown-item:hover span {
  color: #fff;
}

.nav-button .nav-link {
  background: #f8e3a6;
  border-radius: 25px;
}
.nav-button .nav-link .nav-link-span {
  color: #3b3b3b;
}

#bofu-landing .navbar .nav-item img {
  width: 45px;
  height: 45px;
  margin: 0;
}
#bofu-landing .navbar .nav-item #img-bund {
  width: 60px;
}

.filter-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.rezept-form {
  background-color: #e8f7fd;
  margin-bottom: 60px;
  padding: 100px 0px;
}
.rezept-form label {
  font-size: 18px;
  line-height: 1.2;
  padding-top: 5px;
}
.rezept-form h3 {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  color: #0A2538;
}
.rezept-form #select-attest {
  width: 75%;
  height: 50px;
  color: #000;
  background-color: #b9e2f2;
  border-radius: 15px;
  border: 0;
  padding: 10px;
}
.rezept-form input[type=password] {
  font: caption;
}
.rezept-form .form-control {
  border-radius: 20px;
  background-color: #F9F9F9;
}
.rezept-form input::placeholder, .rezept-form select {
  color: #AFAFAF;
  font-style: normal;
}
.rezept-form select {
  padding-right: 10px;
}
.rezept-form .form-group-file input {
  height: 150px;
  opacity: 0;
  position: absolute;
  margin-top: -110px;
}
.rezept-form .form-group-file .form-control.is-invalid {
  border-color: #dc3545;
}
.rezept-form .form-group-file .form-control {
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 20px;
  background-color: #F9F9F9;
  border: 1px solid #ced4da;
}
.rezept-form .form-group-file .form-control p {
  color: #AFAFAF;
}
.rezept-form .form-group-file .form-control .error-font {
  color: red;
}
.rezept-form .form-group-email .glyphicons, .rezept-form .form-group-email-confirm .glyphicons {
  cursor: pointer;
  margin-left: -45px;
  padding: 5px;
}
.rezept-form .form-group-email .glyphicons.glyphicons-eye-slash, .rezept-form .form-group-email-confirm .glyphicons.glyphicons-eye-slash {
  display: none;
}
.rezept-form .form-button span {
  color: #b9e2f2;
  font-weight: 700;
}
.rezept-form .line {
  margin-top: 50px;
  background-color: #e6e3d9;
  width: 100%;
  height: 2px;
}
.rezept-form .note-font {
  font-size: 13px;
}
.rezept-form p {
  line-height: 1.2;
}
.rezept-form button, .rezept-form button:hover, .rezept-form button:active {
  background-color: #376a85 !important;
  border: #376a85 !important;
}
.rezept-form .insurencehint {
  margin: 1rem 0;
}

.logos {
  background-color: #73b7d1;
  padding-top: 60px;
}
.logos .logo-cards {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.logos .logo-cards img {
  display: block;
  max-height: 110px;
}

@media (min-width: 768px) {
  .logos {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .logos .container {
    padding: 0px 40px;
  }
}
@media (min-width: 992px) {
  .logos .logo-cards {
    margin-bottom: 0px;
  }
}
.block {
  margin: 60px 0;
}
.block .row {
  padding: 40px;
}
.block .row h3 {
  font-size: 2rem;
}
.block .row .bob-style {
  background: #376a85;
  color: #fff;
  padding: 8px 40px;
}
.block p span {
  color: #376a85;
  font-weight: bold;
}
.block .bg {
  background: #b9e2f2;
}
.block.dark .bg {
  background: #0a2537;
  color: #fff;
}
.block.dark .row {
  padding: 50px 40px;
}
.block.dark .row .col {
  align-self: center;
}

.cont-code {
  margin-top: 160px;
}

#cont-developer {
  background: url("/images/quotation_marks.svg") no-repeat right top;
}

.research {
  margin: 60px 0;
}
.research .row {
  padding: 40px;
}
.research .row p {
  margin-bottom: 0;
}
.research .row p span {
  color: #376a85;
  font-weight: bold;
}
.research a {
  color: #376a85 !important;
}

.grad-blue-white {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 17%, rgb(185, 226, 242) 17%, rgb(185, 226, 242) 100%);
}

.usps h3 {
  font-size: 28px;
  font-weight: 700;
  color: #000;
}
.usps .usp-content .usp-cards {
  text-align: center;
  min-height: unset;
}
.usps .usp-content .usp-cards .usp-icon {
  margin: 0 auto;
}
.usps .usp-content .usp-cards .title {
  color: #fff;
}
.usps .usp-content .usp-cards p {
  color: #000;
}

.page_6 .usps {
  background: #74b6d1;
}

.page_5 .usps .usp-content .usp-cards {
  text-align: left;
}

.usps-arrow {
  margin: 0 auto 60px auto;
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-top: 60px solid #b9e2f2;
  border-bottom: 0 none;
  width: 0;
  height: 0;
}

#bofu-landing h2 {
  font-size: 1.8rem;
}
#bofu-landing h2 .text-brand {
  color: #376a85;
  font-weight: bold;
}
#bofu-landing .usps {
  background-color: #fff;
}
#bofu-landing .usps .title {
  color: #3a6c86;
  padding: 10px 0px 0px 10px;
}

.slider-1, .slider-2, .slider-3 {
  padding: 0;
  margin: 60px 0 70px;
}
.slider-1 .container-big, .slider-2 .container-big, .slider-3 .container-big {
  overflow: unset;
}
.slider-1 p span, .slider-2 p span, .slider-3 p span {
  color: #376a85;
  font-weight: bold;
}
.slider-1 .flexslider-1, .slider-2 .flexslider-1, .slider-3 .flexslider-1 {
  margin-bottom: 25px;
}
.slider-1 .flex-control-nav, .slider-2 .flex-control-nav, .slider-3 .flex-control-nav {
  position: relative;
}
.slider-1 .flex-control-paging li a, .slider-2 .flex-control-paging li a, .slider-3 .flex-control-paging li a {
  width: 15px;
  height: 15px;
  background: #c9c9c9;
  border: 2px solid #fff;
}
.slider-1 .flex-control-paging li a.flex-active, .slider-2 .flex-control-paging li a.flex-active, .slider-3 .flex-control-paging li a.flex-active {
  background: #376a85;
  border: 2px solid #376a85;
}
.slider-1 .flex-direction-nav a, .slider-2 .flex-direction-nav a, .slider-3 .flex-direction-nav a {
  top: 0;
  height: 60px;
  color: transparent;
}
.slider-1 .flex-direction-nav a:before, .slider-2 .flex-direction-nav a:before, .slider-3 .flex-direction-nav a:before {
  color: #376a85;
  display: none;
}
.slider-1 .flex-direction-nav a:hover:before, .slider-2 .flex-direction-nav a:hover:before, .slider-3 .flex-direction-nav a:hover:before {
  display: inline-block;
  position: absolute;
  top: calc(50% - 40px);
}
.slider-1 .flex-direction-nav .flex-prev,
.slider-1 .flex-direction-nav .flex-next, .slider-2 .flex-direction-nav .flex-prev,
.slider-2 .flex-direction-nav .flex-next, .slider-3 .flex-direction-nav .flex-prev,
.slider-3 .flex-direction-nav .flex-next {
  height: 100%;
  width: 20%;
  border-radius: 0;
  background-color: transparent;
}
.slider-1 .flex-direction-nav .flex-prev, .slider-2 .flex-direction-nav .flex-prev, .slider-3 .flex-direction-nav .flex-prev {
  margin-left: -25px;
}
.slider-1 .flex-direction-nav .flex-next, .slider-2 .flex-direction-nav .flex-next, .slider-3 .flex-direction-nav .flex-next {
  margin-right: -25px;
}
.slider-1 .slider-content h3, .slider-2 .slider-content h3, .slider-3 .slider-content h3 {
  font-size: 34px;
  font-weight: bold;
}

.testimonial-slider {
  background-color: #b9e2f2;
  padding: 60px 0 80px;
}
.testimonial-slider p span {
  color: #376a85;
  font-weight: bold;
}
.testimonial-slider .testimonial {
  background: #fff;
}
.testimonial-slider .testimonial .header {
  margin-top: -50px;
}
.testimonial-slider .testimonial .fitTop {
  object-fit: cover;
  object-position: top;
  width: 230px;
  height: 230px;
}

.steps h2 span, .steps h3 span {
  color: #376a85;
  font-weight: bold;
}
.steps .container {
  background: #dbf0f8;
  padding: 40px !important;
}
.steps p span {
  color: #376a85;
  font-weight: bold;
}
.steps .bob-style {
  background: #376a85;
  color: #fff;
  padding: 8px 40px;
}
.steps .steps-content {
  margin-top: 0;
}
.steps .back-btn:focus {
  background-color: transparent !important;
}
.steps .form-check .form-check-input {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0;
}
.steps .form-control {
  border-radius: 20px;
  background-color: #F9F9F9;
}
.steps input::placeholder, .steps select option.is-invalid {
  color: #AFAFAF;
  font-style: normal;
}
.steps select {
  font-style: normal;
  padding-right: 10px;
}
.steps .form-group-file input {
  height: 150px;
  opacity: 0;
  position: absolute;
  margin-top: -110px;
}
.steps .form-group-file .form-control.is-invalid {
  border-color: #dc3545;
}
.steps .form-group-file .form-control {
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 20px;
  background-color: #F9F9F9;
  border: 1px solid #ced4da;
}
.steps .form-group-file .form-control .error-font {
  color: red;
}
.steps .progress {
  height: 0.5rem;
  border-radius: 0;
  background-color: #b9e2f2;
}
.steps .progress .progress-bar {
  background-color: #73b7d1;
}

@media (min-width: 768px) {
  .steps .steps-content .container h3,
  .steps .steps-content .container p {
    font-size: 24px;
    line-height: 1.5;
  }
  .steps .steps-content .container p a {
    font-size: 24px;
  }
  .steps .steps-content .container .animate-icon {
    padding: 30px;
  }
  .steps .info-content {
    min-height: 240px;
  }
}
.faq p span, .faq .label span, .faq .card-body span {
  color: #376a85;
  font-weight: bold;
}
.faq .icon-question-mark {
  background: url("/images/question_mark.svg") no-repeat left top;
  width: 115px;
  height: 190px;
  position: absolute;
  left: 12%;
}
.faq .container h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
}
.faq .container h3 {
  font-size: 18px;
  font-weight: 700;
}
.faq .faq-downloads {
  background: #b9e2f2;
  padding: 40px;
}
.faq .faq-downloads ul li {
  list-style: none;
}
.faq .faq-downloads ul li a {
  color: #376a85;
  display: inline-flex;
  align-items: center;
}
.faq .faq-downloads ul li a span.icon {
  margin-right: 20px;
}
.faq .faq-downloads ul li a span.icon:before {
  background: url("/images/deprexis_download_icon.svg") #376a85 no-repeat center;
  border-radius: 50%;
  content: " ";
  display: inline-flex;
  height: 60px;
  width: 60px;
}
.faq .accordion {
  padding: 0;
  margin: 0 -40px;
}
.faq .accordion .container .card-body {
  padding-left: 0;
}
.faq .accordion .container .card-body .card-body {
  padding-left: 58px;
  font-size: 1rem;
}
.faq .accordion .label small {
  display: block;
}
.faq #stories .story {
  background-color: #73b7d1;
  cursor: pointer;
}
.faq .no-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
  overflow: hidden;
}
.faq .no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.faq .no-scrollbar .no-scroll-item {
  margin: 0 -15px 0 0;
}
.faq .btn.slide-left, .faq .btn.slide-right {
  position: absolute;
  z-index: 1;
  top: 50%;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  text-align: unset;
  transform: translateY(-50%);
}
.faq .btn.slide-left:before, .faq .btn.slide-right:before {
  display: inline-block;
  font-family: "flexslider-icon";
  font-size: 36px;
  padding: 0 10px;
}
.faq .btn.slide-left {
  left: 0px;
}
.faq .btn.slide-left:before {
  content: "\f001";
}
.faq .btn.slide-right {
  right: 0px;
}
.faq .btn.slide-right:before {
  content: "\f002";
}
.faq .switch .btn {
  border: 1px solid #376a85;
}
.faq #switch {
  display: none;
}
.faq #switch:not(:checked) ~ #stories .faq-stories {
  display: block;
}
.faq #switch:not(:checked) ~ #stories .switch .videoswitch {
  display: none;
}
.faq #switch:checked ~ #stories .faq-text .faq-content {
  display: block !important;
}
.faq #switch:checked ~ #stories .faq-stories {
  display: none;
}
.faq #switch:checked ~ #stories .switch .textswitch {
  display: none !important;
}
.faq #switch:checked ~ #stories .switch .videoswitch {
  display: block !important;
}

.reels .modal {
  overflow: hidden;
  background: rgb(10, 37, 56);
  padding-left: 0 !important;
  align-items: center;
}
.reels .modal .controls {
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  width: 100%;
  z-index: 999;
}
.reels .modal .controls .close, .reels .modal .controls .play, .reels .modal .controls .mute, .reels .modal .controls .rewind, .reels .modal .controls .forward {
  background: transparent;
  opacity: 1;
}
.reels .modal .controls .close {
  float: unset;
}
.reels .modal .controls .play, .reels .modal .controls .mute, .reels .modal .controls .rewind, .reels .modal .controls .forward {
  border: none;
}
.reels .modal .modal-dialog {
  margin: 0 auto;
  border-radius: 0;
  max-width: 48vh;
  height: 100%;
}
.reels .modal .modal-dialog button img {
  width: 52px;
}
.reels .modal .modal-body {
  padding: 0;
}
.reels .modal .modal-content {
  background: transparent;
  border: 0;
}
.reels .modal .blur {
  backdrop-filter: blur(8px) saturate(120%);
  background: rgba(0, 0, 0, 0.6);
  height: 100vh;
  position: absolute;
  width: 100vw;
}
.reels .carousel .carousel-indicators {
  top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}
.reels .carousel .carousel-indicators li {
  width: 50%;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.5);
}
.reels .carousel .carousel-indicators li.watched {
  background-color: rgb(255, 255, 255) !important;
}
.reels .carousel .carousel-control-prev {
  left: -100px;
}
.reels .carousel .carousel-control-next {
  right: -100px;
}
.reels .carousel .carousel-control-next-icon {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0,0,270,389.99999999999943'%3E%3Cg%3E%3Cpath d='M0,0M258.67261443,218.39487975 C258.67261443,218.39487975,94.67261443,381.39487975,94.67261443,381.39487975 C88.67261443,387.39487975,81.3392811,390.39487975,72.67261443,390.39487975 C64.00594776,390.39487975,56.67261443,387.39487975,50.67261443,381.39487975 C50.67261443,381.39487975,30.67261443,362.39487975,30.67261443,362.39487975 C24.67261443,355.72821309,21.67261443,348.06154642,21.67261443,339.39487975 C21.67261443,330.72821309,24.67261443,323.39487975,30.67261443,317.39487975 C30.67261443,317.39487975,152.67261443,195.39487975,152.67261443,195.39487975 C152.67261443,195.39487975,30.67261443,74.39487975,30.67261443,74.39487975 C24.67261443,67.72821309,21.67261443,60.06154642,21.67261443,51.39487975 C21.67261443,42.72821309,24.67261443,35.39487975,30.67261443,29.39487975 C30.67261443,29.39487975,50.67261443,10.39487975,50.67261443,10.39487975 C56.00594776,3.72821309,63.3392811,0.39487975,72.67261443,0.39487975 C82.00594776,0.39487975,89.3392811,3.72821309,94.67261443,10.39487975 C94.67261443,10.39487975,258.67261443,173.39487975,258.67261443,173.39487975 C264.67261443,179.39487975,267.67261443,186.72821309,267.67261443,195.39487975 C267.67261443,204.06154642,264.67261443,211.72821309,258.67261443,218.39487975Z '/%3E%3C/g%3E%3C/svg%3E") #fff;
  background-position: 60% center;
  background-repeat: no-repeat;
  background-size: 50%;
  border-radius: 100%;
  height: 50px;
  width: 50px;
}
.reels .carousel .carousel-control-prev-icon {
  background: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0,0,270,389.99999999999943'%3E%3Cg%3E%3Cpath d='M0,0M30.67261443,218.39487975 C24.67261443,211.72821309,21.67261443,204.06154642,21.67261443,195.39487975 C21.67261443,186.72821309,24.67261443,179.39487975,30.67261443,173.39487975 C30.67261443,173.39487975,194.67261443,10.39487975,194.67261443,10.39487975 C200.00594776,3.72821309,207.3392811,0.39487975,216.67261443,0.39487975 C226.00594776,0.39487975,233.3392811,3.72821309,238.67261443,10.39487975 C238.67261443,10.39487975,258.67261443,29.39487975,258.67261443,29.39487975 C264.67261443,35.39487975,267.67261443,42.72821309,267.67261443,51.39487975 C267.67261443,60.06154642,264.67261443,67.72821309,258.67261443,74.39487975 C258.67261443,74.39487975,136.67261443,195.39487975,136.67261443,195.39487975 C136.67261443,195.39487975,258.67261443,317.39487975,258.67261443,317.39487975 C264.67261443,323.39487975,267.67261443,330.72821309,267.67261443,339.39487975 C267.67261443,348.06154642,264.67261443,355.72821309,258.67261443,362.39487975 C258.67261443,362.39487975,238.67261443,381.39487975,238.67261443,381.39487975 C232.67261443,387.39487975,225.3392811,390.39487975,216.67261443,390.39487975 C208.00594776,390.39487975,200.67261443,387.39487975,194.67261443,381.39487975 C194.67261443,381.39487975,30.67261443,218.39487975,30.67261443,218.39487975Z '/%3E%3C/g%3E%3C/svg%3E") #fff;
  background-position: 40% center;
  background-repeat: no-repeat;
  background-size: 50%;
  border-radius: 100%;
  height: 50px;
  width: 50px;
}
.reels .carousel iframe {
  border-radius: 15px;
}

@media (min-width: 576px) {
  .faq .btn.slide-left, .faq .btn.slide-right {
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
  }
  .faq .btn.slide-left {
    left: -25px;
  }
  .faq .btn.slide-left:before {
    content: "\f001";
  }
  .faq .btn.slide-right {
    right: -25px;
  }
  .faq .btn.slide-right:before {
    content: "\f002";
  }
}
@media (min-width: 768px) {
  .faq .container h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 29px;
  }
  .faq .container h3 {
    font-size: 40px;
    font-weight: 700;
  }
  .faq .accordion {
    padding: 0;
    margin: 0 -40px;
  }
  .faq .accordion .container .card-body {
    padding-left: 58px;
  }
  .faq .no-scrollbar {
    overflow: auto;
  }
  .faq .btn.slide-left, .faq .btn.slide-right {
    color: #376a85;
    background: rgba(0, 0, 0, 0);
  }
  .faq .btn.slide-left {
    left: -40px;
  }
  .faq .btn.slide-left:before {
    content: "\f001";
  }
  .faq .btn.slide-right {
    right: -40px;
  }
  .faq .btn.slide-right:before {
    content: "\f002";
  }
  .page_6 .faq .accordion .accordion {
    margin: 0 -40px !important;
  }
}
.page_1 .faq .accordion {
  margin: 0;
}

.page_6 .faq .accordion .accordion {
  margin: 0;
}

@media (min-width: 1025px) {
  .faq .reels .modal-dialog button img {
    width: 52px;
  }
}
.contact, .page_4 .contact {
  padding-bottom: 30px;
}
.contact .contact-cards, .page_4 .contact .contact-cards {
  padding-bottom: 0;
}
.contact .card, .page_4 .contact .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.contact .card-left a, .contact .card-right a, .page_4 .contact .card-left a, .page_4 .contact .card-right a {
  color: #0A2538;
  white-space: nowrap;
  font-weight: 700;
}
.contact .card-left .bob-style, .contact .card-right .bob-style, .page_4 .contact .card-left .bob-style, .page_4 .contact .card-right .bob-style {
  color: #fff;
  background-color: #376a85;
}
.contact .card-left span, .contact .card-right span, .page_4 .contact .card-left span, .page_4 .contact .card-right span {
  color: #376a85;
}
.contact .card-left, .page_4 .contact .card-left {
  background-color: #b9e2f2;
  color: #0A2538;
}
.contact .card-top-wrapper, .page_4 .contact .card-top-wrapper {
  display: flex;
  margin-bottom: 30px;
}
.contact .card-top-wrapper h4, .page_4 .contact .card-top-wrapper h4 {
  padding-top: 0px;
  align-self: center;
  margin-bottom: 0px;
}
.contact .card-top-wrapper .card-icon, .page_4 .contact .card-top-wrapper .card-icon {
  width: 80px;
  height: auto;
  margin-right: 20px;
}

.page_9 .container.beratung {
  margin: 100px auto;
}
.page_9 .container .bob-style {
  color: #fff;
  background-color: #376a85;
}
.page_9 .container .bob-close {
  display: none;
}

.text p span {
  color: #376a85;
  font-weight: bold;
}

.overview {
  background-color: #DCF1F9;
}
.overview li {
  font-weight: 500;
  line-height: 27px;
  list-style: none;
  margin: 2rem 0 2rem -40px;
  padding: 5px 0;
  align-content: center;
  flex-wrap: wrap;
}
.overview li.checkmark {
  background: url("/images/check.svg") no-repeat left center;
  background-size: 42px;
  padding-left: 60px;
}
.overview li.doc {
  background: url("/images/doc_limit_2.svg") no-repeat left center;
  background-size: 42px;
  padding-left: 60px;
}
.overview li.document {
  background: url("/images/icon_document.svg") no-repeat left center;
  background-size: 42px;
  padding-left: 60px;
}
.overview li.leitlinien {
  background: url("/images/icon_leitlinien.svg") no-repeat left center;
  background-size: 42px;
  padding-left: 60px;
}
.overview li.pc {
  background: url("/images/icon_pc.svg") no-repeat left center;
  background-size: 42px;
  padding-left: 60px;
}
.overview li.cal {
  background: url("/images/icon_cal.svg") no-repeat left center;
  background-size: 42px;
  padding-left: 60px;
}

section.cite .container {
  background: #376984;
}
section.cite .container .row {
  padding: 2rem 2rem;
}
section.cite .container hr.citeLine {
  width: 300px;
  border-color: #fff;
  margin: 3rem auto;
}
section.cite .container p {
  color: #fff;
}
section.cite .container p.citeText {
  font-weight: 500;
  color: #b9e2f2;
  font-size: 26px;
}
section.cite .container p span {
  color: #b9e2f2;
  font-weight: bold;
}
section.cite .container span.citePerson {
  color: #b9e2f2;
  font-weight: 500;
}

@media (min-width: 768px) {
  section.cite .container .row {
    padding: 5rem 10rem;
  }
  section.cite .container p.citeText {
    font-size: 34px;
  }
}
.webinarv2-section .webinar .row {
  justify-content: center;
}
.webinarv2-section .webinar .webinar-content {
  background: #dbeff8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px;
  padding: 30px;
  width: 100%;
}
.webinarv2-section .webinar .webinar-content .date .dateIcon {
  width: 40px;
}
.webinarv2-section .webinar .webinar-content .date .dateText {
  font-size: 18px;
  color: #386984;
}
.webinarv2-section .webinar .webinar-content .mini {
  font-weight: bold;
}
.webinarv2-section .webinar .webinar-content span.speaker {
  color: #386984;
  padding-bottom: 30px;
}
.webinarv2-section .webinar .webinar-content .btn {
  align-self: flex-end;
  background: #376a85;
  color: #fff;
  width: 60%;
}

@media (min-width: 768px) {
  .webinarv2-section .webinar .webinar-content {
    width: 45%;
  }
}
.request {
  padding-bottom: 40px;
  color: #494949;
}
@media (min-width: 768px) {
  .request {
    padding-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .request {
    padding-bottom: 80px;
  }
}
.request h1, .request h2, .request h3, .request h4, .request h5 {
  font-family: "Jost", sans-serif;
  color: #0A2538;
  font-weight: 700;
  line-height: 1.15;
}
.request h1.hyphens, .request h2.hyphens, .request h3.hyphens, .request h4.hyphens, .request h5.hyphens {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.request h1.white, .request h2.white, .request h3.white, .request h4.white, .request h5.white, .request p.white {
  color: #fff;
}
.request h1.app-color, .request h2.app-color, .request h3.app-color, .request h4.app-color, .request h5.app-color, .request p.app-color {
  color: #376a85;
}
.request h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.request h2.h1 {
  font-size: 40px;
}
.request h3 {
  padding-bottom: 20px;
}
.request h3, .request h4 {
  padding-top: 20px;
  font-size: 24px;
}
.request h3.mini {
  font-size: 20px;
  line-height: 1.15;
  margin-bottom: 7px;
  padding-bottom: 0;
}
.request p, .request h4.p {
  font-size: 20px;
  line-height: 1.5;
}
.request p {
  font-weight: 300;
}
.request p.mini,
.request p.mini-sm {
  font-size: 18px;
}
.request p.tiny {
  font-size: 14px;
}
.request h5 {
  font-size: 17px;
}
@media (min-width: 576px) {
  .request p.mini-sm {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .request h2 {
    margin-bottom: 40px;
  }
  .request h2.h1 {
    font-size: 50px;
  }
  .request h2.extra {
    font-size: 38px;
  }
  .request p.extra {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  .request h3.h2-lg {
    font-size: 40px;
  }
  .request h2 {
    margin-bottom: 40px;
  }
  .request h2.h1 {
    font-size: 66px;
  }
  .request h2.extra {
    font-size: 40px;
  }
  .request p.extra {
    font-size: 30px;
  }
}
.request h3 {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .request h3 {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .request h3 {
    margin-bottom: 40px;
  }
}
.request .request-cards .row {
  margin-top: 30px;
  background-color: #b9e2f2;
  color: #0A2538;
}
.request .request-cards .row span {
  color: #b9e2f2;
  font-weight: bold;
}
.request .request-cards .row .btn-img-bg {
  background-color: #376a85;
  width: 140px;
  height: 140px;
}
.request .request-cards .row h4 {
  margin-bottom: 20px;
  font-size: 22px;
}
.request .request-cards .row a {
  font-weight: 700;
  color: #0A2538;
  white-space: nowrap;
}
.request .request-cards .row span {
  color: #376a85;
}
.request .request-cards .row .bob-style {
  color: #fff;
  background-color: #376a85;
  margin-top: 20px;
  width: 100%;
}

.benefits .circle {
  background: #dbf0f8;
  border-radius: 50%;
  padding: 20px;
  width: 140px;
  margin: 0 auto;
}

.section-how p span {
  color: #376a85;
  font-weight: bold;
}

.section-teleclinic {
  background-color: #73b7d1;
}
.section-teleclinic a {
  background-color: #376a85;
  color: #fff;
}
.section-teleclinic p {
  color: #17374b;
}

.page_1 .contact {
  padding-top: 80px;
}

.blog .blogitem {
  margin: 25px 0;
}
.blog .blogitem a, .blog .blogitem a:hover {
  text-decoration: none;
  color: #0A2538;
}
.blog .blogitem a h3, .blog .blogitem a:hover h3 {
  font-weight: bold;
  font-size: 27px;
  line-height: 1.15;
  padding-top: 20px;
}
.blog .blog-img img {
  width: 100%;
}
.blog h2 {
  color: #0A2538;
  font-size: 40px;
}

.blog-single .blogitem {
  margin: 25px 0;
}
.blog-single .blogitem a, .blog-single .blogitem a:hover {
  text-decoration: none;
  color: #0A2538;
}
.blog-single .blogitem a h3, .blog-single .blogitem a:hover h3 {
  font-weight: bold;
}
.blog-single .blog-img img {
  width: 100%;
}
.blog-single .bg {
  padding: 20px;
  margin: 40px 0;
}
.blog-single .bg p strong {
  color: #0a2538;
}
.blog-single .bg h2 {
  margin: 0 0 20px;
}
.blog-single .bg.dark {
  background-color: #376a85;
}
.blog-single .bg.dark p {
  color: #fff;
}
.blog-single .bg.dark p strong {
  color: #b9e2f2;
}
.blog-single .bg.dark a {
  color: #b9e2f2;
}
.blog-single .bg.medium {
  background-color: #74b5d0;
}
.blog-single .bg.medium h2 {
  color: #fff;
}
.blog-single .bg.medium h2 span {
  color: #0A2538;
}
.blog-single .bg.medium p {
  color: #fff;
}
.blog-single .bg.light {
  background-color: #b9e2f2;
}
.blog-single .bg.lighter {
  background-color: #dbf0f8;
}
.blog-single .table-of-contents {
  column-count: 1;
  margin: 40px 0 0;
  width: 100%;
  display: inline-block;
}
.blog-single .table-of-contents a {
  color: #376a85;
}
.blog-single .list-two-col {
  column-count: 1;
  margin: 0;
  width: 100%;
  display: inline-block;
}
.blog-single ol {
  counter-reset: item;
}
.blog-single ol > li {
  display: block;
}
.blog-single ol > li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}
.blog-single .blog-content-image {
  width: 100%;
}
.blog-single .blog-content-image.right {
  float: right;
  margin-left: 32px;
  width: 30%;
}
.blog-single .blog-info {
  padding-top: 10px;
  grid-area: 1/1/2/3;
}
.blog-single .back {
  display: block;
  padding-top: 20px;
  color: #376a85;
}
.blog-single .justify-content-right {
  justify-content: right;
}
.blog-single h2 {
  color: #0A2538;
  font-size: 30px;
  margin: 30px 0 20px;
  font-weight: 700;
  line-height: 1.15;
}
.blog-single h3 {
  color: #0A2538;
  font-size: 18px;
  margin: 30px 0 20px;
  font-weight: 700;
  line-height: 1.15;
}
.blog-single a {
  color: #376a85;
}
.blog-single a.bob-style {
  background: #376a85;
  border: 1px solid #fff;
  color: #fff;
  padding: 8px 40px;
}
.blog-single ul.checkmark li {
  color: #fff;
  font-weight: 500;
  line-height: 27px;
  list-style: none;
  margin: 1rem 0 1rem -40px;
  background: url("/images/check.svg") no-repeat 20px 1px;
  background-size: 20px;
  padding-left: 60px;
  font-style: italic;
}
.blog-single ul.checkmark li strong {
  color: #b9e2f2;
  font-style: normal;
}
.blog-single ul, .blog-single ol {
  list-style-type: disc;
}
.blog-single ul li::marker, .blog-single ol li::marker {
  color: #376a85;
  font-size: 1rem;
}

.blog-header {
  padding-top: 112px;
  background-color: #376a85;
}
.blog-header .title {
  grid-area: 1/1/2/3;
}
.blog-header .title span {
  background-color: #376a85;
}
.blog-header .title span span {
  color: #b9e2f2;
}
.blog-header .title .date, .blog-header .title .readingTime, .blog-header .title .author {
  color: #b9e2f2;
  display: flex;
  align-items: center;
  font-size: 1rem;
}
.blog-header .title .date:before, .blog-header .title .readingTime:before, .blog-header .title .author:before {
  content: " ";
  height: 42px;
  width: 50px;
}
.blog-header .title .date::before {
  background: url("/images/blog/blog_datum.svg");
  background-repeat: no-repeat;
}
.blog-header .title .readingTime::before {
  background: url("/images/blog/blog_lesedauer.svg");
  background-repeat: no-repeat;
}
.blog-header .title .author::before {
  background: url("/images/blog/blog_autor.svg");
  background-repeat: no-repeat;
}
.blog-header .title h1.blog-title {
  color: #fff;
  font-family: "Jost", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.15;
  padding: 20px 0;
}
.blog-header .blog-img {
  object-fit: cover;
  height: 100%;
  width: 120%;
  margin: 0 -10%;
}
.blog-header .back {
  color: #b9e2f2;
  display: block;
  padding: 20px 0 0 0;
  margin-bottom: -30px;
  font-size: 1rem;
}

.page_14 .hero .grid {
  grid-template-columns: 3fr;
}

.page_15 .hero .grid {
  grid-template-columns: 2fr 450px 1fr;
}

@media (min-width: 768px) {
  .blog .blogitem a h3, .blog .blogitem a:hover h3 {
    font-size: 27px;
    padding-top: 20px;
  }
  .blog h2 {
    font-size: 40px;
  }
  .blog-single .blogitem {
    margin: 25px 0;
  }
  .blog-single .blog-content-image {
    width: unset;
  }
  .blog-single .blog-content-image.right {
    margin-left: 32px;
  }
  .blog-single h2 {
    font-size: 40px;
    margin: 60px 0 20px;
    position: relative;
  }
  .blog-single h2 span {
    color: #376a85;
  }
  .blog-single h3 {
    font-size: 20px;
    margin: 30px 0 20px;
  }
  .blog-single h3 span {
    color: #376a85;
  }
  .blog-single ol li span, .blog-single ul li span {
    color: #376a85;
  }
  .blog-single .table-of-contents {
    column-count: 1;
    column-gap: 100px;
  }
  .blog-single .bg {
    padding: 40px 60px;
    margin: 40px 0;
  }
  .blog-header {
    padding-top: 90px;
  }
  .blog-header .title {
    padding-top: 20px;
  }
  .blog-header .title h1.blog-title {
    font-size: 47px;
    font-weight: 700;
    padding-top: 170px;
    line-height: 1.35;
    max-width: 750px;
  }
  .blog-header .blog-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    margin: 0;
  }
}
@media (min-width: 992px) {
  .page_15 .hero .grid {
    grid-template-columns: 2fr 500px 1fr;
  }
  .blog-single .table-of-contents {
    column-count: 2;
    column-gap: 100px;
  }
  .blog-single .list-two-col {
    column-count: 2;
    column-gap: 100px;
  }
}
.quotes {
  background: url("/images/quotation_marks.svg") no-repeat right top;
  display: inline-block;
  height: 98px;
  width: 170px;
  position: absolute;
  right: 0;
  top: -49px;
}