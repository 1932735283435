.usps {
    background-color: $usp-bg;
    padding-top: 60px;

    .title {
        color: $usp-font-title;
    }

    .usp-cards {
        min-height: 160px;
        margin-bottom: 20px;

        .usp-info {
            min-height: 110px;
            margin-bottom: 20px;
        }

        p {
            color: $usp-font;
            line-height: 1.1;
            margin-top: 10px !important;
            font-size: 18px;
            font-weight: 400;
        }

        .usp-heading {
            line-height: 1.1;

            p {
                font-weight: 700;
                font-size: 24px;
                padding-bottom: 10px;
                margin-bottom: 0;
                padding-left: 20px;

                span {
                    color: #fff;
                    -moz-hyphens: auto;
                    -o-hyphens: auto;
                    -webkit-hyphens: auto;
                    -ms-hyphens: auto;
                    hyphens: auto;
                }
            }

            .usp-details-mobile p {
                font-size: 18px;
                font-weight: 600;
            }
        }

        img {
            display: block;
            max-height: 110px;
        }

        .usp-details-desktop {
            display: none;
        }
    }

    span {
        color: $usp-brand-name;
        font-weight: 600;
    }
}

.page_5.project-priovi,
.page_5.project-levidex,
.page_5.project-deprexis {
    .usps {
        background-color: $rezept-usp-bg;

        h3 {
            margin-bottom: 30px;
            font-size: 28px;
            font-weight: 700;
            color: $brand-heading-dark;
        }

        .usp-cards {

            p {
               color: $rezept-usp-font;
            }
            .usp-details-desktop {
                padding-left: 30px;
                padding-top: 10px;
            }
        }
    }
}

// this is special for liebria project
.project-liebria {
    .usps {
        .usp-details-mobile p {
            span {
                color: $usp-brand-name;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .usps {
        padding-top: 70px;
        padding-bottom: 50px;

        .container {
            padding: 0px 40px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .usps {
        .usp-cards {
            min-height: 220px;
            margin-bottom: 0px;

            .usp-details-desktop {
                display: block;
                width: 95%;
            }

            .usp-details-mobile {
                display: none;
            }

            .usp-heading p {
                padding-left: 10px;
            }
        }
    }
}
