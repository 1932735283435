.logos {
    background-color: $logos-bg;
    padding-top: 60px;

    .logo-cards {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;

        img {
            display: block;
            max-height: 110px;
        }
    }
}

@include media-breakpoint-up(md) {
    .logos {
        padding-top: 70px;
        padding-bottom: 50px;

        .container {
            padding: 0px 40px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .logos {
        .logo-cards {
            margin-bottom: 0px;
        }
    }
}
