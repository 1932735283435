// ********************

.spot {
    position: relative;

    .h4 {
        font-size: 20px;
        font-family: $font-family-goth;
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 5px;
    }
}

.spots {
    background: radial-gradient(circle at 40% 50%, #f7f7f7, #f3f3f3, #e9eaea, #dfe1e2, #dbddde);
    margin: 30px 0 0 0;
    padding: 30px 0;
}

.page_2 {
    .spots:not(.spots-hero) {
        padding: 50px 0 30px 0;
        width: 100%;
        background: radial-gradient(circle at 40% 50%, #f7f7f7, #f3f3f3, #e9eaea, #dfe1e2, #dbddde);

        .container {
            overflow: visible;
        }

        .bob-style {
            margin: 7px 0;
        }
    }

    .spots-hero {
        margin-top: 50px;

        .h1 {
            font-size: 26px;
            font-weight: 500;
        }

        h1 {
            font-size: 22px;
        }

        p {
            font-weight: 300;
            font-size: 16px;
        }

        .bob-style {
            margin-top: 20px;
        }
    }
}