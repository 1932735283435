.portal {
    margin-bottom: 80px;

    .arrow {
        margin: 0 auto 60px auto;
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        border-top: 60px solid $usp-bg;
        border-bottom: 0 none;
        width: 0;
        height: 0;
    }

    .portal-row {
        font-family: $font-family-base;
        color: $brand-copy;
        font-size: 20px;
        line-height: 1.45;
        font-weight: 500;

        span {
            color: $brand-name-light;
            font-weight: 700;
        }

        .portal-heading {
            padding-left: 0px;
            padding-right: 0px;
            font-size: 32px;
            line-height: 1.13;
            font-weight: 700;
            padding-bottom: 30px;
            color: $brand-heading-dark;
        }

        h2 {
            font-family: $font-family-goth;
            font-weight: 400;
            color: $brand-custom;
            margin-bottom: 30px;
        }

        .portal-box {
            background-color: $portal-box-bg;
            color: $portal-box-font;
            padding: 20px;
            align-items: center;
            border-radius: 5px;
            margin-bottom: 25px;
            line-height: 1.3;

            img {
                width: 20%;
                max-width: 120px;
                height: fit-content;
                margin: auto;
                display: block;
            }

            p {
                margin: 15px;

                a {
                    color: $portal-box-link-color;
                }
            }
        }

        .portal-row-txt {
            margin-bottom: 10px;

            h2 {
                font-family: $font-family-goth;
                font-weight: 400;
                color: $brand-custom;
                margin-bottom: 30px;
            }
        }

        .portal-row-action {
            .cta-item {
                span {
                    color: $brand-name-light;
                }
            }

            .form-control {
                margin-top: 3px;
                border-radius: 30px;
                padding: 6px 20px;
                background-color: $input-bg-bob;
                border-color: $input-border;

                placeholder {
                    color: $input-placeholder;
                }
            }

            ::placeholder {
                color: $input-placeholder;
                font-style: normal;
            }
        }

        .portal-row-line {
            opacity: 0;
            width: 100%;
            height: 2px;
            background-color: $brand-row-separator;
            margin: 30px 0px;
        }
    }
}

@media only screen and (min-width : 420px) {
    .portal {
        .portal-row {
            .portal-box {
                img {
                  //  width: 10%;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {

    .portal {
        .portal-row {
            .row {
                padding: 10px 0;
            }

            .portal-box {
                img {
                    margin: 0 20px 0 20px;
                }
            }

            .portal-row-line {
                opacity: 1;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .portal {
        .portal-row {
            .portal-row-txt {
                margin-bottom: 0px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .portal {
        .portal-row {
            .portal-box {
                img {
                    width: 10%;
                }
            }
        }
    }
}


// Page_3

.page_3 {
    .portal {
        font-size: 16px;
        margin: 50px 20px 20px 20px;

        .portal-row {
            padding-top: 40px;
        }

        .portal-row-txt {
            font-weight: 300;
        }

        h1 {
            font-family: $font-family-goth;
            font-weight: 400;
            color: $brand-custom;
            margin-bottom: 20px;
        }
    }

    @include media-breakpoint-up(xl) {
        .portal {
            margin: 70px 20px 30px 20px;
            font-size: 19px;

            h1 {
                font-size: 26px;
            }
        }
    }
}