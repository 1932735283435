.pro_usps {

    @include padding-top-half;
    @include padding-bottom-half;
    font-style: $font-family-base;
    background-color: $pro-usp-bg;

    h2 {
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 24px;
        color: $brand-heading-dark;
        padding-top: 20px;

        span {
            color: $brand-app;
        }
    }

    .usp-wrapper{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    p {
        padding: 0px 0px 0 28px;
        line-height: 1.2;
        margin-bottom: 0px;

        span {
            font-weight: 600;
        }
    }
}

@include media-breakpoint-up(md) {

    h2 {
        padding-top: 0px;
    }
}