.slider {
    padding-top: 20px;
    margin: 0 auto;

    .container {
        h3 {
            font-family: $font-family-jost;
            color: $brand-heading-dark;
            font-weight: 700;
            padding-bottom: 20px;
            font-size: 24px;
        }

        span {
            color: $brand-name-dark;
            font-weight: 700;
        }

        .flex-control-nav {
            bottom: -90px;
        }

        .flex-control-paging li a {
            width: 15px;
            height: 15px;
            background: none;
            border: 2px solid $bullet-fill;

        }

        .flex-control-paging li a.flex-active {
            background: $bullet-fill;
            border-color: $bullet-border;
        }

        .flex-direction-nav {
            a {
                height: 60px;
            }

            a:before {
                color: $button-arrow;
            }

            .flex-prev,
            .flex-next {
                height: 60px;
                width: 60px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.705);
            }

            .flex-prev {
                padding-left: 17px;
            }

            .flex-next {
                padding-right: 15px;
            }
        }
        
        .flexslider .slides img {
            max-width: 300px;
            margin: auto;
            padding: 5px 15px;
        }

        .slider-row-line {
            width: 100%;
            height: 2px;
            background-color: $brand-row-separator;
            margin: 100px 0px 0px 0px;
        }

        hr {
            width: 95%;
            height: 2px;
            background-color: $mobile-wrapper;
            margin: 20px auto;
        }

        p {
            padding-left: 20px;
            font-size: 20px;
            padding-right: 20px;
            line-height: 1.5;

            span {
                font-weight: 700;
                color: $brand-name-dark;
            }
        }

        .mobile-wrapper {
            padding: 3px 3px;
            background:
                linear-gradient(0deg, white 0%, $mobile-wrapper) repeat,
                linear-gradient(90deg, $mobile-wrapper 1px, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0)) no-repeat,
                linear-gradient(90deg, rgba(255, 255, 255, 0) 5px, $mobile-wrapper 5px, $mobile-wrapper 6px, rgba(255, 255, 255, 0) 6px, rgba(255, 255, 255, 0)) no-repeat;
            background-color: white;
            border-radius: 8px;
            margin: auto;
            max-width: 350px;

            .mobile-inner-wrapper {
                background-color: white;
                padding: 30px 10px 10px 10px;
                border-radius: 5px;
            }
        }
    }
}

@include media-breakpoint-up (sm) {
    .slider {
        padding-top: 70px;
    }
}

@include media-breakpoint-up (md) {
    .slider {
        .container {
            h3 {
                font-size: 32px;
            }

            .flex-direction-nav {
                .flex-prev {
                    left: -10%;
                }

                .flex-next {
                    right: -10%;
                }
            }
        }
    }
}

@include media-breakpoint-up (lg) {
    .slider {
        .container {
            overflow: visible;
        }
    }
}