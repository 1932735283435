.contact-cards {

    @include padding-bottom;
    @include text-style;

    .contact-row-line {
        width: 100%;
        height: 2px;
        background-color: $brand-row-separator;
        margin: 30px 0px 0px 0px;
    }

    h3 {
        @include margin-bottom-half;
    }

    .card {
        border-radius: 5px;
        padding: 40px 30px 40px 40px;
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;

        span {
            font-weight: 700;
        }

        a {
            text-decoration: none;
            transition: 0.2s;

            &:hover,
            &:active,
            &:focus {
                .btn-img-bg {
                    background-color: $usp-bg;
                }

                h3,
                h4 {
                    color: #fff;
                }
            }
        }
    }


    .btn-img-bg {
        border-radius: 50%;
        display: flex;
        align-items: center;

        img {
            margin: auto;
        }
    }

    .card-left {

        @include span-light;
        background-color: $card-left-bg-page1;
        color: #FFFFFF;
        margin-top: 30px;

        .btn-img-bg {
            background-color: $card-left-span-page1;
            width: 140px;
            height: 140px;
        }

        h3 {
            margin-top: 20px;
            margin-bottom: 0px;
        }

        h4 {
            margin-bottom: 20px;
        }
    }

    .card-right {
        background-color: $card-right-bg-page1;
        color: $card-right-color-page1;
        margin-top: 30px;

        p {
            margin-top: 10px;
        }

        h4 {
            margin-bottom: 0px;
            align-self: center;
        }

        .card-top-wrapper {
            display: flex;
            margin-top: 20px;
            color: $card-right-icon-bg-page1;

            h4 {
                padding-left: 20px;
                width: 70%;
            }
        }

        .btn-img-bg {
            background-color: $card-right-icon-bg-page1;
            width: 95px;
            height: 95px;

            img {
                margin: auto;
                height: 65px;
            }
        }
    }
}

.project-priovi, .project-levidex, .project-somnovia {
    .contact-cards {
        h3.app-color {
            color: $brand-name-light;
        }
    }
}

// used just for phase2
.page_1.project-somnovia, .page_1.project-liebria, .page_1.project-reviga {
    .contact-cards {
        padding-top: 80px;
    }
}

@include media-breakpoint-up (lg) {
    .contact-cards {
        .card {
            width: 45%;
        }

        .card-left {
            margin-top: 0;
        }

        .card-right {
            margin-top: 0;
        }
    }
}
