.box {
    @include text-style;
    @include margin-top-half;
    @include margin-bottom;

    .box-inner {
        background-color: $steps-bg;
        padding: 40px;
        border-radius: 5px;
    }

    .box-inner-content {
        margin-top: 10px;
        display: flex;
        align-items: center;
        color: $brand-copy;

        a {
            font-weight: 700;
        }
    }

    img {
        width: 80%;
        max-width: 220px;
        display: block;
        margin: 0 auto 30px auto;
    }
}

@include media-breakpoint-up(md) {
    .box {
        .box-inner-content {
            margin: 5%;
        }

        img {
            margin: 0 auto;
        }
    }
}

@include media-breakpoint-up(xl) {
    .box {
        .box-inner-content {
            p {
                font-size: 24px;
            }
        }
    }
}