.info-section {
    padding: 80px 0px;

    span {
        color: $brand-name-dark;
        font-weight: 700;
    }

    .container {
        h2 {
            margin-bottom: 50px;
            color: $info-section-heading-syndrom;
            font-size: 28px;
            font-weight: 700;
            line-height: 1.3;
        }

        h3 {
            color: $info-section-heading-syndrom;
            font-size: 22px;
            font-weight: 700;
            line-height: 1.3;
        }

        .info-card {
            p {
                font-size: 18px;
            }
        }

        p {
            line-height: 1.2;
        }

        hr {
            margin-bottom: 50px;
            margin-top: 40px;
            border-color: $info-line-separator-syndrom;
        }

        .bob-style {
            margin: 0px auto;
            color: $hero-btn-font-syndrom;
            background-color: $hero-btn-bg-syndrom;
            display: block;
        }
    }
}