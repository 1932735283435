// NAV
// ***

.navbar-dark {
  background-color: $brand-app;
}

.navbar {
  top: 0px;
  transition: margin-top 0.3s, padding-top 0.3s;
  padding: 0px 1rem;

  .container {
    transition: margin-top 0.3s;
    margin-top: 33px;
    padding-bottom: 30px;
    padding-top: 5px;
  }
}

.navbar-logo-outer {
    width: 150px
}

.nav-logo {
  transition: width 0.3s, margin 0.3s;
  width: 110px;
  height: auto;
}

.hm-logo {
  width: 150px;
}

// this is special for optimune project
// the idea is to merge this nav style with the default one
// and create one style definition that fits to all projects
.project-optimune {
  .nav-logo {
      width: 150px;
  }
}

// this is special for somnovia project
.project-somnovia {
  .nav-logo {
    padding-bottom: 5px;
  }
}

// this is special for liebria project
.project-liebria .navbar-logo-outer img {
    width: 80%;
}

// this is the mobile hide mobile animation end result
.navbar.navbar-animate {
  padding-top: 0px;
  margin-top: -160px;

  .container {
    margin-top: 0;
  }
}

.navbar-secondary {
  display: table;
  position: relative;
  float: right;
  padding-right: 15px;

  a {
      color: #ffffff;
  }
}

.navbar-right {
  a {
      margin-right: 15px;
  }
}

.nav-link {
  i {
      svg {
          width: 20px;
          height: 20px;
          margin: 1px 5px 0 0;
      }
  }
}

.navbar-nav {
  display: block;
}

.nav-item {
  a {
      color: $brand-nav-btn;
      font-size: 17px;
      font-weight: 600;
  }

  img {
      display: block;
      margin-left: auto;
      margin-bottom: -12px;
  }
}

.mobile {
  img {
    margin-left: 90px;
  }
}

.navbar-btn-descr {
  display: block;
  width: auto;
  font-family: $font-family-jost;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  padding-top: 7px;
  color: #fff;
}


//////////////////////////// navbar-toggler

.navbar-toggler {
  border: none;
}

.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}

//////////////////////////// navbar-toggler end


@include media-breakpoint-up(md) {
    .page_1 .navbar li > a > .page_1,
    .page_2 .navbar li > a > .page_2,
    .page_3 .navbar li > a > .page_3,
    .page_4 .navbar li > a > .page_4 {

        border-bottom: 2px solid #ddd;
        color: #fff;
        opacity: 1;
    }
}

/* overide border-color */
.navbar-secondary .btn-nav:focus,
.navbar-nav .btn-nav:focus,
.navbar-secondary .btn-nav.focus,
.navbar-nav .btn-nav.focus {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:hover,
.navbar-nav .btn-nav:hover {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:active,
.navbar-nav .btn-nav:active,
.navbar-secondary .btn-nav.active,
.navbar-nav .btn-nav.active,
.open>.dropdown-toggle.navbar-secondary .btn-nav,
.open>.dropdown-toggle.navbar-nav .btn-nav {
  border-color: #ffffff;
}

.navbar-secondary .btn-nav:active:hover,
.navbar-nav .btn-nav:active:hover,
.navbar-secondary .btn-nav.active:hover,
.navbar-nav .btn-nav.active:hover,
.open>.dropdown-toggle.navbar-secondary .btn-nav:hover,
.open>.dropdown-toggle.navbar-nav .btn-nav:hover,
.navbar-secondary .btn-nav:active:focus,
.navbar-nav .btn-nav:active:focus,
.navbar-secondary .btn-nav.active:focus,
.navbar-nav .btn-nav.active:focus,
.open>.dropdown-toggle.navbar-secondary .btn-nav:focus,
.open>.dropdown-toggle.navbar-nav .btn-nav:focus,
.navbar-secondary .btn-nav:active.focus,
.navbar-nav .btn-nav:active.focus,
.navbar-secondary .btn-nav.active.focus,
.navbar-nav .btn-nav.active.focus,
.open>.dropdown-toggle.navbar-secondary .btn-nav.focus,
.open>.dropdown-toggle.navbar-nav .btn-nav.focus {
  border-color: #ffffff;
}

/* overide border-color - end */

@include media-breakpoint-up(sm) {

  .navbar {
    transition: 0.4s;

    .container {
      padding-bottom: 0px;
    }
  }

  // this is special for optimune project
  // the idea is to merge this nav style with the default one
  // and create one style definition that fits to all projects
  .project-optimune {
    .navbar.navbar-animate {
      padding-top: 5px;

      .nav-logo {
        width: 120px;
      }
    }
  }

.navbar-nav {
    display: flex;
    margin-bottom: 0px;
}

.navbar-btn-descr {
    font-size: 13px;
    display: inline-block;
}

.nav-item {
    a {
        font-size: 17px;
        padding-left: 10px;
    }
}
  .navbar-secondary {
      display: none;
  }

}

@include media-breakpoint-up(md) {
  // this is the desktop shrink animation end result
.navbar.navbar-animate {
  padding-top: 0px;
  margin-top: 0;
  .nav-logo {
    width: 95px;
  }
  .container {
    margin-top: 0;
  }
}

  //velibra HM page
  .project-velibra.page_6 {
    .navbar.navbar-animate {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
