.horizontal-scroll {
    overflow: auto;
    display: flex;
    scroll-snap-type: x mandatory;

    .screenshots-item,
    .illustration {
        scroll-snap-align: start;
    }

    .screenshots-item {
        padding: 20px clamp(50px, calc(50vw - 119px), 6000px);

        p {
            min-height: 125px;
        }
    }

    .illustration {
        padding: 20px clamp(20px, calc(20vw - 119px), 6000px);
    }
}

@include media-breakpoint-up(lg) {
    .screenshots .horizontal-scroll,
    .screenshots .mobile-view-button {
        display: none;
    }
}

@include media-breakpoint-up(md) {
    .illustrations .horizontal-scroll {
        display: none;
    }
}