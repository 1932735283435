.project-info {
    top: -120px;
    position: relative;
}

.steps {
    @include text-style;
    @include padding-top;
    @include padding-bottom;

    h2,
    h3 {
        @include span-brand; 
    }

    .steps-content {
        @include margin-top-half;

        .container {
            background-color: $steps-bg;
            padding-top: 50px;
            padding-bottom: 50px;

            h3 {
                font-size: 20px;
                font-weight: 700;
                font-family: $font-family-jost;
                margin-bottom: 0px;

                .heading-highlight {
                    color: $brand-name-dark;
                }

                .heading-subtext {
                    color: #959796;
                    font-weight: 400;
                }
            }

            a {
                font-size: 20px;
            }

            p {
                font-size: 20px;
                font-weight: 400;
                font-family: $font-family-jost;
                color: #4A5B5F;

                span {
                    color: $brand-name-dark;
                    font-weight: 700;
                }
            }

            p,
            h3 {
                line-height: 1.3;
            }

            .info-content {
                min-height: 150px;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .steps-icons {
                    display: none;
                    padding-top: 60px;
                }
            }

            .steps-icons {
                display: none;
                padding-top: 60px;
            }

            object {
                display: block;
            }

            .col-4 {
                padding: 0px;
            }
        }
    }

    .row-line {
        opacity: 0;
        width: 100%;
        height: 2px;
        background-color: $gray-100;
        margin: 15px 0;
    }

    .padding {
        padding: 30px 0;
    }
}

.project-priovi {
    .steps {
        h2 {
            font-size: 44px;

            span {
                color: $brand-name-dark;
            }
        }

        .steps-content {
            .container {

                img {
                    width: 100%;
                }
                p {
                    color: $brand-name-dark;
                    font-weight: 600;

                    span {
                        color: $brand-heading-dark;
                    }
                }

                a {
                    span {
                        color: $brand-name-dark;
                    }

                    img {
                        width: 40px;
                        margin: auto;
                    }
                }
            }
        }

        .info-content {
            .btn-dark-theme {
                color: #fff;
                background-color: $brand-heading-dark;
            }

            .bob-style {
                font-weight: 600;
                padding: 8px 30px;
                border: 2px solid $brand-heading-dark;
                //border-bottom: 2px solid $brand-heading-dark;

                &:hover,
                &:active,
                &:focus {
                    background-color: #e6bde8;
                }
            }
        }
    }
}

////// icons svg color definitions

svg.chevron-down {
    path {
        stroke: $steps-icon-chevron;
    }
}

@media only screen and (min-width : 370px) {
    .steps {
        .steps-content {
            .container {

                .info-content {
                    min-height: 195px;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .steps {
        .row-line {
            opacity: 1;
        }
    }
}

@include media-breakpoint-up(md) {
    .steps {
        .steps-content {
            .container {

                h3,
                p {
                    font-size: 24px;
                    line-height: 1.2;
                }

                p {
                    a {
                        font-size: 24px;
                    }
                }

                .info-content {
                    min-height: 240px;
                }

            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .steps {
        .steps-content {
            .container {
                .info-content {
                    .steps-icons {
                        display: block;
                    }
                }
            }
        }

        .padding {
            padding: 0;
        }
    }
}

@include media-breakpoint-up(xl) {
    .steps {
        .steps-content {
            .container {

                .info-content {
                    height: 290px;
                }
            }
        }
    }
}