.contact {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: $font-family-jost;

    .contact-row-line {
        width: 100%;
        height: 2px;
        background-color: $brand-row-separator;
        margin: 30px 0px 0px 0px;
    }

    h3,
    h4,
    p {
        line-height: 1.4;
    }

    h3 {
        color: $brand-heading-dark;
        font-weight: 700;
        padding: 70px 0px 40px 0px;
        font-size: 24px;
    }

    h4 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .card {
        border-radius: 5px;
        padding: 25px 30px;
        width: 100%;
        margin-bottom: 50px;
        margin-left: 15px;
        margin-right: 15px;

        span {
            font-weight: 700;
        }
    }

    .card-left {
        background-color: $card-left-bg-page1;
        color: #FFFFFF;

        span {
            color: $card-left-span-page1;
        }
    }

    .card-right {
        background-color: $card-right-bg-page1;
        color: $card-right-color-page1;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        span {
            color: #fff;
        }
    }

}

@include media-breakpoint-up (md) {
    .contact {
        h3 {
            font-size: 32px;
            padding: 70px 0px;
        }

        .card {
            padding: 50px 30px 50px 40px;
            width: 45%;
        }
    }
}

.page_4 {
    .contact {
        .card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .card-left {
            background-color: $card-left-bg-page4;
            color: $brand-heading-dark;

            a {
                font-weight: 700;
                color: $brand-heading-dark;
                white-space: nowrap;
            }

            span {
                color: $brand-app;
            }

            .bob-style {
                color: #fff;
                background-color: $brand-heading-dark;
            }
        }

        .card-top-wrapper {
            display: flex;
            margin-bottom: 30px;

            h4 {
                padding-top: 0px;
                align-self: center;
                margin-bottom: 0px;
            }

            .card-icon {
                width: 80px;
                height: auto;
                margin-right: 20px;
            }
        }
    }
}