@mixin text-style {
    color: $brand-copy;

    h1, h2, h3, h4, h5 {
        font-family: $font-family-jost;
        color: $brand-heading-dark;
        font-weight: 700;
        line-height: 1.15;
    }


    h1, h2, h3, h4, h5 {
        &.hyphens {
            @include hyphens;
        }
    }

    h1, h2, h3, h4, h5, p {
        &.white {
            color: #fff;
        }
    }

    h1, h2, h3, h4, h5, p {
        &.app-color {
            color: $brand-app;
        }
    }

    h2 {
        margin-bottom: 20px;
        font-size: 40px;
    }

    h2.h1 {
        font-size: 40px;
    }

    h3 {
        padding-bottom: 20px;
    }

    h3, h4 {
        padding-top: 20px;
        font-size: 24px;
    }

    h3.mini {
        font-size: 20px;
        line-height: 1.15;
        margin-bottom: 7px;
        padding-bottom: 0;
    }

    p, h4.p {
        font-size: 20px;
        line-height: 1.5;
    }

    p {
        font-weight: 300;
    }

    p.mini,
    p.mini-sm {
        font-size: 18px;
    }

    p.tiny {
        font-size: 14px;
    }

    h5 {
        font-size: 17px;
    }

    @include media-breakpoint-up(sm) {
        p.mini-sm {
            font-size: 20px;
        }
    }

    @include media-breakpoint-up(md) {
        h2 {
            margin-bottom: 40px;
        }

        h2.h1 {
            font-size: 50px;
        }

        h2.extra {
            font-size: 38px;
        }

        p.extra {
            font-size: 28px;
        }
    }

    @include media-breakpoint-up(lg) {

        h3.h2-lg {
            font-size: 40px;
        }

        h2 {
            margin-bottom: 40px;
        }

        h2.h1 {
            font-size: 66px;
        }

        h2.extra {
            font-size: 40px;
        }

        p.extra {
            font-size: 30px;
        }
    }
}


$distance-sm: 40px;
$distance-md: 60px;
$distance-lg: 80px;

// used for the distance between features
@mixin padding-bottom {
    padding-bottom: $distance-sm;

    @include media-breakpoint-up(md) {
        padding-bottom: $distance-md;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: $distance-lg;
    }
}

@mixin padding-bottom-half {
    padding-bottom: calc($distance-sm / 2);

    @include media-breakpoint-up(md) {
        padding-bottom: calc($distance-md / 2);
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: calc($distance-lg / 2);
    }
}

@mixin padding-top {
    padding-top: $distance-sm;

    @include media-breakpoint-up(md) {
        padding-top: $distance-md;
    }

    @include media-breakpoint-up(lg) {
        padding-top: $distance-lg;
    }
}

@mixin padding-top-half {
    padding-top: calc($distance-sm / 2);

    @include media-breakpoint-up(md) {
        padding-top: calc($distance-md / 2);
    }

    @include media-breakpoint-up(lg) {
        padding-top: calc($distance-lg / 2);
    }
}

// used for the distance between features
@mixin margin-top {
    margin-top: $distance-sm;

    @include media-breakpoint-up(md) {
        margin-top: $distance-md;
    }

    @include media-breakpoint-up(lg) {
        margin-top: $distance-lg;
    }
}

// used for the distance between features
@mixin margin-bottom {
    margin-bottom: $distance-sm;

    @include media-breakpoint-up(md) {
        margin-bottom: $distance-md;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: $distance-lg;
    }
}

// used for the distance within a feature
@mixin margin-top-half {
    margin-top: calc($distance-sm / 2);

    @include media-breakpoint-up(md) {
        margin-top: calc($distance-md / 2);
    }

    @include media-breakpoint-up(lg) {
        margin-top: calc($distance-lg / 2);
    }
}

// used for the distance within a feature
@mixin margin-bottom-half {
    margin-bottom: calc($distance-sm / 2);

    @include media-breakpoint-up(md) {
        margin-bottom: calc($distance-md / 2);
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: calc($distance-lg / 2);
    }
}

@mixin span-brand {
    span {
        color: $brand-app;
        font-weight: bold;
    }
}

@mixin span-dark {
    span {
        color: $brand-name-dark;
        font-weight: bold;
    }
}

@mixin span-light {
    span {
        color: $brand-name-light;
        font-weight: bold;
    }
}

@mixin hyphens {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; 
}