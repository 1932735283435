.research {
    margin: 60px 0;

    .row {
        padding: 40px;

        p {
            margin-bottom: 0;

            span {
                color: $brand-name-dark;
                font-weight: bold;
            }
        }
    }

    a {
        color: #376a85 !important;
    }
}

.grad-blue-white {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 17%, rgba(185,226,242,1) 17%, rgba(185,226,242,1) 100%);
}