.subscription {

    @include text-style;
    @include padding-top;
    @include padding-bottom;

    h2,
    h3 {
        @include span-brand; 
    }

    p {
        @include span-dark; 
    }

    .card {
        border-radius: 5px;
        padding: 25px 10px;
        width: 90%;
        margin: 30px auto;
    }

    .card-left {
        background-color: $subscription-car-left-bg;
        color: #fff;

        h3 {
            color: #fff;
        }

        a {
            color: $brand-heading-dark;
        }
    }

    .card-right {
        background-color: $subscription-car-right-bg;
        color: $brand-heading-dark;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .card-top-wrapper {
        margin-bottom: 30px;

        .card-icon {
            width: 80px;
            height: auto;
            margin-right: 10px;
        }
    }
}


@include media-breakpoint-up(sm) {
    .subscription {
        .card {
            width: 45%;
            margin: 30px 10px;
        }

        .card-top-wrapper {

            .card-icon {
                width: 60px;
            }

            h3 {
                font-size: 17px;
                font-weight: 500;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .subscription {
        .card-top-wrapper {
            .card-icon {
                width: 80px;
            }

            h3 {
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .subscription {

        .card {
            padding: 50px 30px 50px 40px;
        }

        .card-top-wrapper {
            .card-icon {
                margin-right: 20px;
            }

            h3 {
                font-size: 19px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .subscription {
        .card-top-wrapper {
            h3 {
                font-size: 20px;
            }
        }
    }
}