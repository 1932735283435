.text-image {

    @include text-style;
    @include padding-top;

    h2,
    h3 {
        @include span-brand; 
    }

    p {
        @include span-dark; 
    }

    object {
        width: 100%;
        max-width: 250px;
        margin-top: -23px;
    }
}
