.devices {

    @include text-style;
    background-color: $copy-background;

    h2,
    h3 {
        @include span-brand; 
    }

    p {
        @include span-dark; 
        margin-bottom: 0;
    }

    object {
        margin: 0 15px;
    }

    .animate-icon {
        flex-shrink: 0;
        width: 80%;
        max-width: 390px;
    }

    .reverse-row {
        flex-direction: row-reverse;

        h2 {
            margin-left: 0;
            margin-right: 5%;
            word-break: break-word;
        }
    }

    .device-button {
        @include span-dark;
        display: flex;
        justify-content: flex-end;
        padding-top: 50px;
    }

    .row-line {
        background-color: #fff;
        height: 2px;
        margin-top: 60px;
        width: 100%;
    }
}

.page_1 .devices  {
    @include padding-bottom;
}

@include media-breakpoint-up(sm) {
    .devices {
        h2 {
            margin-left: 48px;
        }

        .animate-icon {
            width: 30%;
        }
    }
}

@include media-breakpoint-up(md) {
    .devices {
        .reverse-row {

            h2.h1 {
                font-size: 44px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .devices {
        .reverse-row {

            h2.h1 {
                font-size: 54px;
            }
        }
    }
}