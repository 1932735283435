.section-teleclinic {
    background-color: #73b7d1;

    a {
        background-color: #376a85;
        color: #fff;
    }

    p {
        color: #17374b;
    }
}