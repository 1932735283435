.bob-frame.contains-w1-bob-wizard-form1,
.bob-frame.contains-w2-bob-wizard-form2,
.bob-frame.contains-c1-form-contact_pro,
.bob-frame.contains-c1-form-contact,
.bob-frame.contains-h1-form-help,
.bob-frame.contains-w3-bob-wizard-form3,
.bob-frame.contains-w4-bob-wizard-form4,
.bob-frame.contains-n1-neuro-form,
.bob-frame.contains-e1-error-frame,
.bob-frame.contains-s2-bob-wizard-success2,
.bob-frame.contains-s1-bob-wizard-success1,
.bob-frame.contains-scpf-contact-patient-flyer-success,
.bob-frame.contains-cpf1-contact-patient-flyer,
.bob-frame.contains-c1-bob-form-contact-hubspot {

    .content,
    .form-group-email {
        p {
            font-size: 15px;
            font-weight: 300;
        }
    }

    .mini {
        font-size: 16px;
    }
}

.bob-frame.contains-w2-bob-wizard-form2 {
    .custom-control-label {
        font-size: 15px;
        font-weight: 300;
    }
}


.form-group-email {
    
    label {
        white-space: nowrap;
    }
}

.bob-frame.contains-w1-bob-wizard-form1 {
    legend {
        font-size: 23px;
    }
}

@include media-breakpoint-up(sm) {

    .bob-frame.contains-bob-frame-content-passage {

        .passage {
            padding-top: 0;

            .container {
                padding: 0;
            }
        }

        .bob-window {
            max-width: 750px;
        }
    }

    .bob-frame.contains-w1-bob-wizard-form1 {
        legend {
            font-size: 30px;
        }
    }
}

@include media-breakpoint-up(md) {

    .bob-frame.contains-w1-bob-wizard-form1,
    .bob-frame.contains-w2-bob-wizard-form2,
    .bob-frame.contains-c1-form-contact_pro,
    .bob-frame.contains-c1-form-contact,
    .bob-frame.contains-w3-bob-wizard-form3,
    .bob-frame.contains-w4-bob-wizard-form4,
    .bob-frame.contains-h1-form-help,
    .bob-frame.contains-n1-neuro-form,
    .bob-frame.contains-e1-error-frame,
    .bob-frame.contains-s2-bob-wizard-success2,
    .bob-frame.contains-s1-bob-wizard-success1,
    .bob-frame.contains-scpf-contact-patient-flyer-success,
    .bob-frame.contains-cpf1-contact-patient-flyer,
    .bob-frame.contains-c1-bob-form-contact-hubspot {
        .bob-window {
            width: 600px;
        }
    }   
} 

@mixin frame-margin($amount, $breakpoint){

    @include media-breakpoint-up(#{$breakpoint}) {
        .bob-frame.contains-bob-frame-content-passage {
            .bob-window {
                .bob-content {
                    p, .passge-frame-header {
                        margin: $amount 0;
                    }
                }
            }
        }
    } 
}

@include frame-margin(20px, xs);
@include frame-margin(25px, md);
@include frame-margin(30px, lg);
@include frame-margin(35px, xl);