@mixin link-style-on-light ($color, $focus-bg) {

    a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link) {
        color: $color;
        text-decoration: none;
        border-bottom: 1px solid $color;

        &:focus {
            outline: 0 none;
            color: darken($color, 40%);
            border-bottom: 2px solid darken($color, 10%);
            background-color: lighten($focus-bg, 30%);
        }   
    }
}

@mixin link-style-on-dark ($color, $focus-bg) {

    a:not(.btn):not(.bob-btn-sm):not(.bob-btn-md):not(.bob-btn-lg):not(.nav-link) {
        color: $color;
        text-decoration: none;
        border-bottom: 1px solid $color;

        &:focus {
            outline: 0 none;
            background-color: $focus-bg;
        }        
    }
}

@mixin link-style-nested ($color, $focus-bg, $color-hover) {

    a {
        text-decoration: none;
        
        p {
            color: $color;
        }

        .item-img-wrapper,
        p {
            transition: 0.2s;
        }

        &:focus,
        &:hover,
        &:active {
            outline: 0 none;

            p {
                color: $color-hover;
            }

            .item-img-wrapper {
                background-color: $focus-bg;
            }
        }
        
    }
}

.passage,
.jumbotron,
.paragraph,
.stripe,
.spots,
.accordion,
.usps,
.box,
.usps-patient,
.bob-frame .bob-content,
.steps,
.cta-box,
.subscription-box,
.logo-steps,
.graph,
.rezept-form {
    @include link-style-on-light($brand-link, $brand-link)
}

.contact.light {
    @include link-style-nested($brand-heading-dark, $brand-heading-dark, $brand-app)
}

.contact.dark {
    @include link-style-nested($brand-heading-dark, $usp-bg, #fff)
}

.footer {
    @include link-style-on-dark(#fff, #333)
}