.navbar {
    .container {
        padding-bottom: 5px;
        overflow: unset;
    }

    .nav-link-span {
        padding-left: $navbar-nav-link-padding-x;
        padding-right: $navbar-nav-link-padding-x;
    }

    .navbarDropdownMenuLink {
        margin-bottom: -11px;
        svg {
            position: relative;
            z-index: 1001;
        }
    }

    .nav-item.dropdown:hover {
        #arrow-down {
            stroke: #376a85;
        }
        .dropdown-menu {
            display: block;
        }
    }

    .dropdown-menu {
        width: 13rem;
        left: 50%;
        transform: translate(-50%);
        background-color: #73b7d1;
        border: 0;
        border-radius: 0.5rem;
        margin-top: -4px;

        .dropdown-divider {
            width: 22%;
            margin: 0.5rem 41px;
            border-top: 2px solid #9cc8de;
        }

        a.dropdown-item {
            color: #d5e7f1;
            padding: 0.25rem 1.25rem;

            span {
                font-size: 20px;
                color: #73b7d1;
            }
        }

        a.dropdown-item:hover {
            color: #fff;
            background-color: #73b7d1;

            span {
                color: #fff;
            }
        }
    }
}

.nav-button {
    .nav-link {
        background: #f8e3a6;
        border-radius: 25px;
        .nav-link-span {
            color: #3b3b3b;
        }
    }
}

#bofu-landing {
    .navbar .nav-item {
        img {
            width: 45px;
            height: 45px;
            margin: 0;
        }

        #img-bund {
            width: 60px;
        }
    }
}