// custom.less

html,
body {
    height: 100%;
}

.body-content {
    position: relative;
    height: 100%;
}

// ********************
// button

.btn {
    font-family: $font-family-jost;
    font-style: normal;
    font-weight: normal;
}

.button-block a:hover {
    background-color: $brand-heading-dark;
    color: #fff;
}

// ***************

.bg-gray,
.bg-gray-down-lg {
    background-color: #F7F7F7;
}

@include media-breakpoint-up(md) {
    .navbar-btn-descr {
        font-size: 17px;
        margin-right: 5px;
    }
}

@include media-breakpoint-up(lg) {

    .bg-gray {
        border-right: solid thin rgb(213, 213, 213);
        border-left: solid thin rgb(213, 213, 213);
    }

    .passage-last .bg-gray {
        border-top: solid thin rgb(213, 213, 213);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .container.containing-form .bg-gray {
        border-bottom: solid thin rgb(213, 213, 213);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .bg-gray-down-lg {
        background-color: #fff;
    }

}

@include media-breakpoint-up(xl) {

    .container.containing-form .bg-gray {
        margin: 0 auto;
    }
}

// svg animations

.animate-icon {
    object {
        opacity: 0;
    }
}

.animate-icon {
    object.animate-icon-show {
        opacity: 1;
    }
}

img.animate-icon {
    transition: 0.3s;
    opacity: 0;
}

img.animate-icon.animate-icon-show {
    opacity: 1;
}

.container-big {
    width: 100%;
    padding: 0 10px;
    margin: 0 auto;
    overflow: hidden;
}

@include media-breakpoint-up(sm) {
    .container-big {
        max-width: 540px;
        padding: 0;
    }
}

@include media-breakpoint-up(md) {
    .container-big {
        max-width: 780px;
        padding: 0 40px;
    }
}

@include media-breakpoint-up(lg) {
    .container-big {
        max-width: 1000px;
        padding: 0;
    }
}

@include media-breakpoint-up(xl) {
    .container-big {
        max-width: 1200px;
    }
}