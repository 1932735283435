.hero-bullet {
    padding-top: 130px;
    padding-bottom: 50px;
    background-color: $hero-bg-syndrom;

    .container {

        .hero-content {
            width: 100%;
            padding-top: 40px;

            img {
                width: 60px;
                padding: 20px 30px 0px 0px;
            }

            h1 {
                font-size: 40px;
                color: $hero-font-syndrom;
                margin-bottom: 30px;
                line-height: 48px;
                font-weight: 700;
                font-family: $font-family-base;
            }

            .bullet-section {
                p {
                    font-size: 20px;
                }
            }

            p {
                font-size: 24px;
                color: $hero-font-syndrom;
                line-height: 1.1;
                font-weight: 500;
                font-family: $font-family-base;
                margin-bottom: 30px;

                span {
                    color: $brand-name-light;
                }
            }

            h4 {
                font-size: 24px;
                font-weight: 700;
                color: $hero-font-syndrom;
            }

            .btn-mobile {
                display: block;
            }

        }

        .hero-image {
            width: 100%;

            img {
                width: 100%;
                max-width: 400px;
                display: block;
                margin: auto;
            }

            .btn-desktop {
                display: none;
            }
        }
    }

    .bob-style {
        margin: 40px auto;
        color: $hero-btn-font-syndrom;
        background-color: $hero-btn-bg-syndrom;
        padding-top: 20px ;
        padding-bottom: 20px;
    }

    span {
        font-weight: 700;
        color: $brand-name-light;
    }
}

@include media-breakpoint-up(sm) {
    .hero-bullet {
        .container {

            .hero-content {
            }

            .hero-image {
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .hero-bullet {
        .container {

            .hero-content {
                .btn-mobile {
                    display: none;
                }
            }

            .hero-image {
                .btn-desktop {
                    display: block;
                }
            }
        }
    }
}

