/// hamburger menu effect

a.first-button {
    border: none;
    width: 70px;

    &:focus {
        outline: none;
    }

    .menu-label {
        width: 40px;
        margin-top: 8px;
        color: #fff;
        font-size: 17px;
        margin-left: auto;
        margin-right: auto;
    }
}

.animated-icon1 {
    width: 40px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
}

.animated-icon1 span,
.animated-icon2 span,
.animated-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.animated-icon1 span {
    background: $brand-nav-btn;
}

.animated-icon2 span {
    background: #e3f2fd;
}

.animated-icon3 span {
    background: #f3e5f5;
}

.animated-icon1 span:nth-child(1) {
    top: 0px;
}

.animated-icon1 span:nth-child(2) {
    top: 10px;
}

.animated-icon1 span:nth-child(3) {
    top: 20px;
}

