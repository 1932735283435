.bob-frame.contains-bob-menu {
    background-color: $brand-app;

    .bob-frame-container {
        padding: 0 20px 0 25%;

        .bob-content {
            margin-top: 10px;
            margin-right: 5px;
        }
    }

    .navbar-nav {
        margin-bottom: 0px;
    }

    .nav-item {
        width: 200px;
        line-height: 2;

        a {
            color: $brand-nav-btn;
            font-weight: 500;
        }

        a.activeItem {
            color: $brand-nav-btn-active;
        }

        &:after {
            content: "";
            height: 2px;
            width: 100%;
            position: absolute;
            left: 0px;
            display: block;
            z-index: 1;
        }
    }

    .dropup,
    .dropright,
    .dropdown,
    .dropleft {
        position: static;
    }

    .dropdown-menu {
        display: none;
    }

    .dropdown-menu.show {
        border: none;
        margin: 0 auto;
        display: block;
        left: 0;
        width: 160px;
        transform: initial;

        .dropdown-item {
            padding: 0.25rem 0.9rem;
            line-height: 3;
        }
    }

    .bob-navbar {
        padding: 0;

        .container {
            padding: 0;

            .navbar-inner {
                margin-top: 30px;
            }
        }
    }

    .bob-header,
    .bob-close-outer {
        display: none;
    }

    .navbar-logo {
        line-height: 100px;
        z-index: 2;

        .bob-menu-label {
            text-transform: uppercase;
            color: $brand-nav-btn;
            margin-top: 30px;
            font-weight: 600;
            font-size: 24px;
        }
    }

    .bob-navbar-header {
        width: 100%;
        display: block;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
    }

    .bob-close {
        position: relative;
        z-index: 10;
        top: 0px;
        right: 0px;
        background-color: $brand-app;
        color: $brand-nav-btn;
    }

    .bob-close:hover {
        background-color: $brand-footer;
    }

    .dropdown-menu.show {
        position: static;
    }

    .nav-item.dropdown {

        .nav-link {
            &:after {
                content: "-";
                position: relative;
                display: block;
                line-height: 0;
                top: -24px;
                right: -200px;
                transition: 0.4s;
                font-size: 27px;
                font-style: initial;
            }
        }

        .nav-link:not(.active) {

            &:after {
                content: "+";
            }
        }
    }

    .hide-in-nav {
        display: none;
    }


    @include media-breakpoint-up(sm) {

        .navbar {
            top: -2px;
        }
    }
}