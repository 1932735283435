.screenshots  {

    @include text-style;
    @include padding-top;

    h2,
    h3 {
        @include span-brand; 
    }

    p {
        @include span-dark; 
    }

    .container-big {
        background-color: $copy-background;
        padding: 0px 30px;
    }

    .screenshots-items {

        // this is the desktop view
        &:not(.horizontal-scroll) {
            @include padding-bottom;
            @include margin-top-half;

            .screenshots-item {
                @include padding-top-half;
                
            }
        }
        
        background-color: $copy-background;

        .screenshots-item {

            p {
                margin: 0;
                font-size: 16px;
            }
        }
    }

    .screenshots-item-middle {
        max-width: 450px;
        margin: 30px;

        img {
            margin: 0 auto;
        }
    }

    .button-block {
        @include padding-top-half;
        @include padding-bottom-half;
        background-color: $copy-background;

        a {
            margin-right: 0;
        }
    }

    .mobile-view-button {
        a {
            margin: 0 auto;
        }
    }

    ::-webkit-scrollbar {
        width: 20px;
    }
    
    ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        background: $brand-app;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: #4ad9f6;
    }
}

@include media-breakpoint-up(lg) {
    .screenshots {

        .screenshots-item-middle {
            margin: 50px;
        }

        .screenshots-items {

            .screenshots-item {

                .animate-icon,
                .ani-target {
                    opacity: 0;
                }

                .animate-icon,
                .ani-target {
                    transition: 0.3s;
                }

                .animate-icon.animate-icon-show,
                .ani-target.animate-icon-show {
                    opacity: 1;
                }
            }
        }
    }
}