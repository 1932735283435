.text {
    @include text-style;
    @include padding-bottom;

    .line {
        @include margin-bottom-half;
        background-color: $brand-row-separator;
        width: 100%;
        height: 2px;
    }
}