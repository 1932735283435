.subscription-box {
  @include padding-top-half;
  @include padding-bottom;

  .container-big {
    background-color: $box-bg;
    padding: 50px;

    .row-line {
      background-color: #fff;
      height: 2px;
      margin: 40px 0px;
      width: 100%;
    }

    .box-cta {
      margin: 30px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        padding-right: 10px;
        width: 45px;
      }
    }

    span {
      font-weight: 600;
      color: $brand-name-dark;
    }

    a.rezept-button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 300;

      p {
        margin-left: 15px;
        margin-bottom: 0;

        span {
          color: $box-rezept-btn-span;
        }
      }
    }
  }
}

@include media-breakpoint-up (md) {
  .subscription-box {
    .container-big {
      .box-cta {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
}