.block {
    margin: 60px 0;

    .row {
        padding: 40px;

        h3 {
            font-size: 2rem;
        }

        .bob-style {
            background: #376a85;
            color: #fff;
            padding: 8px 40px;
        }
    }

    p span {
        color: $brand-name-dark;
        font-weight: bold;
    }

    .bg {
        background: #b9e2f2;

    }

    &.dark {
        .bg {
            background: #0a2537;
            color: #fff;
        }

        .row {
            padding: 50px 40px;
            .col {
                align-self: center;
            }
        }
    }    
}