.graph {

    @include text-style;
    @include padding-bottom;
    background-color: $copy-background;

    h2, h3, p {
        @include span-brand; 
    }

    img {
        width: 90%;
    }

    .solo-img {
        width: 90%;
    }

    .logo-box {
        img {
         //   object-fit: contain;
            margin-bottom: 20px;
        }
    }
}

.project-somnovia {
    .graph {
        .graph-box {
            padding-top: 40px;
        }
    }
}

@media (min-width: 375px) {
    .graph {
        img {
            width: 70%;
        }
    }
}


@include media-breakpoint-up(sm) {
    .graph {
        img {
            width: auto;
        }
    }
}