.accordion {
    font-family: $font-family-base;
    color: $brand-copy;
    @include padding-bottom;

    .container {

        h2 {
            font-size: 20px;
            font-weight: 700;
            line-height: 29px;
            margin-bottom: 40px;
            color: $brand-heading-dark;
        }

        h3 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 0px;
            margin-top: 0px;
            padding-top: 30px;
            color: $brand-heading-dark;
        }

        .margin-bottom {
            margin-bottom: 40px;
        }

        hr {
            width: 100%;
            border: 0;
            height: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }

        .card {
            margin: 0;
        }

        .card-body {
            padding-left: 0;
            margin-bottom: 40px;

            img {
                width: 110px;
            }

            p {
                font-size: 16px;
                color: #333333;
            }

            li {
                font-size: 16px;
            }
        }

        .card-header {
            background-color: #fff;
            border-bottom: 0;
            padding: 0;

            button {
                display: flex;
                justify-content: start;
                align-items: center;
                text-align: left;
                color: $brand-copy;
                padding: 6px 20px 6px 0;
                line-height: 20px;
                font-size: 18px;
                font-weight: 600;
                width: 100%;

                &:focus,
                &:active {
                    box-shadow: none;

                    .arrow {
                        background-color: #eaeaea;
                    }
                }

                .arrow {
                    padding: 5px 5px 4px 5px;
                    border-radius: 50%;
                    margin-right: 20px;

                    .glyphicons-plus {
                        display: block;
                        -webkit-transition: -webkit-transform .2s linear;
                        transition: -webkit-transform .2s linear;
                        transition: transform .2s linear;
                        transition: transform .2s linear, -webkit-transform .2s linear;

                        svg path {
                            stroke: $brand-heading-dark;
                        }
                    }
                }
            }

            button[aria-expanded="true"] {

                .arrow {
                    .glyphicons-plus {
                        transform: rotate(45deg);
                    }
                }
            }
        }

        // passage styling 

        .passage {
            color: $brand-copy;
            padding: 0px;
            margin: 0 auto;

            h3 {
                font-family: $font-family-jost;
                color: $brand-heading-dark;
                font-weight: 700;
                line-height: 1.15;
                padding-top: 20px;
                padding-bottom: 20px;
                font-size: 18px;
            }

            p {
                font-weight: 300;
                font-size: 20px;
                line-height: 1.5;
            }

            span {
                color: $brand-name-dark;
                font-weight: 700;
            }

            .passage-info-subtext {
                p {
                    font-size: 12.5px;
                    line-height: 17px;
                    font-weight: 400;
                }
            }

            .passage-row-line {
                width: 100%;
                height: 2px;
                background-color: $brand-row-separator;
                margin-bottom: 40px;
            }
        }
    }
}

@include media-breakpoint-up (sm) {

    .accordion {
        .container {
            .passage {
                .container {
                    padding: 0px;
                }
            }
        }
    }
}
