@mixin size-circle {
    height: 100px;
    width: 100px;
    min-width: 100px;

    @include media-breakpoint-up(sm) {
        height: 120px;
        width: 120px;
        min-width: 120px;
    }

    @include media-breakpoint-up(lg) {
        height: 179px;
        width: 179px;
        min-width: 179px;
    }
}

@mixin margin {
    margin: 0 0 25px 0;
}

//// light color theme
.contact.light {
    background-color: #fff;

    .item-left {
        .item-img-wrapper {
            background-color: $usp-bg;
        }
    }

    .item-right {
        p {
            color: $brand-heading-dark;
        }
    }

    .line {
        background-color: #dfe5e6;
    }
}
////

//// dark color theme
.contact.dark {
    background-color: $brand-app;

    h2 {
        color: #fff;
    }

    p {
        color: $brand-heading-dark;
    }

    a p {
        font-weight: 500;
    }

    .item-left {
        .item-img-wrapper {
            background-color: $brand-heading-dark;
        }
    }

    .item-right {
        p {
            color: #fff;
            font-weight: 500;
        }
    }

    .lines-outer {
        background-color: $brand-app;
    }

    .lines-inner {
        background-color: #dfe5e6;
    }
}

.project-priovi {
    .contact.dark {
        p {
            color: #e9b0ef;
        }
    }
}

.project-deprexis {
    .contact.dark {
        p {
            color: #d3e1e6;
        }
    }
}

.project-somnovia {
    //just for phase 2
    .contact {
        margin-bottom: 80px;
    }

    .contact.dark {
        @include link-style-nested(#A18AC1, #baafce, #fff);

        p {
            color: #fff;
        }

        .item-left {
            .item-img-wrapper {
                background-color: #A18AC1;
            }
        }
    }
}

.project-reviga {
    .contact {
        margin-bottom: 80px;
    }

    .contact.dark {
        @include link-style-nested(#fff, #4b3412, #896002);

        p {
            color: #fff;
        }

        .item-left {
            .item-img-wrapper {
                background-color: #4b3412;

                &:hover {
                    background: #896002;
                }
            }
        }
    }
}

////

.project-liebria {
    //just for phase 2
    .contact {
        margin-bottom: 80px;
    }

    .contact.dark {
        p {
            color: #fff;
        }
    }
}
////

.contact {
    @include text-style;
    @include padding-bottom;

    .contact-cta-item {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        p {
            margin: -25px 25px 0 25px;
        }

    }

    .item-left {
        .item-img-wrapper {
            border-radius: 50%;
            @include size-circle;
            @include margin;

            img {
                margin: 0;
                position: relative;
                width: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        p {
            width: 106px;
        }
    }

    .item-right {
        img {
            @include size-circle;
            @include margin;
        }
    }
}

.lines-outer {
    opacity: 0;
    margin: 50px 0px;
}

@include media-breakpoint-up(sm) {
    .lines-outer {
        margin: 55px 0px;
        opacity: 1;
    }
}

@include media-breakpoint-up(md) {
    .lines-outer {
        margin: 65px 0px;
    }
}


@media only screen and (min-width: 350px) {
    .contact {
        .item-left {
            p {
                width: auto;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .contact {

        .contact-cta {
            display: grid;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 120px 120px 1fr;
            grid-template-rows:  2px 1fr;
            grid-gap: 25px;
        }

        .line1 { grid-area: 1 / 1 / 2 / 2; }
        .line2 { grid-area: 1 / 4 / 2 / 5; }
        .item-left { grid-area: 2 / 1 / 3 / 3; }
        .item-right { grid-area: 2 / 3 / 3 / 5; }

        .lines-inner {
            margin-top: 50px;
        }

        .line {
            height: 2px;
            width: 100%;
        }

        .contact-cta-item {
            display: block;
        }

        .item-left,
        .item-right {
            display: flex;
        }

        .item-left {
            flex-direction: row;
            p {
                margin: -25px 25px 0 0px;
            }
        }

        .item-right {
            p {
                margin: -25px 0px 0 25px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .contact {
        .contact-cta {
            grid-template-columns: 1fr  179px 179px 1fr;
        }
    }
}

.page_6 {
    .contact {
        padding-top: 80px;
    }
}
