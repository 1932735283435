.section-dark-box {

    @include text-style;
    @include padding-bottom;
    background-color: $copy-background;

    hr {
        @include margin-bottom;
        width: 100%;
        height: 2px;
        background-color: $dark-box-section-row-separator;
        border: none;
        margin-top: 0;
    }
}

.dark-box {
    @include margin-top-half;

    .container-big {
        padding: 10px;
        background-color: $dark-box-bg;
        border-radius: 7px;

        p {
            color: #fff;
        }

        h2, h3, p {
            @include span-light;
        }

        img {
            display: block;
            width: 100%;
            max-width: 430px;
            margin: 0 auto;
        }
        
        a {
            color: $brand-app;
            text-decoration: underline;
        }
    }
}

@include media-breakpoint-up(sm) {

    .dark-box {
        .container-big {
            padding: 30px 55px;
        }
    }
}

@include media-breakpoint-up(lg) {

    .dark-box {
        .container-big {
            padding: 30px 50px 30px 10px;
        }

        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 0px;
            align-items: center;
        }
    }
}