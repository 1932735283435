.webinar-section {

    @include text-style;
    @include padding-top;
    @include padding-bottom;
    @include span-brand;

    hr {
        width: 100%;
        height: 2px;
        background-color: $webinar-row-separator;
    }

    .container-big {
        padding: 10px;
        background-color: $brand-custom-sec;
        border-radius: 7px;
    }

    .webinar-content {
        padding-left: 20px;

        p {
            margin-bottom: 0px;
        }
    }
}

.project-priovi {
    .webinar-section {
        .webinar-content {
            p {
                color: #EABCE9;
            }
        }
    }
}

.project-optimune, .project-somnovia {
    .webinar-section {
        .container-big {
            background-color: $webinar-box-bg;
            color: #fff;

            .webinar-content {
                h3 {
                    color: #fff;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .webinar-section {
        .container-big {
            padding: 30px 55px;
        }
    }
}