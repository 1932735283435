.slider-1, .slider-2, .slider-3 {
    padding: 0;
    margin: 60px 0 70px;

    .container-big {
        overflow: unset;
    }

    p span {
        color: $brand-name-dark;
        font-weight: bold;
    }

    .flexslider-1 {
        margin-bottom: 25px;
    }

    .flex-control-nav {
        position: relative;
    }

    .flex-control-paging li a {
        width: 15px;
        height: 15px;
        background: #c9c9c9;
        border: 2px solid #fff;
    }

    .flex-control-paging li a.flex-active {
        background: $bullet-fill;
        border: 2px solid $bullet-fill;
    }

    .flex-direction-nav {
        a {
            top: 0;
            height: 60px;
            color: transparent;
        }

        a:before {
            color: $button-arrow;
            display: none;
        }

        a:hover:before {
            display: inline-block;
            position: absolute;
            top: calc(50% - 40px);
        }

        .flex-prev,
        .flex-next {
            height: 100%;
            width: 20%;
            border-radius: 0;
            background-color: transparent;
        }

        .flex-prev {
            margin-left: -25px;
        }

        .flex-next {
            margin-right: -25px;
        }
    }

    .slider-content {
        h3 {
            font-size: 34px;
            font-weight: bold;
        }
    }
}

.testimonial-slider {
    background-color: #b9e2f2;
    padding: 60px 0 80px;

    p span {
        color: $brand-name-dark;
        font-weight: bold;
    }
    
    .testimonial {
        background: #fff;

        .header {
            margin-top: -50px;
        }

        .fitTop {
            object-fit: cover;
            object-position: top;
            width: 230px;
            height: 230px;
        }
    }
}