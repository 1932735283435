.blog {
    .blogitem {
        margin: 25px 0;

        a, a:hover {
            text-decoration: none;
            color: $brand-heading-dark;
            
            h3 {
                font-weight: bold;
                font-size: 27px;
                line-height: 1.15;
                padding-top: 20px;
            }
        }
    }

    .blog-img {
        img {
            width: 100%;
        }
    }

    h2 {
        color: $brand-heading-dark;
        font-size: 40px;
    }
}

.blog-single {

    .blogitem {
        margin: 25px 0;

        a, a:hover {
            text-decoration: none;
            color: $brand-heading-dark;
            
            h3 {
                font-weight: bold;
            }
        }
    }

    .blog-img {
        img {
            width: 100%;
        }
    }

    .bg {
        padding: 20px;
        margin: 40px 0;

        p strong {
            color: $usp-font;
        }

        h2 {
            margin: 0 0 20px;
        }

        &.dark {
            background-color: $brand-app;
            
            p {
                color: #fff;
                strong {
                    color: $usp-bg;
                }
            }

            a {
                color: $brand-name-light;
            }
        }

        &.medium {
            background-color: #74b5d0;

            h2 {
                color: #fff;
                span {
                    color: $brand-heading-dark;
                }
            }

            p {
                color: #fff;
            }
        }

        &.light {
            background-color: $brand-sec;
        }

        &.lighter {
            background-color: $steps-bg;
        }
    }

    .table-of-contents {
        column-count: 1;
        margin: 40px 0 0;
        width: 100%;
        display: inline-block;

        a {
            color: $brand-app;
        }
    }

    .list-two-col {
        column-count: 1;
        margin: 0;
        width: 100%;
        display: inline-block;
    }

    ol {
        counter-reset: item;

        > li {
            display: block;

            &:before {
                content: counters(item, ".") ". ";
                counter-increment: item;
            }
        }
    }
    

    .blog-content-image {
        width: 100%;

        &.right {
            float: right;
            margin-left: 32px;
            width: 30%;
        }
    }

    .blog-info {
        padding-top: 10px;
        grid-area: 1/1/2/3;
    }

    .back {
        display: block;
        padding-top: 20px;
        color: $brand-app;
    }

    .justify-content-right {
        justify-content: right;
    }

    h2 {
        color: $brand-heading-dark;
        font-size: 30px;
        margin: 30px 0 20px;
        font-weight: 700;
        line-height: 1.15;
    }

    h3 {
        color: $brand-heading-dark;
        font-size: 18px;
        margin: 30px 0 20px;
        font-weight: 700;
        line-height: 1.15;
    }

    a {
        color: $brand-app;

        &.bob-style {
            background: $brand-app;
            border: 1px solid #fff;
            color: #fff;
            padding: 8px 40px;
        }
    }

    ul.checkmark {
        li {
            color: #fff;
            font-weight: 500;
            line-height: 27px;
            list-style: none;
            margin: 1rem 0 1rem -40px;
            background: url('/images/check.svg') no-repeat 20px 1px;
            background-size: 20px;
            padding-left: 60px;
            font-style: italic;

            strong {
                color: $usp-bg;
                font-style: normal;
            }
        }
    }

    ul, ol {
        list-style-type: disc;
        li {
            &::marker {
                color: $brand-name-dark;
                font-size: 1rem;
            }
        }
    }

    
}

.blog-header {
    padding-top: 112px;
    background-color: $brand-app;

    .title {
        grid-area: 1/1/2/3;
        span {
            background-color: $brand-app;
            span {
                color: $brand-sec;
            }
        }

        .date, .readingTime, .author {
            color: $brand-sec;
            display: flex;
            align-items: center;
            font-size: 1rem;

            &:before {
                content: " ";
                height: 42px;
                width: 50px;
            }
        }

        .date::before {
            background: url('/images/blog/blog_datum.svg');
            background-repeat: no-repeat;            
        }

        .readingTime::before {
            background: url('/images/blog/blog_lesedauer.svg');
            background-repeat: no-repeat;
        }

        .author::before {
            background: url('/images/blog/blog_autor.svg');
            background-repeat: no-repeat;
        }
    }

    .title {
        h1.blog-title {
            color: #fff;
            font-family: "Jost", sans-serif;
            font-size: 40px;
            font-weight: 700;
            line-height: 1.15;
            padding: 20px 0;
        }
    }

    .blog-img {
        object-fit: cover;
        height: 100%;
        width: 120%;
        margin: 0 -10%;
    }

    .back {
        color: $brand-sec;
        display: block;
        padding: 20px 0 0 0;
        margin-bottom: -30px;
        font-size: 1rem;
    }
}

.page_14 {
    .hero .grid {
        grid-template-columns: 3fr;
    }
}

.page_15 {
    .hero .grid {
        grid-template-columns: 2fr 450px 1fr;
    }
}

@include media-breakpoint-up(md) {
    .blog {

        .blogitem {
            a, a:hover {                
                h3 {
                    font-size: 27px;
                    padding-top: 20px;
                }
            }
        }
    
        h2 {
            font-size: 40px;
        }
    }
    
    .blog-single {
    
        .blogitem {
            margin: 25px 0;
        }

        .blog-content-image {
            width: unset;
    
            &.right {
                margin-left: 32px;
            }
        }
    
        h2 {
            font-size: 40px;
            margin: 60px 0 20px;
            position: relative;
            span {
                color: $brand-name-dark;
            }
        }
    
        h3 {
            font-size: 20px;
            margin: 30px 0 20px;
            span {
                color: $brand-name-dark;
            }
        }
        ol li, ul li {
            span {
                color: $brand-name-dark;
            }
        }

        .table-of-contents {
            column-count: 1;
            column-gap: 100px; 
        }

        .bg {
            padding: 40px 60px;
            margin: 40px 0;
        }
    }
    
    .blog-header {
        padding-top: 90px;
    
        .title {
            padding-top: 20px;

            h1.blog-title {
                font-size: 47px;
                font-weight: 700;
                padding-top: 170px;
                line-height: 1.35;
                max-width: 750px;
            }
        }

        .blog-img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            margin: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    .page_15 {
        .hero .grid {
            grid-template-columns: 2fr 500px 1fr;
        }
    }

    .blog-single {

        .table-of-contents {
            column-count: 2;
            column-gap: 100px; 
        }

        .list-two-col {
            column-count: 2;
            column-gap: 100px; 
        }
    }
}

.quotes {
    background: url('/images/quotation_marks.svg') no-repeat right top;
    display: inline-block;
    height: 98px;
    width: 170px;
    position: absolute;
    right: 0;
    top: -49px;
}